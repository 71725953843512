<template>
    <div class="app-avatar" :style="wrapperStyles" :class="{'app-avatar-centered': !src}">
        <img v-if="src && !isImageFailed" :src="src" alt="avatar" class="app-avatar__image" :style="imageStyles"
             @error="imageLoadError"/>
        <div v-else-if="initials" :style="textStyleObj" class="app-avatar__text">
            {{ initials }}
        </div>
    </div>
</template>

<script>
export default {
    name: "Avatar",
    props: {
        size: Number,
        src: {
            type: String
        },
        displayName: {
            type: Array,
            default: () => []
        },
        rounded: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isImageFailed: null
        }
    },
    methods: {
        imageLoadError() {
            this.isImageFailed = true
        }
    },
    watch: {
        src() {
            this.isImageFailed = false;
        }
    },
    computed: {
        initials() {
            let initials = ''
            const names = this.displayName.filter(item => typeof item === 'string')
            if (names.length === 1) {
                initials = names[0].charAt(0)
            }
            if (names.length > 1) {
                initials = names[0].charAt(0) + names[names.length - 1].charAt(0)
            }
            return initials
        },
        imageStyles() {
            let styles = {}
            if (this.rounded) {
                styles['border-radius'] = '200px'
            }
            return styles
        },
        wrapperStyles() {
            let styleObj = {};
            if (this.size) {
                styleObj['height'] = this.size + 'px'
                styleObj['width'] = this.size + 'px'
                styleObj['min-width'] = this.size + 'px'
            }
            return styleObj
        },
        textStyleObj() {
            let styleObj = {}
            if (this.size) {
                styleObj['font-size'] = this.size / 3 + 'px'
            }
            styleObj['border-radius'] = this.rounded ? '200px' : 'unset'
            return styleObj
        }
    }
};
</script>

<style lang="scss">
.app-avatar {

    &-centered {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &__text {
        height: 100%;
        width: 100%;
        border-radius: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 500;
        background: #0068AD;
        font-size: 3em;
        text-transform: uppercase;
    }
}
</style>
