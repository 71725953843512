import ApiService from '@/services/ApiService'
import {User} from '@/utils/user'
import {GENERAL_CONTRACTOR_TYPE, SUB_CONTRACTOR_TYPE,} from '@/utils/constants/plans'
import router from '../router'

const state = {
    initialized: false,
    loading: true,
    user: null,
    verificationModal: null,
    countries: [],
    libraryDataUpdate: 0,
    selectLibraryItems: [],
    modalComponent: null,
    teleportNameModalComponent: null,
    unreadNotifications: 0,
    entities: [
        'setTaskEntity',
        'setProjectEntity',
        'setCompanyEntity',
        'setEmployeeEntity',
        'setScopeEntity',
        'template/setTemplateEntity',
        'setTaskPermissions',
        'setProjectPermissions',
        'setCompanyPermissions',
        'setEmployeePermissions',
        'setScopePermissions',
        'template/setTemplatePermissions',
    ],
}

const getters = {
    user: (state) => new User(state.user),
    isGeneralPlan: (state) => state.user?.company?.type === GENERAL_CONTRACTOR_TYPE,
    isSubPlan: (state) => state.user?.company?.type === SUB_CONTRACTOR_TYPE,
    countries: (state) => state.countries.map((i) => ({label: i.state_name, value: i.state_id})),
    verificationModal: (state) => state.verificationModal,
    libraryDataUpdate: (state) => state.libraryDataUpdate,
    selectLibraryItems: (state) => state.selectLibraryItems,
    modalComponent: (state) => state.modalComponent,
    teleportNameModalComponent: (state) => state.teleportNameModalComponent,
    notificationCounter: (state) => state.unreadNotifications,
}

const mutations = {
    setUnreadNotificationsCounter(state, payload) {
        state.unreadNotifications = payload
    },
    setModalComponent(state, data) {
        const {
            name,
            success,
            close,
            btnDirection,
            xVisible,
            label,
            sublabel,
            slot,
            slotId,
            icon,
            size,
        } = data
        state.modalComponent = {
            name,
            success,
            close,
            btnDirection: btnDirection || 'row',
            xVisible: xVisible || false,
            label,
            sublabel,
            slot,
            slotId,
            icon,
            size: size || 'sm',
            loading: false,
        }
    },
    setLoadingModalComponent(state, loading = false) {
        state.modalComponent.loading = loading
    },
    setTeleportNameModalComponent(state, value) {
        state.teleportNameModalComponent = value
    },
    closeModalComponent(state) {
        state.modalComponent = null
        state.teleportNameModalComponent = null
    },
    setInitialized(state, initialized) {
        state.initialized = initialized
    },
    setLoading(state, loading) {
        state.loading = loading
    },
    setVerificationModal(state, verificationModal) {
        state.verificationModal = verificationModal
    },
    setUser(state, user = null) {
        state.user = user
    },
    setUserAvatar(state, avatarUrl) {
        state.user.photo = avatarUrl
    },
    setCountries(state, data) {
        state.countries = data
    },
    setLibraryDataUpdate(state) {
        state.libraryDataUpdate++
    },
}

const actions = {
    setUnreadNotificationsCounter({commit}, payload) {
        commit('setUnreadNotificationsCounter', payload)
    },
    fetchCountries({commit}) {
        return new Promise((resolve, reject) => {
            ApiService.api.app
                .fetchCountries()
                .then((res) => {
                    commit('setCountries', res.data.data)
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getStates(vm, id) {
        return new Promise((resolve, reject) => {
            ApiService.api.app
                .getStates({params: {country_id: id}})
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    getCities(vm, id) {
        return new Promise((resolve, reject) => {
            ApiService.api.app
                .getCities({params: {state_id: id}})
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    initApp({commit, state}, beforeInitialized) {

        if (['/login', '/sign-up'].includes(window.location.pathname) && !state.user?.id) {
            return new Promise((resolve, reject) => {
                const updateState = () => {
                    commit('setInitialized', true)
                    commit('setLoading', false)
                }

                if (beforeInitialized) {
                    beforeInitialized().finally(updateState)
                } else {
                    updateState()
                }
                resolve(state.user)
            })
        }

        return ApiService.api.auth
            .user()
            .then((res) => {
                commit('setUser', res.data.data)
            })
            .finally(() => {
                const updateState = () => {
                    commit('setInitialized', true)
                    commit('setLoading', false)
                }

                if (beforeInitialized) {
                    beforeInitialized().finally(updateState)
                } else {
                    updateState()
                }
            })
    },
    login({commit}, data) {
        return ApiService.api.auth.login(data).then((res) => {
            commit('setUser', res.data.data)
            return res
        })
    },
    loginSuperAdmin({commit}, data) {
        return ApiService.api.superadmin.sendCode(data).then((res) => {
            commit('setUser', res.data.data)
            return res
        })
    },
    loginSuperAdminCompany({commit}, data) {
        return ApiService.api.superadmin.loginCompany(data).then((res) => {
            return ApiService.api.auth.user().then((res) => {
                commit('setUser', res.data.data)
                return res.data.data
            })
        })
    },
    logoutSuperAdminCompany({commit}, data) {
        return ApiService.api.superadmin.logout(data).then(() => {
            return ApiService.api.auth.user().then((res) => {
                commit('setUser', res.data.data)
                return res.data.data
            })
        })
    },
    async logout({commit, state}) {
        return await ApiService.api.auth
            .logout()
            .then(async () => await router.push('/login'))
            .then(() => {
                commit('setUser')
                state.entities.forEach((el) => this.commit(el, {}))
            })
    },
    logoutSuperAdmin({commit}) {
        return ApiService.api.superadmin.logout().then(() => {
            commit('setUser')
        })
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
