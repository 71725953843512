<template>
    <CSidebar position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible"
              @visible-change=" (event) => $store.commit({ type: 'updateSidebarVisible', value: event, })">
        <CSidebarBrand style="background: #ffffff">
            <img v-if="!sidebarUnfoldable" custom-class-name="sidebar-brand-full" src="@/assets/logo.svg" :height="45"/>
            <img v-else custom-class-name="sidebar-brand-full" src="@/assets/logo-min.svg" :height="45"/>
        </CSidebarBrand>
        <AppSidebarNav :key="key"/>
        <div v-if="!sidebarUnfoldable" class="quick">
            <span class="quick__title">QUICK LINKS</span>
            <hr/>
            <a class="quick__link" target="_blank"
               href="https://contractsconnected.freshdesk.com/support/solutions/folders/69000652500">
                Getting Started Guide
            </a>
            <a class="quick__link" target="_blank" href="https://contractsconnected.freshdesk.com/support/tickets/new">Support</a>
        </div>
    </CSidebar>
</template>

<script>
import {computed} from 'vue'
import {useStore} from 'vuex'
import {AppSidebarNav} from './AppSidebarNav'
import {logoNegative} from '@/assets/brand/logo-negative'
import {sygnet} from '@/assets/brand/sygnet'

export default {
    name: 'AppSidebar',
    components: {
        AppSidebarNav,
    },
    setup() {
        const store = useStore()
        return {
            logoNegative,
            sygnet,
            sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
            sidebarVisible: computed(() => store.state.sidebarVisible),
        }
    },
    mounted() {
        this.setMenu()
        window.addEventListener("resize", () => this.setMenu());
    },
    computed: {
        key() {
            return this.$route.params.id || '0'
        }
    },
    methods: {
        setMenu() {
            if (window.innerWidth >= 768) {
                this.$store.commit('setSidebar', true)
                this.$store.commit('setUnfoldable', this.sidebarUnfoldable)
            } else {
                this.$store.commit('setSidebar', false)
                this.$store.commit('setUnfoldable', false)
            }
        }
    }
}
</script>

<style lang="scss">
.sidebar-narrow-unfoldable {
    width: 4rem !important;
}

.sidebar {
    background: #32424E !important;
}

.quick {
    display: flex;
    flex-direction: column;
    padding: 20px 25px;
    background: #465967;

    &__title {
        font-size: 11px;
        color: #C3CDD5;
        white-space: nowrap;
    }

    &__link {
        font-size: 12px;
        color: #DEE4E8;
        text-decoration: underline;
        margin-bottom: 16px;
        white-space: nowrap;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            color: #DEE4E8;
        }
    }
}
</style>
