import ApiService from '@/services/ApiService'
import { BtoF } from '@/services/MomentService'
import { FtoB } from '../services/MomentService'
import Gate from '@/services/Gate/Gate'
import {convertDate} from "@/utils/helper";

export default {
  state: {
    dateErrKeys: [
      'due_date',
      'assign_date',
      'reminders_every',
      'reminders_period',
      'reminders_days',
      'recurrence_every',
      'recurrence_period',
      'recurrence_days',
      'recurrence_until_repeats',
      'recurrence_until_date',
    ],
    task: {},
    entity: {},
    permissions: {
      reviewAndApproveView: false,
      reviewAndApproveEdit: false,
      sendForSignatureView: false,
      signView: false,
      finishView: false,
      create: false,
      edit: false,
      view: false,
    },
    updateTicker: 0
  },
  getters: {
    taskName: (state) => state.task.name,
    taskPermissions: (state) => state.permissions,
    taskUpdateTicker: state => state.updateTicker
  },
  mutations: {
    setTask: (state, data) => (state.task = data),
    setTaskEntity: (state, data) => (state.entity = data),
    setTaskPermissions: async (state) => {
      state.permissions = {
        reviewAndApproveView: await Gate.can('reviewAndApproveView', 'task', state.entity.id),
        reviewAndApproveEdit: await Gate.can('reviewAndApproveEdit', 'task', state.entity.id),
        sendForSignatureView: await Gate.can('sendForSignatureView', 'task', state.entity.id),
        signView: await Gate.can('signView', 'task', state.entity.id),
        finishView: await Gate.can('finishView', 'task', state.entity.id),
        create: await Gate.can('create', 'task'),
        edit: await Gate.can('edit', 'task', state.entity.id),
        view: await Gate.can('view', 'task', state.entity.id),
      }
    },
    updateTicker: state => state.updateTicker++
  },
  actions: {
    fetchTaskPermissions({ commit }, id) {
      commit('setTaskEntity', {})
      return new Promise((resolve, reject) => {
        ApiService.api.app
          .getModelPermissions('task', id)
          .then((res) => {
            commit('setTaskEntity', res.data.data)
            commit('setTaskPermissions')
            resolve(res.data.data)
          })
          .catch((err) => {
            commit('setTaskEntity', {})
            commit('setTaskPermissions')
            resolve({})
            reject(err)
          })
      })
    },
    fetchTask({ commit }, data) {
      commit('setTask', {})
      return new Promise((resolve, reject) => {
        ApiService.api.tasks
          .getTask({ params: data })
          .then((res) => {
            commit('setTask', res.data.data)
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchTasks(vm, data) {
      return new Promise((resolve, reject) => {
        ApiService.api.tasks
          .fetchTasks(data)
          .then((res) => {
            const data = res.data.data.map((i, index) => ({
              ...i,
              id: i.id,
              task_id: i.task_id,
              task_name: i.task_name,
              project_name: i.project_name,
              required_action: i.required_action,
              due_date: BtoF({ value: i.due_date }),
              status: i.status.capitalize(),
              priority: i.priority.capitalize(),
              internal_assignee: i.internal_assignee,
              internal_signer: i.internal_signer,
              external_assignee: i.external_assignee,
              external_signer: i.external_signer,
              package: i.package,
              reminders: i.reminders ? 'Yes' : 'No',
            }))
            resolve({ ...res.data, data: data })
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    makeDateForm(vm, task) {
      return {
        assign_date: task.assigned_date,
        due_date: task.due_date,

        recurrence_day_of_month: task.recurrence_component.recurrence_day_of_month,
        recurrence_days_of_week: task.recurrence_component.recurrence_days_of_week,
        recurrence_enabled: task.recurrence_component.recurrence_enabled,
        recurrence_every: task.recurrence_component.recurrence_every,
        recurrence_period: task.recurrence_component.recurrence_period,
        recurrence_type: task.recurrence_component.recurrence_type,
        recurrence_until_date: task.recurrence_component.recurrence_until_date,
        recurrence_until_repeats: task.recurrence_component.recurrence_until_repeats
      }
    },
    makeTaskForm(vm, data) {

      let taskForm = data.task
      let dateForm = data.date

      let form = {
        task_id: data.save ? taskForm.task_id : taskForm.id,
        name: taskForm.name,
        document_name: taskForm.document_name,
        required_action: taskForm.required_action,
        // action: taskForm.required_action || undefined,
        taskID: taskForm['taskID'],
        internal_assignee_id: taskForm.internal_assignee_id,
        external_assignee_id: taskForm.external_assignee_id,
        external_signer_id: taskForm.external_signer_id || undefined,
        internal_signer_id: taskForm.internal_signer_id || undefined,
        assigned_company: taskForm.assigned_company,
        assigned_folder: taskForm.assigned_folder,
        scope_name: taskForm.scope_name,
        complete_date: taskForm.complete_date,
        attachments: taskForm.attachments,
        attachmentsOld: taskForm.attachments,
        status: taskForm.status,
        description: taskForm.description || '',
        note: taskForm.note || '',
        due_date: convertDate(dateForm.due_date),
        priority: taskForm.priority,
        assign_date: convertDate(dateForm.assign_date),

        recurrence_day_of_month: dateForm.recurrence_day_of_month,
        recurrence_days_of_week: dateForm.recurrence_days_of_week,
        recurrence_enabled: dateForm.recurrence_enabled,
        recurrence_every: dateForm.recurrence_every,
        recurrence_period: dateForm.recurrence_period,
        recurrence_type: dateForm.recurrence_type,
        recurrence_until_date: dateForm.recurrence_until_date,
        recurrence_until_repeats: dateForm.recurrence_until_repeats
      }

      if (data.save) {
        form.tags =
          typeof taskForm.tags !== 'string'
            ? taskForm.tags.join(',')
            : form.tags
      } else {
        form.tags = taskForm.tags
      }

      return form
    },
    makeFormData({ dispatch }, data) {
      const recurrenceFields = [
        'recurrence_day_of_month',
        'recurrence_days_of_week',
        'recurrence_enabled',
        'recurrence_every',
        'recurrence_period',
        'recurrence_type',
        'recurrence_until_date',
        'recurrence_until_repeats'
      ]

      let arrKeys = ['attachments']
      const formData = new FormData()
      dispatch('makeTaskForm', data).then((form) => {

        for (let key in form) {
          if (recurrenceFields.includes(key)) {
            if (typeof form[key] === 'boolean') {
              formData.append(key, Number(form[key]))
            } else {
              if (form[key]) formData.append(key, form[key]);
            }
          } else {
            if (!arrKeys.includes(key) && form[key] !== undefined) formData.append(key, form[key])
          }
        }

        ;['recurrence_days_of_week'].forEach((el) => {
          form[el].forEach((item, index) => {
            formData.append(el + '[' + index + ']', item)
          })
        })

        form.attachments.forEach((file, index) => {
          if (file instanceof File) {
            formData.append('files[' + index + ']', file)
          }
        })
      })

      return formData
    },
    onTickerUpdate({ commit }) {
      commit('updateTicker')
    }
  },
}
