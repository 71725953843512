import LaravelEcho from '@/services/LaravelEcho'
import ApiService from '@/services/ApiService'

const state = () => ({
  chatsData: null,

  chatsUserTags: null,

  currentChatSlug: null,

  messages: [],
})

const actions = {
  getChatsData({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.api.chat
        .getChatsData(data)
        .then((response) => {
          commit('setChatsData', response.data.data)
          resolve(response.data.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getMessages({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.api.chat
        .getMessages(data)
        .then((response) => {
          commit('setMessages', response.data.data)

          if (data.part) {
            commit('setCurrentChatSlug', data.part)
          }

          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  subscribe({ commit, getters }, { channel, event }) {
    return LaravelEcho.private(channel).listen(event, (data) => {
      const channelSuffix = data.message?.channel_suffix

      if (channelSuffix) {
        if (!data.message.deleted && !data.message.edited) {
          commit('setUnreadMessagesCountByChannelSuffix', channelSuffix)
        }
      }

      if (
        getters['currentChatSlug'] === data.message?.channel_suffix ||
        !getters['currentChatSlug']
      ) {
        if (data.message.deleted) commit('deleteMessage', data.message)
        else if (data.message.edited) commit('editMessage', data.message)
        else commit('sendMessage', data.message)
      }
    })
  },
  unsubscribe(store, channel) {
    return LaravelEcho.leave(channel)
  },

  handleDeleteMessage({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.api.chat
        .deleteMessage({ message_id: data.id })
        .then((response) => {
          commit('deleteMessage', data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  handleEditMessage({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.api.chat
        .editMessage(data)
        .then((response) => {
          commit('editMessage', data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  handleSendMessage({ commit }, data) {
    return new Promise((resolve, reject) => {
      ApiService.api.chat
        .sendMessage(data)
        .then((response) => {
          commit('sendMessage', response.data.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  handleSetAsMarked({ commit }, { messageId, userId, channelSuffix }) {
    return new Promise((resolve, reject) => {
      ApiService.api.chat
        .setAsMarked({ last_message_id: messageId })
        .then(() => {
          commit('setAsMarked', { messageId, userId, channelSuffix })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

const getters = {
  messages: (state) => state.messages,
  chatsData: (state) => state.chatsData,
  chatsUserTags: (state) => state.chatsUserTags,
  currentChatSlug: (state) => state.currentChatSlug,
}

const mutations = {
  setUnreadMessagesCountByChannelSuffix: (state, channelSuffix) => {
    state.chatsData = {
      ...state.chatsData,
      [channelSuffix]: state.chatsData[channelSuffix] + 1,
    }
  },
  setChatsData: (state, data) => {
    if (data.parts) {
      state.chatsData = {
        editing: data.parts?.editing?.not_read_count,
        approving: data.parts?.approving?.not_read_count,
      }
      state.chatsUserTags = {
        editing: data.parts.editing?.tag_users,
        approving: data.parts.approving?.tag_users,
      }
    } else if (data.tag_users) {
      state.chatsUserTags = data.tag_users
    }
  },
  setMessages: (state, data) => (state.messages = data),
  setCurrentChatSlug: (state, data) => (state.currentChatSlug = data),
  deleteMessage: (state, data) => {
    state.messages = state.messages.map((el) => {
      return el.id === data.id ? { ...el, deleted: true } : el
    })
  },
  editMessage: (state, data) => {
    const messageId = data.message_id || data.id

    state.messages = state.messages.map((el) => {
      return el.id === messageId
        ? {
            ...el,
            content: data.content,
            edited: true,
          }
        : el
    })
  },
  sendMessage: (state, data) => {
    state.messages = [...state.messages, data]
  },
  setAsMarked: (state, { messageId, userId, channelSuffix }) => {
    let find = false

    state.messages = state.messages
      .reverse()
      .map((el) => {
        if (el.id === +messageId) {
          find = true
        }

        if (find) {
          return {
            ...el,
            marked_user_ids: [...el.marked_user_ids, userId],
          }
        } else {
          return el
        }
      })
      .reverse()

    if (channelSuffix) {
      state.chatsData[channelSuffix] = state.messages?.filter(
        (message) => !message.marked_user_ids.includes(userId),
      )?.length
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
