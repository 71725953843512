import {asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    login: data => asJson(axios).post(getVerUri('superuser/login'), data),
    signUp: data => asJson(axios).post(getVerUri('sign-up/company-owner'), data),
    loginCompany: data => asJson(axios).post(getVerUri('superuser/sign-in/company-admin'), data),
    logout: data => asJson(axios).post(getVerUri('superuser/sign-out/company-admin'), data),
    sendCode: data => asJson(axios).post(getVerUri('superuser/verify'), data),
    resendCode: data => asJson(axios).post(getVerUri('superuser/resend'), data),
    getCompanyList: data => asJson(axios).get(getVerUri('superuser/companies'), data),
    superuserAccountCreate: data => asJson(axios).post(getVerUri('superuser/account/create'), data),
    companyDetails: id => asJson(axios).get(getVerUri('superuser/companies/' + id)),
    companyDetailsActivity: (id, data) => asJson(axios).get(getVerUri('superuser/companies/' + id + '/users-activity'), data),
    blockCompany: companyId => asJson(axios).post(getVerUri(`superuser/companies/${companyId}/block-unblock`)),
    deleteCompany: companyId => asJson(axios).delete(getVerUri(`superuser/companies/${companyId}`))
});
