<template>
    <CDropdown variant="nav-item">
        <CDropdownToggle placement="bottom-end" class="py-0 position-relative" :caret="false">
            <Avatar :src="avatar" :display-name="[user.first_name, user.last_name]" :size="40"/>
            <Avatar v-if="avatarCompany && user.is_superuser" :display-name="[user.company.name]" :size="20"
                    :src="avatarCompany" class="company__logo"/>
        </CDropdownToggle>
        <CDropdownMenu class="pt-0">
            <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">Account</CDropdownHeader>
            <div v-if="!user.is_superuser && !is_superadmin">
                <CDropdownItem @click=" $router.push({ name: 'MyProfile', params: { id: user.id } })">
                    My Profile
                </CDropdownItem>
                <CDropdownItem
                    @click="$router.push({ name: 'CompanyInfo', params: { id: $store.getters.user?.company?.id } })">
                    Company Info
                </CDropdownItem>
                <CDropdownItem @click="$router.push({ name: 'Notifications' })">
                    Notifications
                </CDropdownItem>
            </div>
            <CDropdownItem v-if="user.is_superuser && !is_superadmin" style="color: red !important"
                           @click="handleLogoutSU">
                Back to admin panel
            </CDropdownItem>
            <CDropdownItem v-else @click="handleLogout">
                Logout
            </CDropdownItem>
        </CDropdownMenu>
    </CDropdown>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import modalComponent from '@/mixin/modalComponent'
import Avatar from '@/components/Avatar.vue'

export default {
    name: 'AppHeaderDropdownAccnt',
    components: {Avatar},
    mixins: [modalComponent],
    props: {
        is_superadmin: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            itemsCount: 42,
        }
    },
    computed: {
        ...mapGetters(['user']),
        avatar() {
            return this.user.photo + '?time=' + new Date().getTime();
        },
        avatarCompany() {
            return this.user.company
                ? this.user.company.logo + '?time=' + new Date().getTime()
                : null;
        },
    },
    methods: {
        ...mapMutations(['setLoading']),
        ...mapActions(['logout', 'logoutSuperAdmin', 'logoutSuperAdminCompany']),
        handleLogout() {
            this.setLoading(true)
            if (this.is_superadmin) {
                this.logoutSuperAdmin().finally(() => {
                    this.logout().finally(() => {
                        this.setLoading(false)
                    })
                })
            } else {
                this.logout().finally(() => {
                    this.setLoading(false)
                })
            }
        },
        handleLogoutSU() {
            this.$modal
                .open({
                    close: 'Cancel',
                    success: 'Yes, log out',
                    label: 'Logout',
                    sublabel: `Are you sure you want to log out of "${
                        this.user.company?.name || '--'
                    }" account?`,
                    size: 'lg',
                })
                .then(() => {
                    this.logoutSuperAdminCompany().finally(() =>
                        this.$router.push({name: 'SuperAdminCompany'}),
                    )
                    this.$modal.close()
                })
        },
    },
}
</script>

<style lang="scss">
.company__logo {
    position: absolute !important;
    height: 20px !important;
    width: 20px !important;
    top: 56% !important;
    left: 66% !important;

    img {
        height: 100% !important;
        width: 100% !important;
        object-fit: cover;
    }
}
</style>
