import Multiselect from '@vueform/multiselect'
import Projects from './types/Projects'
import Tasks from './types/Tasks'
import Company from './types/Company'
import InvitedCompany from './types/InvitedCompany'
import InvitedEmployees from './types/InvitedEmployees'
import Users from './types/Users'
import SuperAdminCompany from './types/SuperAdminCompany'
import TaskBoard from './types/TaskBoard'
import { isEqual } from '@/utils/helper'
import Badges from './components/Badges'
import Templates from './types/Templates'

export default {
  name: 'GCFilter',
  components: {
    Multiselect,
    Projects,
    Badges,
    Tasks,
    Company,
    Users,
    SuperAdminCompany,
    InvitedCompany,
    InvitedEmployees,
    TaskBoard,
    Templates,
  },
  props: {
    statuses: { type: Array, default: [] },
    style: { type: [Array, Object, String], default: '' },
    default: { type: Object, default: {} },
    component: { type: String, required: true },
    exceptColumn: { type: Array, default: [] },
  },
  data() {
    return {
      isOpen: false,
      isDisabled: false,
      form: {},
      selected: [],
      badges: [],
      taskStatuses: [],
      taskTypes: [],
    }
  },
  watch: {
    selected: function () {
      for (let key in this.form)
        if (!this.selected.includes(key)) this.form[key] = null
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setDataForm()
      this.setBadge()
      this.$watch(
        () => this.$refs.itemType.form,
        (val) => (this.isDisabled = this.checkDisabled(val)),
        { deep: true },
      )
      this.initOutsideClick()
      this.isDisabled = isEqual(
        this.$refs.itemType.form,
        this.$refs.itemType.origin,
      )
      this.setQuerySearch()
    })

    this.$EventBus.on('clearGCFilter', this.handleRemove)
  },
  unmounted() {
    this.$EventBus.off('clearGCFilter', this.handleRemove)
  },
  methods: {
    updated(params = {}) {
      this.setParamByQuery()
    },
    checkDisabled(val) {
      let f = this.$deepClone(this.form)
      delete f.search
      return (
        isEqual(val, this.$refs.itemType.origin) &&
        isEqual(this.$refs.itemType.origin, f)
      )
    },
    setBadge() {
      this.badges = this.$refs.itemType
        .getBadgeItems(this.form)
        .filter((el) => !!el.value)
    },
    handleInput(e) {
      this.form.search = e.target.value
      this.$emit('search', this.normaliseForm())
    },
    setQuerySearch() {
      if (this.$route.query.search) {
        this.form.search = this.$route.query.search || ''
        this.$emit('search', this.normaliseForm())
      }
    },
    toggleSearchBody() {
      this.isOpen = !this.isOpen
    },
    setDataForm() {
      this.form = { ...this.form, ...this.$deepClone(this.$refs.itemType.form) }
      if (this.default.form) this.form = { ...this.form, ...this.default.form }

      if (this.default.selected)
        this.selected = [...this.selected, ...this.default.selected]

      // this.setParamByQuery()
    },
    setParamByQuery() {
      if (this.$route.query.status) this.setStatus()
      if (this.$route.query.is_overdue && this.$route.query.is_overdue === 'true') {
        this.setIsOverdue()
      }
    },
    setIsOverdue() {
      this.form.is_overdue = 1
      this.$refs.itemType.form.is_overdue = 1
      this.handleSearch()
    },
    setStatus() {
      this.form.status = Array.isArray(this.$route.query.status)
        ? this.$route.query.status
        : [this.$route.query.status]

      this.$refs.itemType.form = this.$deepClone(this.form)
      this.handleSearch()
      this.$refs.itemType.taskStatuses = this.$deepClone(
        this.$refs.itemType.taskStatuses,
      )

      this.form.status.forEach((status) => {
        this.$refs.itemType.taskStatuses.forEach((item, index) => {
          if (item.value === status) {
            this.$refs.itemType.taskStatuses[index]['selected'] = true
          }
        })
      })
    },
    initOutsideClick() {
      document.addEventListener('click', (evt) => {
        if (this.$refs.GCFilter) {
          let flyoutEl = this.$refs.GCFilter.$el
          let targetEl = evt.target

          let checkInBoxSelect =
            targetEl.className &&
            typeof targetEl.className === 'string' &&
            targetEl.className.includes('form-multi-select-selection-cleaner')

          let checkInClear =
            typeof targetEl.className === 'object' &&
            targetEl.className['baseVal'].includes('CInputDate__clear')

          do {
            if (
              (targetEl === flyoutEl && this.isOpen) ||
              checkInBoxSelect ||
              checkInClear
            ) {
              this.isOpen = true
              return
            }
            targetEl = targetEl.parentNode
          } while (targetEl)
          {
            this.isOpen = false
          }
        }
      })
    },
    delayCheck(delay = 10) {
      setTimeout(
        () =>
          (this.isDisabled = isEqual(
            this.$refs.itemType.form,
            this.$refs.itemType.origin,
          )),
        delay,
      )
    },
    handleSearch() {
      this.form = { ...this.form, ...this.$deepClone(this.$refs.itemType.form) }
      this.$emit('search', this.normaliseForm())
      this.setBadge()
      this.isOpen = false
      this.delayCheck()
    },
    handleRemove(key = [], needEmit = true) {
      this.$refs.itemType.removeByKey(key)
      if (!key.length) {
        this.form = this.$deepClone(this.$refs.itemType.origin)
      } else {
        key.forEach((name) => (this.form[name] = undefined))
      }
      this.setBadge()
      if (needEmit) {
        this.$emit('search', this.normaliseForm())
      }
      this.isOpen = false
      this.delayCheck(100)
    },
    normaliseForm() {
      const data = {}
      for (const [key, value] of Object.entries(this.form)) {
        if (value instanceof Date) data[key] = this.$FtoB({ value: value })
        else data[key] = value || undefined
      }

      return data
    },
  },
}
