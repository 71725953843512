import MSelect from '../components/MSelect'
import CInputDate from '../../CInputDate'

export default {
  components: { MSelect, CInputDate },
  props: {
    exceptColumn: { type: Array, default: [] },
  },
  data() {
    return {
      origin: {},
      key: 0,
      form: {},
    }
  },
  methods: {
    removeByKey(key = []) {
      if (key.length) key.forEach((k) => (this.form[k] = this.origin[k]))
      else this.form = JSON.parse(JSON.stringify(this.origin))
      this.key++
    },
    setOrigin() {
      this.origin = JSON.parse(JSON.stringify(this.form))
    },
    checkRange(checkKey, eqKey, can) {
      let checkVal = this.form[checkKey]
      if (checkVal < 0) {
        this.form[checkKey] = 0
        this.key++
        return
      }

      if (can === 'max' && parseInt(checkVal) > parseInt(this.form[eqKey])) {
        this.form[checkKey] = this.form[eqKey]
        this.key++
      }

      if (can === 'min' && parseInt(checkVal) < parseInt(this.form[eqKey])) {
        this.form[checkKey] = this.form[eqKey]
        this.key++
      }
    },
    valueDateRange(from, to) {
      if (from && to) return `from ${this.$BtoF({value: from})} &#151; to ${this.$BtoF({value: to})}`
      if (from && !to) return `from ${this.$BtoF({ value: from })}`
      if (!from && to) return `to ${this.$BtoF({ value: to })}`

      return ''
    },
    valueRange(from, to) {
      if (from && to) return `from ${from} &#151; to ${to}`
      if (from && !to) return `from ${from}`
      if (!from && to) return `to ${to}`

      return ''
    },
    badgeBySelectOptions(formKey, listVarName, idInt = true) {
      let id = this.form[formKey][0] || null
      let item = idInt
        ? this[listVarName].filter((el) => parseInt(el.value) === parseInt(id))
        : this[listVarName].filter((el) => el.value === id)
      let more = this.form[formKey].length > 1 ? ` (+ ${this.form[formKey].length - 1} more)` : ''
      return item.length ? item[0]['text'] + more : ''
    },
    getBadgeItems() {
      return []
    },
  },
}
