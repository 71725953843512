<template>
    <div :class="{ 'c-dark-theme': $store.state.darkMode }" class="c-app">
        <CContainer class="d-flex align-items-center min-vh-100">
            <CRow class="w-100 justify-content-center">
                <CCol md="6">
                    <div class="w-100">
                        <div class="clearfix">
                            <h4 class="pt-3">
                                To Many Attempts
                            </h4>

                            <p class="text-muted">
                                <submit-btn
                                    color="primary"
                                    @click="$router.back()"
                                >
                                Back
                                </submit-btn>
                            </p>
                        </div>
                    </div>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<style lang="scss" scoped>
    .expired {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>