<template>
  <div>
    <div :class="['cn-form-textarea', { 'fl_invalid': invalid }]" @click.prevent="handleFocus">
      <textarea
        id="cnFormTextarea"
        ref="textarea"
        v-model="propVal"
        class="cn-form-textarea__field"
        :class="[
          textareaClass,
          {
            'not-empty': propVal && !!propVal.trim()
          },
        ]"
        :disabled="disabled"
        :maxlength="maxLength"
        @input="$emit('update:modelValue', $event.target.value)"
        @blur="$emit('blur', $event.target.value)"
      />
      <label v-show="label" for="cnFormTextarea">
        {{ label }}
        <sup v-if="required" :class="{ 'text-error': true }">*</sup>
      </label>
    </div>
    <CFormText v-if="error" class="text-error">{{ error }}</CFormText>
  </div>
</template>

<script>
export default {
  name: 'CNTextarea',
  props: {
    invalid: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    modelValue: String,
    error: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    textareaClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: undefined,
    },
  },
  emits: ['update:modelValue', 'blur'],
  computed: {
    propVal: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      },
    },
  },
  mounted() {},
  methods: {
    handleFocus() {
      this.$nextTick(() => this.$refs.textarea.focus())
    },
  },
}
</script>
<style lang="scss">
.cn-form-textarea {
  width: 100%;
  position: relative;
  background: white;
  border: 1px solid #9fafbc;
  border-radius: 8px;
  padding: 30px 2px 2px 16px;

  textarea:disabled {
    background: #fff;
  }

  &__field {
    min-height: 112px;
    width: 100%;
    height: 100%;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.002em;
    color: #1c262f;
    display: block;
    border: none;
    outline: none;

    &.validation-error {
      border-color: red;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #677a89;
    }

    &.not-empty + label,
    &:focus + label {
      top: 8px;
      left: 16px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.003em;
    }
  }

  label {
    cursor: pointer;
    display: block;
    position: absolute;
    pointer-events: none;
    font-size: 16px;
    line-height: 24px;
    left: 16px;
    top: 16px;
    color: #677a89;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    letter-spacing: 0.002em;
  }
}
</style>
