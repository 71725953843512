import { Type } from './Type'

export class Note extends Type {
  async list(companyId) {
    companyId = parseInt(companyId)
    if (!companyId) return false

    return super.auth().company.id !== companyId && super.auth().can(super.permissions().COMPANIES_VIEW)
  }

  async actions(companyId) {
    companyId = parseInt(companyId)
    if (!companyId) return false
    return super.auth().company.id !== companyId && !super.auth().hasRole(super.roles().GENERAL_VIEW_ONLY)
  }
}
