<template>
  <teleport to="body">
    <div v-if="modalComponent" class="modalComponent-bg" />
    <div
      v-if="modalComponent"
      class="modalComponent modalContent"
      :class="`${modalComponent.size === 'lg' ? 'lg' : 'sm'}`"
    >
      <div v-if="modalComponent.xVisible" class="modalComponent-close">
        <CCloseButton @click="reject" />
      </div>

      <div
        v-if="modalComponent.icon"
        :id="modalComponent.icon"
        class="mb-4 d-flex justify-content-center"
      />

      <div v-if="modalComponent.label" class="modalComponent-label">
        {{ modalComponent.label }}
      </div>
      <div
        v-if="modalComponent.sublabel"
        class="modalComponent-sublabel"
        v-html="modalComponent.sublabel"
      />

      <div
        v-if="modalComponent.slot"
        :id="modalComponent.slot"
        class="mb-2 d-flex justify-content-center modalComponent-slot"
      />

      <div
        class="modalComponent-btn__wrap"
        :class="`flex-${
          modalComponent.btnDirection === 'row' ? 'row-reverse' : 'column'
        }`"
      >
        <CButton
          v-if="modalComponent.success"
          color="primary"
          :disabled="modalComponent.loading"
          class="m-2 mt-0 modalComponent-btn"
          @click="resolve"
        >
          <CSpinner v-if="modalComponent.loading" size="sm" />
          {{ modalComponent.success }}
        </CButton>
        <CButton
          v-if="modalComponent.close"
          color="primary"
          variant="outline"
          class="m-2 mt-0 modalComponent-btn"
          @click="reject"
          >{{ modalComponent.close }}</CButton
        >
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  emits: ['resolve', 'reject'],
  data: () => ({}),
  computed: {
    ...mapGetters(['modalComponent']),
  },
  methods: {
    resolve() {
      this.$emit('resolve')
    },
    reject() {
      this.$emit('reject')
    },
  },
}
</script>

<style lang="scss" scoped>
.modalComponent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 7px 16px 2px rgba(0, 0, 0, 0.11);
  border-radius: 12px;
  z-index: 99999999;
  padding: 24px 30px;

  &.sm {
    width: 100%;
    max-width: 688px;
    padding: 56px 40px 40px 40px;

    & .modalComponent-btn__wrap.flex-column {
      max-width: 100%;

      & .modalComponent-btn {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    & .modalComponent-btn__wrap {
      margin-top: 36px;
      max-width: 100%;

      & .modalComponent-btn {
        min-width: 200px;
        width: auto;
      }
    }
  }

  &.lg {
    width: 100%;
    max-width: 688px;

    & .modalComponent-btn__wrap {
      margin-top: 48px;
      max-width: 100%;

      & .modalComponent-btn {
        min-width: 200px;
      }
    }

    & .modalComponent-btn__wrap.flex-column {
      width: 200px;

      & .modalComponent-btn {
        width: 200px;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  &-close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  &-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.45);
    z-index: 9999999;
  }

  &-btn {
    &__wrap {
      margin: 16px auto 0;
      display: flex;
      justify-content: center;

      &.flex-column {
        max-width: 216px;
      }

      &.flex-row-reverse .modalComponent-btn {
        &:first-child {
          margin-right: 0 !important;
        }

        &:last-child {
          margin-left: 0 !important;
        }
      }
    }
  }

  &-label {
    //font-size: 20px;
    //font-weight: 600;
    //color: #202e39;
    //text-align: center;
    //margin-bottom: 12px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #1c262f;
    text-align: center;
  }

  &-sublabel {
    //font-size: 18px;
    //font-weight: 400;
    //color: #465967;
    //text-align: center;
    //margin-bottom: 32px;
    margin-top: 16px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: rgb(70, 89, 103);
  }

  &-slot {
    margin-top: 30px;
  }
}
</style>
