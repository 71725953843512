import { createStore } from 'vuex'

import app from './app.module'
import auth from './auth.module'
import projects from './projects.module'
import users from './users.module'
import companies from './companies.module'
import setting from './setting.module'
import template from './template.module'
import library from './library.module'
import tasks from './tasks.module'
import offices from './offices.module'
import permissionGroup from './permissionGroup.module'
import discussions from './discussions.module'
import contract from './contract.module'
import chat from './chat.module'
import toast from './toast.module'
import scope from './scope.module'
import dashboard from './dashboard.module'

export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: 'default',
    updateModal: null,
  },
  getters: {
    updateModal: (state) => state.updateModal,
    sidebarUnfoldable: (state) => state.sidebarUnfoldable,
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    setSidebar(state, value) {
      state.sidebarVisible = value
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    setUnfoldable(state, value) {
      state.sidebarUnfoldable = value
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setUpdateModal(state, val) {
      state.updateModal = val
    },
  },
  actions: {},
  modules: {
    app,
    auth,
    projects,
    users,
    companies,
    setting,
    template,
    library,
    tasks,
    offices,
    permissionGroup,
    discussions,
    contract,
    chat,
    toast,
    scope,
    dashboard,
  },
})
