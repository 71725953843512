<template>
  <CRow :key="key" :xs="{ cols: 1, gutter: 3 }" class="text-dark">
    <CCol>
      <CRow>
        <CFormLabel class="col-12 col-form-label filterLabelWidth">Company Name</CFormLabel>
        <CCol class="mx-0">
          <CFormInput v-model="form.company_name" placeholder="Company Name" />
        </CCol>
      </CRow>
    </CCol>
    <CCol>
      <CRow>
        <CFormLabel class="col-12 filterLabelWidth col-form-label">Company Type</CFormLabel>
        <CCol class="mx-0">
          <MSelect v-model="form.type" :options="types" placeholder="Select Company Type" style="z-index: 9999999999 !important;" />
        </CCol>
      </CRow>
    </CCol>
    <CCol class="countFilterBlock">
      <CRow :xs="{ cols: 1, gutterY: 3 }" :xl="{ cols: 3 }" class="justify-content-between">
        <CCol class="col-lg-auto">
          <CRow>
            <CFormLabel class="filterLabelWidth col-12 col-form-label">Projects:</CFormLabel>
            <CCol>
              <CRow class="align-items-center">
                <CCol class="lInput">
                  <CFormInput v-model="form.projects_min" type="number" placeholder="Min" @blur="checkRange('projects_min', 'projects_max', 'max')" />
                </CCol>
                <CCol class="col-auto p-0 text-center">-</CCol>
                <CCol class="rInput">
                  <CFormInput v-model="form.projects_max" type="number" placeholder="Max" @blur="checkRange('projects_max', 'projects_min', 'min')" />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
        <CCol class="col-lg-auto">
          <CRow>
            <CFormLabel class="filterLabelWidth filterLabelTask col-12 col-form-label">Tasks:</CFormLabel>
            <CCol>
              <CRow class="align-items-center">
                <CCol class="lInput">
                  <CFormInput v-model="form.tasks_min" type="number" placeholder="Min" @blur="checkRange('tasks_min', 'tasks_max', 'max')" />
                </CCol>
                <CCol class="col-auto p-0">-</CCol>
                <CCol class="rInput">
                  <CFormInput v-model="form.tasks_max" type="number" placeholder="Max" @blur="checkRange('tasks_max', 'tasks_min', 'min')" />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
        <CCol class="col-lg-auto">
          <CRow>
            <CFormLabel class="filterLabelWidth filterLabelOverdue col-12 col-form-label">Tasks overdue:</CFormLabel>
            <CCol>
              <CRow class="align-items-center">
                <CCol class="lInput">
                  <CFormInput v-model="form.tasks_overdue_min" type="number" placeholder="Min" @blur="checkRange('tasks_overdue_min', 'tasks_overdue_max', 'max')" />
                </CCol>
                <CCol class="col-auto p-0">-</CCol>
                <CCol class="rInput">
                  <CFormInput v-model="form.tasks_overdue_max" type="number" placeholder="Max" @blur="checkRange('tasks_overdue_max', 'tasks_overdue_min', 'min')" />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCol>
    <CCol>
      <CRow>
        <CFormLabel class="col-12 filterLabelWidth col-form-label">Contact Name</CFormLabel>
        <CCol class="mx-0">
          <CFormInput v-model="form.contact_name" placeholder="Contact Name" />
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import mixin from './mixin'
export default {
  name: 'Company',
  mixins: [mixin],
  data() {
    return {
      form: {
        company_name: '',
        type: [],
        projects_min: '',
        projects_max: '',
        tasks_min: '',
        tasks_max: '',
        tasks_overdue_min: '',
        tasks_overdue_max: '',
        contact_name: '',
      },
      types: [
        { value: 'general_contractor', text: 'General Contractor' },
        { value: 'sub_contractor', text: 'Sub Contractor' },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => this.setOrigin())
  },
  methods: {
    getBadgeItems(outForm = undefined) {
      const form = outForm ? outForm : this.form
      return [
        { label: 'Company', value: form.company_name, key: ['company_name'] },
        { label: 'Contact', value: form.contact_name, key: ['contact_name'] },
        { label: 'Type', value: this.badgeBySelectOptions('type', 'types', false), key: ['type'] },
        { label: 'Projects', value: this.valueRange(form.projects_min, form.projects_max), key: ['projects_min', 'projects_max'] },
        { label: 'Tasks', value: this.valueRange(form.tasks_min, form.tasks_max), key: ['tasks_min', 'tasks_max'] },
        { label: 'Tasks overdue', value: this.valueRange(form.tasks_overdue_min, form.tasks_overdue_max), key: ['tasks_overdue_min', 'tasks_overdue_max'] },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  .filterLabelWidth {
    width: 125px !important;
  }

  .countFilterBlock {
    .filterLabelTask {
      width: 70px !important;
    }
    .filterLabelOverdue {
      width: 125px !important;
    }

    input {
      padding: 0 !important;
      width: 40px;
      text-align: center;
    }

    .rInput {
      padding-left: 5px
    }
    .lInput {
      padding-right: 5px
    }
  }
}
</style>
