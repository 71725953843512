<template>
  <div class="p-0">
    <SmartTable
      :name="'table-setting-account-company-details-office'"
      :items="offices"
      :columns="officeFields.map((i) => ({ ...i, sorter: false }))"
      infinity
    >
    </SmartTable>
  </div>
</template>
<script>
export default {
  name: 'Office',
  inject: ['toast'],
  props: {
    offices: { type: Array, default: () => [] },
  },
  emits: ['updateData'],
  data() {
    return {
      loading: false,
      officeFields: [
        { key: 'name', label: 'Office or Subsidiary Name' },
        { key: 'address', label: 'Street Address' },
        { key: 'city', label: 'City' },
        { key: 'state', label: 'State' },
        { key: 'zip_code', label: 'Zip' },
        { key: 'country', label: 'Country' },
        { key: 'phone', label: 'Phone' },
        { key: 'fax', label: 'Fax' },
        { key: 'is_headquarters', label: 'Headquarters', format: value => value ? 'Yes' : 'No' }
      ],
    }
  },
  methods: {
    goWebsite(url) {
      window.open(url[0] !== 'h' ? 'https://' + url : url, '_blank')
    },
  },
}
</script>
