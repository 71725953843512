const timeout = 500;

export default {
  data() {
    return {
      _searchTimeout: null
    }
  },
  methods: {
    onTimeoutSearch(callback, log) {
      clearTimeout(this._searchTimeout);
      this._searchTimeout = setTimeout(callback, timeout);
    }
  }
}
