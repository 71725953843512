import {asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    getChartDetails: params => asJson(axios).get(getVerUri('main-dashboard/details'), {params}),
    getChartEntityTeam: params => asJson(axios).get(getVerUri('main-dashboard/teams'), {params}),
    getCharts: params => asJson(axios).get(getVerUri('main-dashboard/charts'), {params}),
    getTimeline: params => asJson(axios).get(getVerUri('main-dashboard/timeline'), {params}),
    getNotifications: params => asJson(axios).get(getVerUri('main-dashboard/notifications'), {params}),
    getInvitations: () => asJson(axios).get(getVerUri('main-dashboard/invitations')),
})
