// function middlewarePipeline(context, middleware, index) {
//   const nextMiddleware = middleware[index]
//   if (!nextMiddleware) {
//     return context.next
//   }
//   return () => {
//     const nextPipeline = middlewarePipeline(context, middleware, index + 1)
//     nextMiddleware({ ...context, next: nextPipeline })
//   }
// }

async function middlewarePipeline(context) {
  if (Array.isArray(context.to.meta.middleware) && context.to.meta.middleware.length) {
    await context.to.meta.middleware.forEach((el) => el(context))
  }
}

export default middlewarePipeline
