import permissions from '@/utils/constants/permissions'
import Gate from '@/services/Gate/Gate'
import { notifyByPlan } from '@/services/Notify/Toasts'

export default [
  {
    component: 'CNavItem',
    name: 'My Dashboard',
    to: { name: 'Dashboard' },
    icon: 'cilHome',
    permissions: [],
    checkTo: async () => await Gate.can('view', 'dashboard').then(async (res) => res),
    callback: async () =>
      await Gate.can('view', 'dashboard').then(async (res) => {
        if (!res) return notifyByPlan()
      }),
  },
  {
    component: 'CNavItem',
    name: 'Projects',
    to: { name: 'ProjectsList' },
    permissions: [permissions.PROJECTS_VIEW],
    icon: 'cilProjects',
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Contracts & Scopes',
  //   to: { name: 'ProjectContractsScopes', params: { id: 'all' } },
  //   permissions: [permissions.PROJECTS_VIEW],
  //   icon: 'cilCheckCircle',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Tasks',
  //   to: { name: 'TasksList' },
  //   icon: 'cilTask',
  //   permissions: [permissions.TASKS_AND_DOCUMENTS_VIEW],
  // },
  {
    component: 'CNavItem',
    name: 'Companies',
    to: { name: 'CompaniesList' },
    icon: 'cilFactory',
    permissions: [permissions.COMPANIES_VIEW],
  },
  {
    component: 'CNavItem',
    name: 'Network Users',
    to: { name: 'Users' },
    icon: 'cilUser',
    permissions: [permissions.USERS_VIEW],
  },
  {
    component: 'CNavItem',
    name: 'Templates',
    to: { name: 'Templates' },
    // to: { name: 'Templates' },
    icon: 'cilFile',
    permissions: [permissions.TEMPLATES_VIEW],
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Library',
  //   to: { name: 'Library' },
  //   // to: { name: 'LibraryDetailsRequest' },
  //   icon: 'cilCopy',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Insights',
  //   to: { name: 'PageNotFound' },
  //   icon: 'cilChart',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Exporter',
  //   to: { name: 'PageNotFound' },
  //   icon: 'cilCloudDownload',
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Drop Box',
  //   to: { name: 'PageNotFound' },
  //   icon: 'cilFolderOpen',
  // },
  {
    component: 'CNavItem',
    name: 'Quick Access',
    to: { name: 'QuickAccess' },
    icon: 'cilSpreadsheet',
    permissions: [],
  },
]
