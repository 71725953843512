<template>
  <div>
    <div
      :class="[
        'cn-form-select',
        'cn-form-select-' + variant,
        invalid ? 'validation-error' : '',
        disabled && 'cn-form-select-disabled',
        searchable || search ? 'cn-form-select-searchable' : '',
      ]"
    >
      <Multiselect
        v-if="isTags"
        v-model="propVal"
        mode="tags"
        class="w-100 cn-form-select-tags"
        :placeholder="placeholder"
        :loading="loading"
        :disabled="loading || disabled"
        :close-on-select="false"
        :searchable="true"
        :create-option="true"
        :options="options"
      />

      <Multiselect
        v-else
        ref="select"
        v-model="propVal"
        :options="options"
        :can-clear="clearable"
        :can-deselect="canDeselect"
        :searchable="searchable || search"
        :loading="loading"
        :close-on-deselect="true"
        label="name"
        class="w-100"
        :disabled="disabled"
        @search-change="searchChange"
        @open="handleOpen"
        @close="handleClose"
        @change="handleChange"
      >
        <template #nooptions>
          <slot name="nooptions" />
        </template>

        <template #caret>
          <CIcon
            v-if="searchable && !caret"
            class="caret"
            name="cilSearch"
            style="z-index: 1"
          />
          <span
            v-else
            class="multiselect-caret caret"
            :class="{ caretOpen: isOpen }"
            aria-hidden="true"
            :style="{...cssProps, ...caretColor}"
          />
          <!--        <CIcon-->
          <!--          v-else-->
          <!--          :class="{ caretOpen: isOpen }"-->
          <!--          class="caret"-->
          <!--          name="cilDropdownArrow"-->
          <!--        />-->
        </template>
      </Multiselect>


      <label
        v-show="label"
        :class="[
          'cn-form-select-label',
          modelValue ? 'cn-form-select-label-selected' : '',
        ]"
        @click="$refs.select.open()"
      >
        {{ label }}<sup v-if="required" :class="{ 'text-danger': true }">*</sup>
      </label>
    </div>

    <CFormText v-if="error" class="text-error" v-html="error" />

    <CFormText
      v-if="informationText"
      class="text-information"
      v-html="informationText"
    />
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import { debounce } from '@/utils/debounce'
export default {
  name: 'CNSelect',
  components: { Multiselect },
  props: {
    caret: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => [],
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    informationText: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    modelValue: String,
    variant: {
      type: String,
      default: 'border',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    canDeselect: {
      type: Boolean,
      default: false,
    },
    isTags: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'change', 'search'],
  data() {
    return {
      isOpen: false,
      cssProps: {
        '-webkit-mask-image': `url(${require('@/assets/Joel/caret.svg')})`,
        margin: 0,
      },
      isFocused: false
    }
  },
  computed: {
    caretColor() {
      return {'background-color': this.invalid ? 'red' : '#0895D1'}
    },
    propVal: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue ? newValue : null)
      },
    },
  },
  methods: {
    searchChange: debounce(function (event) {
      if (event) this.$emit('search', event)
    }, 500),
    handleChange(val) {
      this.$emit('update:modelValue', val ? val : null)
      this.$emit('change', val ? val : null)
    },
    handleClose() {
      this.handleChange(this.propVal)
      this.isOpen = false
    },
    handleOpen() {
      this.isOpen = true
    },
  },
}
</script>

<style lang="scss">
@import "@/styles/floating-labels";

.cn-form-select {
  width: 100%;
  position: relative;
  border-radius: 8px;

  &-disabled {
    pointer-events: none;
  }

  .is-active .multiselect-search {
    width: 90% !important;
  }

  .is-active,
  .is-open {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.cn-form-select-searchable:has(.is-active) label {
  top: 8px !important;
  left: 16px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.003em;
}

.cn-form-select-searchable .is-active input {
  padding: 0;
  top: 15px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.002em;
  color: #1c262f;
  height: 24px;
}

.cn-form-select-border {
  .multiselect {
    border: 1px solid #9fafbc;
    border-radius: 8px;
    padding: 16px;
    height: 56px;
    position: relative;
  }

  &:has(> .is-active) {
    box-shadow: 0 0 0 4px #ABE7FA;
    border-radius: 8px;
    transition: 0.3s;
  }

  .cn-form-select-tags {
    padding: 0;
    height: auto;
    min-height: 56px;
  }

  .caret {
    transition: transform 300ms ease;
    width: 24px !important;
    height: 24px !important;
    z-index: 1;
    cursor: pointer;
  }

  .caretOpen {
    transform: rotate(-180deg);
    transition: transform 300ms ease;
  }

  .multiselect.is-disabled {
    background: #fff;
  }
  .multiselect-option.is-selected {
    background: #c3cdd5;
  }

  .cn-form-select-label {
    cursor: pointer;
    display: block;
    position: absolute;

    pointer-events: none;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.01em;
    left: 16px;
    top: 16px;
    bottom: 16px;
    color: #677a89;
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }

  .multiselect-single-label-text {
    padding: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 92%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.002em;
    color: #1c262f;
  }

  .multiselect-single-label {
    width: 100%;
  }

  .cn-form-select-label-selected {
    top: 8px !important;
    left: 16px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.003em;
  }

  input::placeholder {
    color: #677a89;
  }
}

.cn-form-input-ghost {
}

.validation-error {

  .multiselect {
    border: 1px solid $invalidBorderColor !important;

    & .cn-password__icon path {
      fill: #FF1F26;
    }
  }
}

.cn-form-select-disabled {
  .multiselect {
    background: #ffffff;
    //border: 1px solid #dee4e8; // TODO need to fix selected value
  }

  .cn-form-select-label {
    //color: #c3cdd5; // TODO need to fix selected value
  }

  .caret {
    color: #c3cdd5;
  }
}
</style>
