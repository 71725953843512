export const formatMobile = (phone) => {
    let tt = phone ? phone.split('') : null
    if (tt) {
        tt.splice(1, "", " ")
        tt.splice(5, "", " ")
        tt.splice(9, "", " ")
        tt.splice(12, "", " ")
        return `+${tt.join('')}`
    } else {
        return null
    }
}