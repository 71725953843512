<template>
  <div class="cn-input-search">
    <input
      :value="modelValue"
      placeholder="Search"
      type="search"
      class="cn-input-search__field"
      @input="updateValue($event.target.value)"
    />

    <CIcon name="cilSearch" size="lg" class="cn-input-search__icon" />
  </div>
</template>

<script>
export default {
  name: 'CNInputSearch',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value)
    },
  },
}
</script>

<style scoped lang="scss">
.cn-input-search {
  position: relative;
  height: 56px;

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  input[type='search']:focus-visible {
    outline-offset: 0;
    outline: solid #9fafbc;
  }

  &__field {
    width: 100%;
    height: 100%;
    border: 1px solid #9fafbc;
    border-radius: 8px;
    padding: 16px 56px 16px 16px;
    font-size: 16px;
    line-height: 24px;
  }

  &__icon {
    position: absolute;
    top: 19px;
    right: 20px;
  }
}
</style>
