import { createRouter, createWebHistory } from 'vue-router'

import TheContainer from '@/layouts/DefaultLayout'

import auth from './auth'
import projects from './projects'
import companies from './companies'
import users from './users'
import store from '@/store'
import settings from './settings'
import templates from './templates'
import topNav from './topNav'
import superadmin from './superadmin'
import dashboard from './dashboard'

import Page404 from '@/views/Error/Page404'
import Page403 from '@/views/Error/Page403'
import Expired from '@/views/Error/Expired'
import ToManyAttempts from '@/views/Error/ToManyAttempts'
import PermissionsError from '@/views/Pages/PermissionsError'

import middlewarePipeline from './middleware/middlewarePipeline'

const REDIRECT = { name: 'PermissionsError' }

const routes = [
  ...auth,
  ...superadmin,
  {
    path: '/',
    name: 'Home',
    component: TheContainer,
    redirect: '/projects',
    meta: { auth: true, unverified: false },
    children: [
      {
        path: '/permissions-error',
        name: 'PermissionsError',
        component: PermissionsError,
        meta: { permissions: [] },
      },
      ...dashboard,
      ...projects,
      ...companies,
      ...users,
      ...templates,
      ...topNav,
      ...settings,
    ],
  },
  // Page not found route
  {
    path: '/*',
    name: 'PageNotFound',
    component: Page404,
  },
  // Decline link
  {
    path: '/decline-link',
    name: 'DeclineLink',
    meta: { auth: true },
  },
  // Link Expired
  {
    path: '/expired',
    name: 'LinkExpired',
    component: Expired,
  },
  // To Many Attempts
  {
    path: '/429',
    name: 'ToManyAttempts',
    component: ToManyAttempts,
  },
  //Invalid permission
  {
    path: '/403',
    name: '403',
    component: Page403,
    meta: { auth: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: Page404,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

const isAuthUser = () => store.state.app.user
const isAuthAndVerifiedUser = () =>
  store.state.app.user && store.state.app.user.email_verified
const isAuthAndSuperAdmin = () =>
  store.state.app.user && store.state.app.user.is_superuser

const checkAuthRouteWithGuest = (auth) => auth && !isAuthUser()
const checkNeedToAuthVerifiedRoute = (auth, unverified) =>
  (!auth && isAuthUser()) || (unverified && isAuthAndVerifiedUser())
const checkAuthRouteWithUnverifiedUser = (auth, unverified) =>
  auth && !isAuthAndVerifiedUser() && !unverified
const checkAuthRouteWithSuperAdmin = (auth, superadmin) =>
  auth && !isAuthAndSuperAdmin() && superadmin

const checkPermissions = function (user, route) {
  if (route && route.meta && route.meta.permissions) {
    if (Array.isArray(route.meta.permissions)) {
      return route.meta.checkType === 'any'
        ? user.canAny(route.meta.permissions)
        : user.canAll(route.meta.permissions)
    } else {
      return user.can(route.meta.permissions)
    }
  } else {
    return true
  }
}

export const authorizeMiddleware = (route) => {
  const { auth, unverified, superadmin } = route.meta || {}
  const name = route.name

  if (name === 'PageNotFound') {
    return null
  } else if (checkAuthRouteWithGuest(auth)) {
    return { name: 'Login' }
  } else if (
    checkNeedToAuthVerifiedRoute(auth, unverified) && name !== 'ToManyAttempts'
  ) {
    return { name: 'Home' }
  } else if (checkAuthRouteWithUnverifiedUser(auth, unverified)) {
    return { name: 'EmailVerificationNotification' }
  } else if (checkAuthRouteWithSuperAdmin(auth, superadmin)) {
    return { name: 'Home' }
  } else {
    const savedRedirectionRoutes = ['TaskReviewApprove', 'ProjectScopeEdit', 'TaskSign']

    if (name === 'Login' && route.redirectedFrom) {
      if (route.redirectedFrom.name && savedRedirectionRoutes.includes(route.redirectedFrom.name)) {
        localStorage.setItem('failedRedirection', route.redirectedFrom.fullPath)
      }
    }

    return null
  }
}

const PermissionAndGuardMiddleware = async (to, from, next) => {
  if (store.getters['user'] && !checkPermissions(store.getters['user'], to))
    return next(REDIRECT)

  if (
    store.getters['user'].is_superuser &&
    !store.getters['user'].company &&
    !to.meta.superadmin
  )
    next('/superadmin/company')

  if (to.meta.superadmin && !store.getters['user'].is_superuser)
    await store
      .dispatch('logoutSuperAdmin')
      .finally(async () => await store.dispatch('logout'))

  if (store.state.app.user && to.meta.guard)
    if (typeof to.meta.guard === 'function')
      if (!to.meta.guard(to, store.getters['user'])) return next(REDIRECT)

  // TODO delete operator if
  if (from.name !== 'Login') return next()
}

router.beforeEach(async (to, from, next) => {
  const context = { to, from, next, store }
  if (to.name === 'DeclineLink') {
    await store.dispatch('logout').finally(() => {
      return next({
        path: '/login',
        query: {
          declineLink: to.query.token,
        },
      })
    })
  }
  if (store.getters.updateModal) {
    if (
      !confirm(
        'You have unsaved changes. Are you sure, you want to cancel them?',
      )
    ) {
      return next(false)
    } else {
      store.commit('setUpdateModal', null)
    }
  }
  if (!store.state.app.initialized) {
    return store
      .dispatch('initApp')
      .then(async () => {
        await middlewarePipeline(context)
        PermissionAndGuardMiddleware(to, from, next)
      })
      .catch(() => (to.meta && to.meta.auth ? next('/login') : next()))
  } else {
    await middlewarePipeline(context)
    await PermissionAndGuardMiddleware(to, from, next)
    const result = authorizeMiddleware(to)
    if (result) {
      return next(result)
    }
  }

  next()
})

export default router
