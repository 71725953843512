export const TASK_STATUS_OVERDUE = 'Overdue'
export const TASK_STATUS_PENDING = 'Pending Signatures'
export const TASK_STATUS_IN_REVIEW = 'In review'

export const SCHEDULED_TO_SEND = 'Scheduled to Send'
export const SIGNATURE_REQUIRED = 'Signature Required'
export const CONTRACT_RETURNED_WITH_EDITS = 'Edited'
export const CONTRACT_PENDING_INTERNAL_APPROVAL = 'Pending Approval'
export const CONTRACT_READY_FOR_SIGNATURE = 'Need Signature'
export const CONTRACT_PENDING_EXTERNAL_SIGNATURE = 'Pending Signature (Seller)'
export const CONTRACT_PENDING_INTERNAL_SIGNATURE = 'Pending Signature (You)'
export const CONTRACT_FULLY_EXECUTED = 'Fully Executed'
export const REQUEST_PENDING_SUBMISSION = 'Pending Submission'
export const REQUEST_PENDING_APPROVAL = 'Pending Approval'
export const REQUEST_FULLY_EXECUTED = 'Fully Executed'
export const ACKNOWLEDGE_PENDING_ACKNOWLEDGEMENT = 'Pending Acknowledgement'
export const ACKNOWLEDGE_FULLY_EXECUTED = 'Fully Executed'
export const ACKNOWLEDGE_COMPLETED = 'Completed'
export const REVOKED = 'Revoked'
export const CANCELED = 'Cancelled'

export const OPEN_TASKS = [
  SIGNATURE_REQUIRED,
  CONTRACT_RETURNED_WITH_EDITS,
  CONTRACT_PENDING_INTERNAL_APPROVAL,
  CONTRACT_READY_FOR_SIGNATURE,
  CONTRACT_PENDING_EXTERNAL_SIGNATURE,
  CONTRACT_PENDING_INTERNAL_SIGNATURE,
  REQUEST_PENDING_SUBMISSION,
  REQUEST_PENDING_APPROVAL,
  ACKNOWLEDGE_PENDING_ACKNOWLEDGEMENT,
]

export const CLOSED_TASKS = [
  CANCELED,
  REVOKED,
  CONTRACT_FULLY_EXECUTED,
  REQUEST_FULLY_EXECUTED,
  ACKNOWLEDGE_FULLY_EXECUTED,
  ACKNOWLEDGE_COMPLETED
]

export const ALLOW_CONTRACT_EDIT = [
  SIGNATURE_REQUIRED,
  CONTRACT_RETURNED_WITH_EDITS,
  CONTRACT_PENDING_INTERNAL_APPROVAL,
  CONTRACT_READY_FOR_SIGNATURE,
]

export const ALLOW_TASK_CANCEL = [
  SIGNATURE_REQUIRED,
  CONTRACT_RETURNED_WITH_EDITS,
  CONTRACT_PENDING_INTERNAL_APPROVAL,
  CONTRACT_PENDING_INTERNAL_SIGNATURE,
  CONTRACT_PENDING_EXTERNAL_SIGNATURE,
  CONTRACT_READY_FOR_SIGNATURE,
]

export const DISALLOW_TASK_REVOKE = [
  CANCELED,
  REVOKED,
  CONTRACT_FULLY_EXECUTED,
  REQUEST_FULLY_EXECUTED,
  ACKNOWLEDGE_FULLY_EXECUTED,
]

export const DISALLOW_TASK_CANCEL = [
  REVOKED,
  SCHEDULED_TO_SEND,
  CONTRACT_FULLY_EXECUTED,
  REQUEST_FULLY_EXECUTED,
  ACKNOWLEDGE_FULLY_EXECUTED,
]

export default {
  TASK_STATUS_OVERDUE,
  TASK_STATUS_PENDING,
  TASK_STATUS_IN_REVIEW,

  SCHEDULED_TO_SEND,

  SIGNATURE_REQUIRED,
  CONTRACT_RETURNED_WITH_EDITS,
  CONTRACT_PENDING_INTERNAL_APPROVAL,
  CONTRACT_READY_FOR_SIGNATURE,
  CONTRACT_PENDING_EXTERNAL_SIGNATURE,
  CONTRACT_PENDING_INTERNAL_SIGNATURE,
  CONTRACT_FULLY_EXECUTED,

  REQUEST_PENDING_SUBMISSION,
  REQUEST_FULLY_EXECUTED,
  REQUEST_PENDING_APPROVAL,

  ACKNOWLEDGE_FULLY_EXECUTED,
  ACKNOWLEDGE_PENDING_ACKNOWLEDGEMENT,

  REVOKED,
  CANCELED,
}
