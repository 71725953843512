import permissions from '@/utils/constants/permissions'
import Gate from '@/services/Gate/Gate'
import { permissionError, notifyByPlan, updatePlan } from '@/services/Notify/Toasts'
import store from "./index";

export default {
  state: {
    settingTypes: {
      Account: [
        {
          title: 'Account Details',
          route: { name: 'SettingAccountDetails' },
          guard: async () => {
            const check = await Gate.can('accountDetails', 'setting')
            if (!check) store.commit('setToast', permissionError)
            return check
          },
        },
        {
          title: 'Company Details',
          route: { name: 'SettingCompanyDetails' },
          guard: async () => {
            const check = await Gate.can('companyDetails', 'setting')
            if (!check) store.commit('setToast', permissionError)
            return check
          },
        },
        {
          title: 'Employees',
          route: { name: 'SettingCompanyUsersIndex' },
          permissions: [permissions.EMPLOYEES_VIEW],
        },
        {
          title: 'Permission Groups',
          route: { name: 'SettingPermissionGroups' },
          guard: async () => {
            const check = await Gate.can('view', 'setting')
            if (!check) store.commit('setToast', permissionError)
            return check
          },
        },
      ],
      Templates: [
        {
          title: 'Merge Fields',
          route: { name: 'SettingDocumentTemplate' },
          guard: async () => {
            const check = await Gate.can('templatesView', 'setting')
            if (!check) notifyByPlan()
            return check
          },
        },
        {
          title: 'Email Templates',
          route: { name: 'SettingEmailTemplates' },
          guard: async () => {
            const check = await Gate.can('templatesView', 'setting')
            if (!check) notifyByPlan()
            return check
          },
        },
      ],
      Project: [
        {
          title: 'Contract Approvals',
          route: { name: 'SettingContractApprovals' },
          guard: async () => {
            const check = await Gate.can('projectView', 'setting')
            if (!check) notifyByPlan()
            return check
          },
        },
        {
          title: 'Scope Approvals',
          route: { name: 'SettingScopeApproval' },
          guard: async () => {
            const check = await Gate.can('projectView', 'setting')
            if (!check) notifyByPlan()
            return check
          },
        },
        {
          title: 'Company Types',
          route: { name: 'SettingCompanyTypes' },
          guard: async () => {
            const check = await Gate.can('edit', 'setting')
            if (!check) notifyByPlan()
            return check
          },
        },
      ],
    },
  },
  getters: {
    settingTypes: (state) => state.settingTypes,
  },
  mutations: {},
  actions: {},
}
