const TYPES = {
  KB: Math.pow(1024, 1),
  MB: Math.pow(1024, 2),
  GB: Math.pow(1024, 3),
}

export default (config = { size: 1024, type: 'B' }) =>
  (val) => {
    let fileSize = TYPES[config.type] ? val.size / TYPES[config.type] : val.size
    let typeMsg = TYPES[config.type] ? config.type : 'B'

    return fileSize < config.size
      ? true
      : ['validations.fileSize', { size: config.size + typeMsg }]
  }
