import $permissions from '@/utils/constants/permissions'
import $roles from '@/utils/constants/roles'
import store from '@/store'
import { SUB_CONTRACTOR_TYPE } from '@/utils/constants/plans'

export class Type {
  auth() {
    return store.getters.user
  }

  isSubPlan() {
    return this.auth()?.company?.type === SUB_CONTRACTOR_TYPE
  }

  permissions() {
    return $permissions
  }

  roles() {
    return $roles
  }

  store() {
    return store
  }
}
