import { Type } from './Type'

export class Company extends Type {
  async #checkAndFetchEntity(id = undefined) {
    let entityId = super.store().state.companies?.entity?.id
    if (id && parseInt(entityId) !== parseInt(id)) {
      await super.store().dispatch('fetchCompanyPermissions', id)
    }
  }

  async view(id) {
    if (!id) {
      super.store().commit('setCompanyEntity', {})
      return false
    }

    await this.#checkAndFetchEntity(id)
    return super.store().state.companies.entity.view
  }

  async create() {
    return super.auth().can(super.permissions().COMPANIES_CREATE)
  }

  async deleteList() {
    return super.auth().can(super.permissions().COMPANIES_DELETE)
  }
}
