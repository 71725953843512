import {asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    fetchInvitedUsers: (data) => asJson(axios).get(getVerUri('users/employee/invites'), data),
    sendEmail: (data) => asJson(axios).get(getVerUri('users/invite/search-user'), data),
    addUser: (data) => asJson(axios).get(getVerUri('users/invite/check-user'), data),
    archiveUser: (data) => asJson(axios).post(getVerUri(`users/${data.user_id}/archive`), data),
    getProjects: (data) => asJson(axios).get(getVerUri('users/projects'), {params: data}),
    getTasks: (data) => asJson(axios).get(getVerUri('users/tasks'), {params: data}),
    inviteEmployee: (data) => asJson(axios).post(getVerUri('users/employee/invite'), data),
    exportUsers: (data) => axios({
        method: 'GET',
        url: getVerUri('users/export'),
        responseType: 'blob',
        params: data,
    }),
    resendInvite: (id) => asJson(axios).post(getVerUri(`users/employee/invite/${id}/resend`)),
    cancelInvite: (id) => asJson(axios).put(getVerUri(`users/employee/invite/${id}/cancel`)),
})
