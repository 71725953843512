<template>
  <div class="p-0">
    <SmartTable
      :name="'table-setting-account-company-details-licence'"
      :items="licenses"
      :columns="licensesFields.map((i) => ({ ...i, sorter: false }))"
      infinity
    >
    </SmartTable>
  </div>
</template>
<script>

export default {
  name: 'Licence',
  inject: ['toast'],
  props: {
    licenses: { type: Array, default: () => [] },
  },
  emits: ['updateData', 'createOffice'],
  data() {
    return {
      loading: false,
      licensesFields: [
        { key: 'office', label: 'Office' },
        { key: 'state', label: 'State' },
        { key: 'number', label: 'License Number' },
        { key: 'type', label: 'License  Class/Type' },
        { key: 'exp_date', label: 'Expiration Date', format: value => value ? this.$BtoF({ value }) : null },
        { key: 'note', label: 'Notes' },
      ],
    }
  },
  methods: {
    goWebsite(url) {
      window.open(url[0] !== 'h' ? 'https://' + url : url, '_blank')
    },
  },
}
</script>
