<template>
  <CCol class="col-12 gcFilterBudges">
    <CBadge
      v-for="(badge, key) in limitBadges"
      :key="key"
      color="secondary"
      shape="rounded-pill"
      class="categoryBadge me-3"
    >
      <CCol class="fw-normal mx-1 text d-inline-block" v-html="badge.label + ': ' + badge.value" />
      <c-icon v-if="badge.disabled !== true" name="cilX" class="ms-1 pointer removeBadge" @click="$emit('remove', badge.key)" />
    </CBadge>

    <CBadge v-if="moreBadges" class="moreBadge" color="success" shape="rounded-pill">
      <CIcon name="cilPlus" class="moreIcon" />
      <CCol class="fw-normal mx-1 d-inline-block moreText">
        {{ moreBadges }} more
      </CCol>
    </CBadge>

  </CCol>
</template>

<script>
const LIMIT = 3;

export default {
  name: 'Badges',
  props: {
    badges: { type: Array, default: [] },
  },
  emits: ['remove'],
  computed: {
    limitBadges() {
      return this.badges.slice(0, LIMIT)
    },
    moreBadges() {
      return this.badges.length > LIMIT ? this.badges.length - LIMIT : 0
    },
  },
  methods: {},
}
</script>

<style lang="scss">
.gcFilterBudges {
  .categoryBadge {
    background: #c8c3f2 !important;
  }

  .moreBadge,
  .categoryBadge {
    //padding: 2px 8px;
  }

  .moreIcon, .removeBadge {
    margin-bottom: -1.5px;
  }

  .moreText,
  .moreIcon,
  .removeBadge,
  .text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }

  .moreText,
  .moreIcon {
    color: #ffffff;
  }

  .removeBadge {
    color: #005d9d;
  }

  .text {
    color: #303c54 !important;
  }
}
</style>
