<template>
  <CRow :key="key" :xs="{ cols: 1, gutter: 3 }" class="text-dark">
    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label">Project Name</CFormLabel>
        <CCol class="col-md-8 col-xxl-9">
          <CFormInput v-model="form.project_name" placeholder="Project Name" />
        </CCol>
      </CRow>
    </CCol>

    <CCol v-if="!exceptColumn.includes('company_name')">
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label">Company Name</CFormLabel>
        <CCol class="col-md-8 col-xxl-9">
          <CFormInput v-model="form.company_name" placeholder="Company Name" />
        </CCol>
      </CRow>
    </CCol>

    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label">Owner Name</CFormLabel>
        <CCol class="col-md-8 col-xxl-9">
          <CFormInput v-model="form.owner_name" placeholder="Owner Name" />
        </CCol>
      </CRow>
    </CCol>

    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label">Stage</CFormLabel>
        <CCol class="col-md-8 col-xxl-9">
          <MSelect v-model="form.stage" :options="projectStages" placeholder="Select Stage" style="z-index: 9999999999 !important;" />
        </CCol>
      </CRow>
    </CCol>

    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label">Start Date</CFormLabel>
        <CCol class="col-md-8 col-xxl-9">
          <CRow :xs="{ gutterX: 3 }" class="justify-content-between">
            <CCol>
              <CInputDate v-model="form.start_from" :end="form.start_to ? form.start_to : null" type="clear" clearable placeholder="Date from" />
            </CCol>
            <CCol>
              <CInputDate v-model="form.start_to" :start="form.start_from ? form.start_from : null" type="clear" clearable placeholder="Date to" />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCol>

    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label">End Date</CFormLabel>
        <CCol class="col-md-8 col-xxl-9">
          <CRow :xs="{ gutterX: 3 }" class="justify-content-between">
            <CCol>
              <CInputDate v-model="form.end_from" :end="form.end_to ? form.end_to : null" type="clear" clearable placeholder="Date from" />
            </CCol>
            <CCol>
              <CInputDate v-model="form.end_to" :start="form.end_from ? form.end_from : null" type="clear" clearable placeholder="Date to" />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCol>

    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label">Project Manager</CFormLabel>
        <CCol class="col-md-8 col-xxl-9">
          <CFormInput v-model="form.project_manager" placeholder="Project Manager" />
        </CCol>
      </CRow>
    </CCol>

  </CRow>
</template>

<script>
import mixin from './mixin'
export default {
  name: 'Projects',
  mixins: [mixin],
  data() {
    return {
      labelClass: ['col-md-4 col-lg-3 col-xxl-3 col-form-label'],
      inputClass: ['col-md-8 col-lg-9 col-xxl-9'],
      form: {
        company_name: '',
        project_name: '',
        owner_name: '',
        stage: [],
        start_from: null,
        start_to: null,
        end_from: null,
        end_to: null,
        project_manager: '',
      },
      projectStages: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getStages()
      this.setOrigin()
    })
  },
  methods: {
    getBadgeItems(outForm = undefined) {
      const form = outForm ? outForm : this.form
      return [
        { label: 'Company', value: form.company_name, key: ['company_name'] },
        { label: 'Project', value: form.project_name, key: ['project_name'] },
        { label: 'Owner', value: form.owner_name, key: ['owner_name'] },
        { label: 'Project manager', value: form.project_manager, key: ['project_manager'] },
        { label: 'Stage', value: this.badgeBySelectOptions('stage', 'projectStages', false), key: ['stage'] },
        { label: 'Start Date', value: this.valueDateRange(form.start_from, form.start_to), key: ['start_from', 'start_to'] },
        { label: 'End Date', value: this.valueDateRange(form.end_from, form.end_to), key: ['end_from', 'end_to'] },
      ]
    },
    async getStages() {
      await this.$store.dispatch('getStages').then((data) => {
        this.projectStages = data.map((item) => {
          return { value: item.name, text: item.name }
        })
      })
    },
  },
}
</script>
