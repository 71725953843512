import { RouterView } from 'vue-router'

const QuickAccess = () => import('@/views/TopNav/QuickAccess/index.vue')
const Notifications = () => import('@/views/TopNav/Notifications/index.vue')

export default [
  {
    path: '',
    component: RouterView,
    meta: { auth: true, unverified: false },
    children: [
      {
        path: 'quick-access',
        name: 'QuickAccess',
        component: QuickAccess,
        meta: { auth: true },
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: Notifications,
        meta: { auth: true },
      }
    ]
  },
]
