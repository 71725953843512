import Companies from './components/Companies'
import Projects from './components/Projects'
import Tasks from './components/Tasks'
import Users from './components/Users'
import Documents from './components/Documents'
import KnowledgeBase from './components/KnowledgeBase'
import TableTitle from './components/TableTitle'
import AccordionItem from '@/components/Page/AccordionItem'
import SearchForm from './components/SearchForm'
import CIcon from "@coreui/icons-vue";

export default {
  name: 'SearchResults',
  components: {
    TableTitle,
    Companies,
    Projects,
    Tasks,
    Users,
    Documents,
    KnowledgeBase,
    AccordionItem,
    SearchForm,
  },
  inject: ['toast'],
  data() {
    return {
      showModal: false,
      loading: false,
      search: '',
      searchOrigin: '',
      types: {
        users: { icon: 'cilUsers', title: 'Users' },
        tasks: { icon: 'cilTasks', title: 'Tasks' },
        companies: { icon: 'cilCompanies', title: 'Companies' },
        documents: { icon: 'cilDocument', title: 'Documents' },
        projects: { icon: 'cilProjects', title: 'Projects' },
        knowledge_base: { icon: 'cilSearch', title: 'Knowledge Base' },
      },
      results: [],
    }
  },
  mounted() {},
  methods: {
    handleSearch() {
      let search = this.search.trim()
      if (search.length > 2) {
        this.loading = true
        this.$http.topNav
          .searchResults({ params: { search: search } })
          .then((response) => {
            this.results = response.data.search_results
            this.showModal = true
          })
          .catch((err) => {
            this.toast('error', err.response.data.message)
          })
          .finally(() => (this.loading = false))
      } else {
        for (let key in this.results) {
          this.results[key] = {}
        }
        // this.close()
      }
    },
    close() {
      this.showModal = false
      let el = document.getElementsByClassName('header-sticky')[0]
      if (el) el.style.removeProperty('z-index')
      setTimeout(() => document.getElementById('headerInput').focus(), 100)
    },
    show() {
      let el = document.getElementsByClassName('header-sticky')[0]
      if (el) el.style.zIndex = '1040'
      document.getElementById('modalInput').focus()
    },
  },
}
