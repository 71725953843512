import axios from 'axios';
import {handleError} from './utils/ErrorHandler';

export default class BaseConfigs {
    api;
    baseUrl;
    endpointRoute;

    constructor() {
        this.baseUrl = process.env.VUE_APP_BASE_API_URL;

        this.createAxiosInstance();
        this.setDefaultHeaders();
        this.setInterceptors();
    }

    createAxiosInstance() {
        this.api = axios.create({
            baseURL: this.baseUrl,
            withCredentials: true,
            xsrfCookieName: 'XSRF-TOKEN',
        });
    }

    setDefaultHeaders() {
        this.api.defaults.headers = {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
        };
    }

    asFormData() {
        this.api.defaults.headers = {
            ...this.api.defaults.headers,
            'Content-Type': 'multipart/form-data'
        };

        return this.api;
    }

    asJson() {
        this.api.defaults.headers = {
            ...this.api.defaults.headers,
            'Content-Type': 'application/json'
        };

        return this.api;
    }

    async asDownload(route) {
        return this.asJson().get(route, {responseType: 'blob'});
    }

    setRouteBindings(bindings) {
        Object.keys(bindings)
            .forEach(key => {
                this.endpointRoute = this.endpointRoute.replace(`:${key}`, bindings[key]);
            });

        return this;
    }

    setInterceptors() {
        this.api.interceptors.response.use(undefined, (error) => handleError(error));
    }
}
