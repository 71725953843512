const fireCustomEvents = () => {
  const event = new Event('poppers:close')
  window.dispatchEvent(event)
}
export const CustomEventOnScroll = {
  created(el) {
    el.addEventListener('scroll', fireCustomEvents)
  },
  unmounted(el) {
    el.removeEventListener('scroll', fireCustomEvents)
  },
}
