/**
 * val - string
 * placeholders - Object { ... placeholder: bool }
 */
export default (placeholders) => (val) => {
  let arr = val.match(/{{([a-zA-Z_\.]+)}}/g) || []
  let bag = []
  for (let i = 0; i < arr.length; i++) {
    if (!placeholders[arr[i]]) {
      bag.push(arr[i])
    }
  }

  return !bag.length
    ? true
    : ['validations.shortCodePlaceholder', { msg: bag.join() }]
}
