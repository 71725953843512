<template>
  <CRow :key="key" :xs="{ cols: 1, gutter: 3 }" class="text-dark">
    <CCol v-if="!exceptColumn.includes('company_name')">
      <CRow>
        <CFormLabel class="filterLabelWidth col-12 col-form-label">Company</CFormLabel>
        <CCol class="mx-0">
          <CFormInput v-model="form.company_name" placeholder="Company Name" />
        </CCol>
      </CRow>
    </CCol>

    <CCol>
      <CRow>
        <CFormLabel class="filterLabelWidth col-12 col-form-label">User Name</CFormLabel>
        <CCol class="mx-0">
          <CFormInput v-model="form.user_name" placeholder="User Name" />
        </CCol>
      </CRow>
    </CCol>

    <CCol class="countFilterBlock">
      <CRow :xs="{ cols: 1, gutterY: 3, gutterX: 2 }" :xl="{ cols: 3 }" class="justify-content-between">
        <CCol class="col-lg-auto">
          <CRow>
            <CFormLabel class="filterLabelWidth col-12 col-form-label">Projects:</CFormLabel>
            <CCol>
              <CRow class="align-items-center">
                <CCol class="lInput">
                  <CFormInput v-model="form.projects_min" type="number" placeholder="Min" @blur="checkRange('projects_min', 'projects_max', 'max')" />
                </CCol>
                <CCol class="col-auto p-0 text-center">-</CCol>
                <CCol class="rInput">
                  <CFormInput v-model="form.projects_max" type="number" placeholder="Max" @blur="checkRange('projects_max', 'projects_min', 'min')" />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
        <CCol class="col-lg-auto">
          <CRow>
            <CFormLabel class="filterLabelWidth filterLabelTask col-12 col-form-label">Open Tasks:</CFormLabel>
            <CCol>
              <CRow class="align-items-center">
                <CCol class="lInput">
                  <CFormInput v-model="form.open_tasks_min" type="number" placeholder="Min" @blur="checkRange('open_tasks_min', 'open_tasks_max', 'max')" />
                </CCol>
                <CCol class="col-auto p-0">-</CCol>
                <CCol class="rInput">
                  <CFormInput v-model="form.open_tasks_max" type="number" placeholder="Max" @blur="checkRange('open_tasks_max', 'open_tasks_min', 'min')" />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
        <CCol class="col-lg-auto">
          <CRow>
            <CFormLabel class="filterLabelWidth filterLabelOverdue col-12 col-form-label">Overdue Tasks:</CFormLabel>
            <CCol>
              <CRow class="align-items-center">
                <CCol class="lInput">
                  <CFormInput v-model="form.tasks_overdue_min" type="number" placeholder="Min" @blur="checkRange('tasks_overdue_min', 'tasks_overdue_max', 'max')" />
                </CCol>
                <CCol class="col-auto p-0">-</CCol>
                <CCol class="rInput">
                  <CFormInput v-model="form.tasks_overdue_max" type="number" placeholder="Max" @blur="checkRange('tasks_overdue_max', 'tasks_overdue_min', 'min')" />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCol>
    <CCol>
      <CRow>
        <CFormLabel class="filterLabelWidth col-12 col-form-label">Job</CFormLabel>
        <CCol class="mx-0">
          <MSelect v-model="form.professions" :options="professions || []" placeholder="Job Title" />
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import mixin from './mixin'
import MSelect from '../components/MSelect'
export default {
  name: 'Users',
  components: { MSelect },
  mixins: [mixin],
  data() {
    return {
      professions: [],
      form: {
        company_name: '',
        user_name: '',
        projects_min: '',
        projects_max: '',
        open_tasks_min: '',
        open_tasks_max: '',
        tasks_overdue_min: '',
        tasks_overdue_max: '',
        professions: [],
      },
      types: [
        { value: 'general_contractor', text: 'General Contractor' },
        { value: 'sub_contractor', text: 'Sub Contractor' },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setOrigin()
      this.fetchProfessions()
    })
  },
  methods: {
    async fetchProfessions() {
      await this.$http.setting.fetchProfessions().then((res) => {
        this.professions = res.data.map((el) => {
          return { value: el.id, text: el.title }
        })
      })
    },
    getBadgeItems(outForm = undefined) {
      const form = outForm ? outForm : this.form
      return [
        { label: 'User', value: form.user_name, key: ['user_name'] },
        { label: 'Company', value: form.company_name, key: ['company_name'] },
        { label: 'Projects', value: this.valueRange(form.projects_min, form.projects_max), key: ['projects_min', 'projects_max'] },
        { label: 'Open Tasks', value: this.valueRange(form.open_tasks_min, form.open_tasks_max), key: ['open_tasks_min', 'open_tasks_max'] },
        { label: 'Overdue Tasks', value: this.valueRange(form.tasks_overdue_min, form.tasks_overdue_max), key: ['tasks_overdue_min', 'tasks_overdue_max'] },
        { label: 'Job', value: this.badgeBySelectOptions('professions', 'professions'), key: ['professions'] },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  .filterLabelWidth {
    width: 113px !important;
  }

  .countFilterBlock {
    .filterLabelTask {
      width: 120px !important;
    }
    .filterLabelOverdue {
      width: 121px !important;
    }
    input {
      padding: 0 !important;
      width: 40px;
      text-align: center;
    }

    .rInput {
      padding-left: 5px
    }
    .lInput {
      padding-right: 5px
    }
  }
}
</style>
