<template>
  <div class="stepper">
    <div v-for="(item, key) in steps" :key="key" class="step" :class="{ completed: isCompleted(key), active: isActive(key) }">
      <div class="step-label text-center" :class="{ pointer: hasClick(key) }" @click="handleClick(key, item)">
        <template v-if="item.icon">
          <CIcon v-if="isShowIcon(item, key)" :name="item.icon" style="color: white" />
          <CIcon v-else-if="isCompleted(key)" name="cilSolidCheck" style="color: white" />
        </template>
        <template v-else>
          {{ key+1 }}
        </template>
      </div>
      <div v-if="item.name" class="step-description" :class="{ no_completed: !isCompleted(key), 'step-description-active': item?.route?.name === $route.name }">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CNStep',
  props: {
    steps: { type: Array, default: () => [] },
    currentStep: { type: [Number, String], default: () => 0 },
  },
  emits: ['click'],
  data() {
    return {
      effect: 'in-out-translate-fade',
    }
  },
  computed: {},
  methods: {
    isCompleted(key) {
      return this.currentStep > key
    },
    isActive(key) {
      return this.currentStep === key
    },
    isShowIcon(item, key) {
      return item.icon && !this.isCompleted(key) && !this.isActive(key)
    },
    hasClick(key) {
      return this.isCompleted(key) || this.isActive(key)
    },
    handleClick(key, item) {
      if (this.hasClick(key)) {
        this.$emit('click', key, item)
      }
    },
  },
}
</script>

<style>
:root {
  --bs-gray-200: #9fafbc;
  --bs-gray-500: #999;
  --bs-gray-600: red;
  --bs-gray-800: #677a89;
  --bs-light: #fff;
  --bs-blue: #5ec7eb;

  --fnt: sans-serif;
  --fnt-size: 1rem;
}

.stepper {
  font-family: var(--fnt);
  font-size: var(--fnt-size);
  position: relative;
  counter-reset: step 0;
  width: 100%;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  gap: 0;
  justify-content: space-around;
  align-items: flex-start;
}
.stepper > .step {
  position: relative;
  counter-increment: step;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.stepper > .step::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 4px;
  background: var(--bs-gray-200);
  left: 0;
  top: 10px;
  border-radius: 8px;
}
.stepper > .step > .step-label {
  position: relative;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background: var(--bs-gray-200);
  color: var(--bs-gray-600);
  text-align: center;
  border-radius: 50%;
  margin: 0 auto;
}

.stepper > .step.active::before {
  background: linear-gradient(to right, var(--bs-blue), var(--bs-gray-200));
}
.stepper > .step.active > .step-label {
  background: var(--bs-light);
  border: 4px solid var(--bs-blue);
  font-weight: bold;
  line-height: 1.8rem;
}

.stepper > .step.completed::before {
  background: var(--bs-blue);
}
.stepper > .step.completed > .step-label {
  background: var(--bs-blue);
  color: var(--bs-light);
}
.stepper > .step > .step-description {
  margin-top: 0.5rem;
  overflow-wrap: break-word;
  hyphens: auto;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.stepper > .step > .step-description.no_completed {
  color: #9AA8B5;
}

.stepper > .step > .step-description.step-description-active {
  color: #1c262f !important;
}

.stepper > .step.active > .step-description,
.stepper > .step.completed > .step-description {
  color: var(--bs-gray-800);
}
.stepper > .step.active > .step-description {
  font-weight: bold;
}

</style>
