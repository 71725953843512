<template>
  <CRow :key="key" :xs="{ cols: 1, gutter: 3 }" class="text-dark">
    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label"
          >Task Name</CFormLabel
        >
        <CCol class="col-md-8 col-xxl-9">
          <CFormInput v-model="form.task_name" placeholder="Task Name" />
        </CCol>
      </CRow>
    </CCol>
    <CCol v-if="!exceptColumn.includes('project_name')">
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label"
          >Project Name</CFormLabel
        >
        <CCol class="col-md-8 col-xxl-9">
          <CFormInput v-model="form.project_name" placeholder="Project Name" />
        </CCol>
      </CRow>
    </CCol>
    <CCol v-if="!exceptColumn.includes('company_name')">
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label"
          >Company Name</CFormLabel
        >
        <CCol class="col-md-8 col-xxl-9">
          <CFormInput v-model="form.company_name" placeholder="Company Name" />
        </CCol>
      </CRow>
    </CCol>
    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label"
          >Scope Name</CFormLabel
        >
        <CCol class="col-md-8 col-xxl-9">
          <CFormInput v-model="form.scope_name" placeholder="Scope Name" />
        </CCol>
      </CRow>
    </CCol>
    <CCol v-if="!exceptColumn.includes('required_action')">
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label"
          >Required Action</CFormLabel
        >
        <CCol class="col-md-8 col-xxl-9">
          <MSelect
            v-model="form.required_action"
            :options="actions"
            placeholder="Select Required Action"
          />
        </CCol>
      </CRow>
    </CCol>
    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label"
          >Due Date</CFormLabel
        >
        <CCol class="col-md-8 col-xxl-9">
          <CRow :xs="{ gutterX: 3 }" class="justify-content-between">
            <CCol>
              <CInputDate
                v-model="form.date_from"
                type="clear"
                :end="form.date_to ? form.date_to : null"
                clearable
                placeholder="Date from"
              />
            </CCol>
            <CCol>
              <CInputDate
                v-model="form.date_to"
                type="clear"
                :start="form.date_from ? form.date_from : null"
                clearable
                placeholder="Date to"
              />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCol>

    <CCol v-if="!exceptColumn.includes('status')">
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label"
          >Status</CFormLabel
        >
        <CCol class="col-md-8 col-xxl-9">
          <MSelect
            v-model="form.status"
            :options="taskStatuses"
            placeholder="Select Status"
          />
        </CCol>
      </CRow>
    </CCol>

    <CCol v-if="!exceptColumn.includes('type')">
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label">Type</CFormLabel>
        <CCol class="col-md-8 col-xxl-9">
          <MSelect
            v-model="form.task_type"
            :options="taskTypes || []"
            placeholder="Select Type"
          />
        </CCol>
      </CRow>
    </CCol>
    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label"
          >Priority</CFormLabel
        >
        <CCol class="col-md-8 col-xxl-9">
          <CFormSelect v-model="form.priority">
            <option
              v-for="(priority, key) in taskPriorities"
              :key="key"
              :value="priority.value"
              :selected="form.priority === priority.value"
            >
              {{ priority.label }}
            </option>
          </CFormSelect>
        </CCol>
      </CRow>
    </CCol>
    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label"
          >Internal Assignee</CFormLabel
        >
        <CCol class="col-md-8 col-xxl-9">
          <CFormInput
            v-model="form.internal_assignee"
            placeholder="Internal Assignee"
          />
        </CCol>
      </CRow>
    </CCol>
    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label"
          >External Assignee</CFormLabel
        >
        <CCol class="col-md-8 col-xxl-9">
          <CFormInput
            v-model="form.external_assignee"
            placeholder="External Assignee"
          />
        </CCol>
      </CRow>
    </CCol>

    <CCol v-if="!exceptColumn.includes('is_overdue')">
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label">
          Overdue
        </CFormLabel>
        <CCol class="col-md-8 col-xxl-9">
          <CFormCheck v-model="isOverdue" style="width: 25px; height: 25px" @change="handleChangeIsOverdue($event)" />
        </CCol>
      </CRow>
    </CCol>

  </CRow>
</template>

<script>
import mixin from './mixin'
export default {
  name: 'Tasks',
  mixins: [mixin],
  emits: ['updated'],
  data() {
    return {
      form: {
        task_name: '',
        project_name: '',
        company_name: '',
        scope_name: '',
        priority: '',
        status: [],
        task_type: [],
        required_action: [],
        internal_assignee: '',
        external_assignee: '',
        date_from: null,
        date_to: null,
        is_overdue: 0,
      },
      taskStatuses: [],
      taskTypes: [
        { value: 'Contracts', text: 'Contracts' },
        { value: 'Request documents', text: 'Request documents' },
        { value: 'Acknowledge', text: 'Acknowledge' },
      ],
      taskPriorities: [
        { value: '', label: 'Select Priority' },
        { value: 'Normal', label: 'Normal' },
        { value: 'High', label: 'High' },
      ],
      actions: [
        { value: 'Signature Required', text: 'Signature Required' },
        {
          value: 'Acknowledgement of Receipt Required',
          text: 'Acknowledgement of Receipt Required',
        },
        { value: 'Request', text: 'Request' },
      ],
    }
  },
  computed: {
    isOverdue: {
      get() {
        return this.form.is_overdue ? true : false
      },
      set(newValue) {
        this.form.is_overdue = newValue ? 1 : 0
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$http.common.fetchReferenceBook({ params: { only: 'task_statuses' } })
        .then(({ data }) => {
          this.taskStatuses = Object.values(data.data.task_statuses).map((el) => ({ value: el, text: el }))
          this.$emit('updated')
        })
      this.setOrigin()
    })
  },
  methods: {
    handleChangeIsOverdue(event) {
      // this.form.is_overdue = event.target.checked ? 1 : 0
    },
    getBadgeItems(outForm = undefined) {
      const form = outForm ? outForm : this.form
      return [
        { label: 'Task', value: form.task_name, key: ['task_name'] },
        { label: 'Overdue', value: form.is_overdue ? 'yes' : '', key: ['is_overdue'] },
        { label: 'Project', value: form.project_name, key: ['project_name'] },
        { label: 'Company', value: form.company_name, key: ['company_name'] },
        { label: 'Scope', value: form.scope_name, key: ['scope_name'] },
        { label: 'Priority', value: form.priority, key: ['priority'] },
        {
          label: 'Status',
          value: this.badgeBySelectOptions('status', 'taskStatuses', false),
          key: ['status'],
        },
        {
          label: 'Type',
          value: this.badgeBySelectOptions('task_type', 'taskTypes', false),
          key: ['task_type'],
        },
        {
          label: 'Required Action',
          value: this.badgeBySelectOptions('required_action', 'actions', false),
          key: ['required_action'],
        },
        {
          label: 'Internal',
          value: form.internal_assignee,
          key: ['internal_assignee'],
        },
        {
          label: 'External',
          value: form.external_assignee,
          key: ['external_assignee'],
        },
        {
          label: 'Due Date',
          value: this.valueDateRange(form.date_from, form.date_to),
          key: ['date_from', 'date_to'],
        },
      ]
    },
  },
}
</script>
