import ApiService from '@/services/ApiService'
import Gate from '@/services/Gate/Gate'

export default {
  state: {
    users: [],
    entity: {},
    permissions: {
      view: false,
    },
  },
  getters: {
    users: (state) => state.users,
  },
  mutations: {
    setUsers: (state, data) => (state.users = data),
    setEmployeeEntity: (state, data) => (state.entity = data),
    setEmployeePermissions: async (state) => {
      state.permissions = {
        view: await Gate.can('view', 'employee', state.entity.id),
        edit: await Gate.can('edit', 'employee', state.entity.id),
        create: await Gate.can('create', 'employee'),
        delete: await Gate.can('delete', 'employee', state.entity.id),
      }
    },
  },
  actions: {
    fetchEmployeePermissions({ commit }, id) {
      commit('setEmployeeEntity', {})
      return new Promise((resolve, reject) => {
        ApiService.api.app
          .getModelPermissions('employee', id)
          .then((res) => {
            commit('setEmployeeEntity', res.data.data)
            commit('setEmployeePermissions')
            resolve(res.data.data)
          })
          .catch((err) => {
            commit('setEmployeeEntity', {})
            commit('setEmployeePermissions')
            resolve({})
            reject(err)
          })
      })
    },
  },
}
