import Gate from '@/services/Gate/Gate'
import router from '../index'

export const canView = async ({ to }) => {
  await Gate.can('view', 'scope', to.params.scopeId).then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}

export const canCreate = async ({ to }) => {
  await Gate.can('scopeCreate', 'project', to.params.id).then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}
