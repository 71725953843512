const Dashboard = () => import('@/views/Dashboard')
import { canView } from './middleware/dashboard'

export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: async () => await canView(),
  },
]
