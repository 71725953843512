const Templates = () => import('@/views/Templates');
const TemplatesList = () => import('@/views/Templates/List');
const Template = () => import('@/views/Templates/Template');
const TemplateEditor = () => import('@/views/Templates/Template/TemplatesEdit');
const TemplatePage = () => import('@/views/Templates/Template/TemplatePage.vue');

import { canCreate, canListView, canView } from './middleware/template';

export default [
    {
        name: 'Templates',
        component: Templates,
        redirect: { name: 'TemplatesList' },
        path: 'templates',
        children: [
            {
                path: '',
                name: 'TemplatesList',
                beforeEnter: async () => await canListView(),
                component: TemplatesList,
            },
            {
                path: 'new-requests',
                name: 'NewTemplateRequest',
                beforeEnter: async () => await canCreate(),
                component: TemplatePage,
            },
            {
                name: 'Template',
                path: ':id',
                component: Template,
                beforeEnter: async (to) => await canView({ to: to }),
                children: [
                    {
                        name: 'TemplateRequest',
                        path: 'request',
                        component: TemplatePage,
                    },
                    {
                        name: 'TemplateEditor',
                        path: 'editor',
                        component: TemplateEditor,
                    },
                ],
            },
        ],
    },
];
