export default {
    inject: ['toast', 'downloadToast'],
    methods: {
        handleDownloadPromise(promise, downloadId, downloadDescription) {
            this.downloadToast.open(downloadId, downloadDescription);

            promise
                .then(response => {
                    this.downloadToast.update(downloadId);
                    this.downloadFileFromResponse(response, downloadDescription);
                })
                .catch(() => {
                    this.downloadToast.clear();
                    this.toast('danger', 'File download failed!');
                });
        },
        downloadFileFromResponse(response, fileName) {
            const blob = new Blob([response.data], {
                type: response.headers['content-type'],
            });

            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.target = '_blank';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    }
}
