import { Type } from './Type'

export class Scope extends Type {
  async #checkAndFetchEntity(id = undefined) {
    let entityId = super.store().state.scope?.entity?.id
    if (id && parseInt(entityId) !== parseInt(id)) {
      await super.store().dispatch('fetchScopePermissions', id)
    }
  }

  async view(id) {
    if (!id) {
      super.store().commit('setScopeEntity', {})
      return false
    }

    if (super.isSubPlan() || !super.auth().can(super.permissions().PROJECTS_VIEW)) {
      return false
    }

    await this.#checkAndFetchEntity(id)
    return super.store().state.scope.entity.view
  }

  async edit(id) {
    if (!id) {
      super.store().commit('setScopeEntity', {})
      return false
    }

    if (super.isSubPlan() || !super.auth().can(super.permissions().PROJECTS_EDIT)) {
      return false
    }

    await this.#checkAndFetchEntity(id)

    return super.store().state.scope.entity.update
  }

  async create() {
    if (super.isSubPlan() || !super.auth().can(super.permissions().PROJECTS_EDIT)) {
      return false
    }

    return super.store().state.scope.entity.create
  }

  async delete(id) {
    if (!id || super.isSubPlan() || !super.auth().can(super.permissions().PROJECTS_DELETE)) {
      return false
    }

    await this.#checkAndFetchEntity(id)

    return super.store().state.scope.entity.delete
  }

  async chat(id) {
    if (!id || super.isSubPlan() || !super.auth().can(super.permissions().PROJECTS_EDIT)) {
      return false
    }

    await this.#checkAndFetchEntity(id)

    return super.store().state.scope.entity.chat
  }
}
