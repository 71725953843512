<template>
  <CCol v-if="items.count > 0">
    <SmartTable
      :name="'search-table-projects'"
      :items="items.data"
      :columns="columns.map((i) => ({ ...i, sorter: false }))"
      infinity
    >
    </SmartTable>
    <CButton color="link" class="w-link-all" @click="toAll()">
      See All
    </CButton>
  </CCol>
  <AccordionEmptyTable v-else />
</template>

<script>
import AccordionEmptyTable from './AccordionEmptyTable'
export default {
  name: 'Projects',
  components: { AccordionEmptyTable },
  props: {
    items: {
      type: Object,
      default: () => {
        return { data: [], total: 0 }
      },
    },
    search: { type: String, default: '' },
  },
  data() {
    return {
      columns: [
        {
          key: 'name',
          label: 'Project Name',
          _style: { textAlign: 'center' },
          link: { name: 'Project', params: { id: 'id' } },
        },
        {
          key: 'company_name',
          label: 'Company Name',
          _style: { textAlign: 'center' },
          link: { name: 'Company', params: { id: 'company_id' } },
        },
        {
          key: 'internal_number',
          label: 'Project #',
          _style: { textAlign: 'center' },
        },
        {
          key: 'estimated_start_date',
          label: 'Scheduled start date',
          _style: { textAlign: 'center' },
          format: (value) => (value ? this.$BtoF({ value }) : null),
        },
        {
          key: 'estimated_end_date',
          label: 'Scheduled end date',
          _style: { textAlign: 'center' },
          format: (value) => (value ? this.$BtoF({ value }) : null),
        },
        {
          key: 'stage',
          label: 'Stage',
          _style: { textAlign: 'center' },
        },
        {
          key: 'manager_id',
          label: 'Project Manager',
          _style: { textAlign: 'center' },
          link: {
            name: 'CompanyUser',
            params: { id: 'company_id', user_id: 'manager_id' },
          },
          format: (v, i) =>
            v
              ? `${i.manager_first_name || ''} ${i.manager_last_name || ''}`
              : null,
        },
      ],
    }
  },
  methods: {
    toAll() {
      if (this.$route.href === '/projects-list') {
        window.location.href = '/projects-list?search=' + this.search
      } else {
        this.$router.push({
          name: 'ProjectsList',
          query: { search: this.search },
        })
      }
    },
  },
}
</script>

<style scoped></style>
