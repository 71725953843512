<template>
    <CModal class="confirmDialog" :unmount-on-close="false" :visible="show" :size="config.size"
            :backdrop="config.backdrop" @close="handleCancel">
        <CModalBody class="modal-body">
            <h4 v-html="config.text"/>
            <div v-if="config.info.length > 0" class="mt-3" style="color: #465967" v-html="config.info"></div>
        </CModalBody>

        <CModalFooter>
            <CRow v-if="config.reverse" :xs="{ cols: 1, gutter: 3 }" :sm="{ cols: 2 }" class="w-100">
                <CCol class="text-center text-sm-end">
                    <CButton color="primary" variant="outline" style="width: 200px" @click="handleCancel">
                        {{ config.cancelText }}
                    </CButton>
                </CCol>
                <CCol class="text-center text-sm-start">
                    <CButton :color="config.confirmColor" style="width: 200px" :disabled="loading"
                             @click="handleConfirm">
                        {{ config.confirmText }}
                    </CButton>
                </CCol>
            </CRow>
            <CRow v-else-if="config.reverseState" :xs="{ cols: 1, gutter: 3 }" :sm="{ cols: 2 }" class="w-100">
                <CCol class="text-center text-sm-end">
                    <CButton color="primary" variant="outline" style="width: 200px" @click="handleConfirm">
                        {{ config.cancelText }}
                    </CButton>
                </CCol>
                <CCol class="text-center text-sm-start">
                    <CButton :color="config.confirmColor" style="width: 200px" :disabled="loading"
                             @click="handleCancel">
                        {{ config.confirmText }}
                    </CButton>
                </CCol>
            </CRow>
            <CRow v-else-if="config.onlyConfirmButton" :xs="{ cols: 2 }" class="w-100 justify-content-center">
                <CCol style="text-align: center">
                    <CButton :color="config.confirmColor" style="width: 200px" :disabled="loading"
                             @click="handleConfirm">
                        {{ config.confirmText }}
                    </CButton>
                </CCol>
            </CRow>
            <CRow v-else :xs="{ cols: 1, gutter: 3 }" :sm="{ cols: 2 }" class="w-100">
                <CCol class="text-center text-sm-end">
                    <CButton :color="config.confirmColor" :disabled="loading" style="width: 200px"
                             @click="handleConfirm">
                        {{ config.confirmText }}
                    </CButton>
                </CCol>
                <CCol class="text-center text-sm-start">
                    <CButton color="primary" variant="outline" style="width: 200px" @click="handleCancel">
                        {{ config.cancelText }}
                    </CButton>
                </CCol>
            </CRow>
        </CModalFooter>
    </CModal>
</template>

<script>
export default {
    name: 'ConfirmDialog',
    data() {
        return {
            show: false,
            reject: null,
            resolve: null,
            config: {
                text: this.$t('Are you sure you want to proceed?'),
                info: '',
                cancelText: this.$t('No'),
                confirmText: this.$t('Yes'),
                confirmColor: 'primary',
                size: '',
                reverse: false,
                reverseState: false,
                backdrop: 'static',
                onlyConfirmButton: false,
            },
        }
    },
    methods: {
        confirm(config = {}) {
            this.config = {...this.config, ...config}
            this.show = true

            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        handleCancel() {
            this.show = false
            this.resolve(false)
        },
        handleConfirm(e) {
            e.preventDefault();
            this.show = false
            this.resolve(true)
        },
    },
}
</script>

<style scoped>
.confirmDialog {
    text-align: center !important;

    .modal-body {
        text-align: center !important;
    }
}
</style>
