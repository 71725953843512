<template>
  <div :class="classes">
    <CButton
      v-if="!filename || !filename.length"
      color="light"
      class="mt-3"
      :class="{ 'w-100': fullwidth, 'file-input-redesign': redesign }"
      @click="chooseFiles"
    >
      <input
        id="fileUpload"
        type="file"
        :accept="accept"
        @change="handleChangeFile"
      />

      <CIcon v-if="btnIcon && !redesign" :name="btnIcon"/>
      <span v-if="btnName && !redesign">{{ btnName }}</span>

      <div v-if="redesign" class="file-input-redesign__placeholder">
        Drag & Drop File Here or <span class="file-input-redesign__link">Browse File</span>
      </div>

    </CButton>

    <div class="file-input-redesign__summary" v-else>
      <CIcon v-if="fileIcon" :name="fileIcon" style="margin-right: 5px"/>
      <span style="cursor: pointer">{{ filename.split('/').pop() }}</span>
      <CCloseButton @click="deleteImage()"/>
    </div>

    <CFormText v-if="error" style="color: red">{{ error }}</CFormText>

  </div>
</template>

<script>
export default {
  name: 'UploadFile',
  props: {
    modelValue: {},
    classes: {type: [String, Array, Object]},
    fileIcon: {type: String, default: () => null},
    btnIcon: {type: String, default: () => null},
    btnName: {type: String, default: () => 'Attach'},
    accept: {type: String, default: () => ''},
    maxSize: {type: Number, default: () => 10000000},
    fullwidth: {type: Boolean, default: true},
    withValidation: {type: Boolean, default: true},
    redesign: {type: Boolean, default: false},
  },
  emits: ['update:modelValue', 'change', 'size-error'],
  data() {
    return {
      filename: this.modelValue,
      error: '',
    }
  },
  watch: {
    modelValue(val) {
      this.filename = val instanceof File ? val.name : val
      this.$emit('change', val)
    },
  },
  methods: {
    chooseFiles() {
      if (!this.redesign) {
        document.getElementById('fileUpload').click()
      }
    },
    changeWithValidation(e) {
      const file = e.target.files[0]
      if (file.size > this.maxSize) {
        e.target.value = ''
        this.error =
          'The maximum size of the uploaded file should be ' +
          this.maxSize / 1000000 +
          ' MB'
        this.$emit('size-error', this.error)
        setTimeout(() => {
          this.error = ''
          this.$emit('size-error', this.error)
        }, 3000)
        this.filename = null
      } else {
        this.error = ''
        this.filename = file.name
        this.$emit('size-error', this.error)
        this.$emit('update:modelValue', file)
        this.$emit('change', file)

        e.target.value = ''
      }
    },
    handleChangeFile(e) {
      if (this.withValidation) {
        this.changeWithValidation(e)
      } else {
        this.$emit('update:modelValue', e.target.files[0])
      }
    },
    deleteImage() {
      this.filename = null
      this.$emit('update:modelValue', null)
    },
  },
}
</script>

<style lang="scss" scoped>
.file-input-redesign {
  background: #ABE7FA40;
  border: 1px dashed #00437A !important;
  height: 56px;
  display: flex;
  position: relative;
  align-items: center;

  & input {
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
  }

  &:hover {
    background: rgba(171, 231, 250, 0.77) !important;
  }

  &__summary {
    display: flex;
    align-items: center;
    margin-top: 16px;

    & span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__placeholder {
    color: #677A89;
    font-size: 16px;
  }

  &__link {
    color: #0068AD;
  }
}
input[type='file'] {
  opacity: 0;
  position: absolute;
  top: -2000px;
  left: 0;
}
</style>
