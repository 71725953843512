export function templateAdapter(template) {
    return {
        id: template.template_id,
        name: template.template_name,
        is_favorite: template.is_favorite,
        is_draft: template.is_draft,
        is_main: template.is_main,
        labels: template.labels?.map((label) => ({
            value: label.id,
            label: label.name,
        })),
        updated_at: template.updated_at,
        modified_by: template.modified_by?.full_name,
        item_type: 'template',
        type: template.type,
        selectable_id: `${template.template_id},template`,
        has_attachment: template.has_attachment,
        standard_template_id: template.standard_template_id,
    }
}

export function templatesListAdapter(templates) {
    return templates.map((template) => templateAdapter(template))
}

export function packageAdapter(packageData) {

    return {
        id: packageData.folder_id,
        name: packageData.folder_name,
        is_favorite: packageData.is_favorite,
        labels: packageData.labels,
        updated_at: packageData?.updated_at,
        modified_by: packageData.modified_by?.full_name,
        item_type: 'package',
        primary_contracts: templatesListAdapter(packageData.primary_contracts),
        secondary_contracts: templatesListAdapter(packageData.secondary_contracts),
        selectable_id: `${packageData.folder_id},package`,
    }
}

export function packagesListAdapter(packages) {
    return packages.map((packageData) => packageAdapter(packageData))
}

export function scenarioAdapter(scenario) {
    return {
        id: scenario.id,
        name: scenario.name,
        packages: packagesListAdapter(scenario.folders),
        templates: templatesListAdapter(scenario.templates),
        modified_by: scenario.modified_by,
        item_type: 'scenario',
        selectable_id: `${scenario.id},scenario`,
    }
}

export function scenariosListAdapter(scenarios) {
    return scenarios.map((scenario) => scenarioAdapter(scenario))
}

export function packageTableAdapter(packageData) {
    const primaryContracts = templatesListAdapter(
        packageData.primary_contracts,
    )?.map((item) => ({
        ...item,
        selectable_id: `${item.id}.1,template`,
        file_type: 'primary'
    }))
    const secondaryContracts = templatesListAdapter(
        packageData.secondary_contracts,
    )?.map((item) => ({
        ...item,
        selectable_id: `${item.id}.2,template`,
        file_type: 'secondary'
    }))

    return [
        {
            title: 'Primary contract package',
            items: primaryContracts,
            bind: {'data-flow': 'primary'},
            sectionId: 1,
            additionalAction: {
                buttonText: 'Add primary package templates',
                buttonEvent: 'uploadPrimaryContracts',
            },
        },
        {
            title: 'Flow Down',
            items: secondaryContracts,
            bind: {'data-flow': 'secondary'},
            sectionId: 2,
            additionalAction: {
                buttonText: 'Add flow down templates',
                buttonEvent: 'uploadSecondaryContracts',
            },
        },
    ]
}

export function scenarioTableAdapter(scenario) {
    const _scenario = scenarioAdapter(scenario)
    return [
        ..._scenario.packages.map((el) => ({...el, scenario_id: scenario.id})),
        ..._scenario.templates,
    ]
}

export function filterTableAdapter(filterData) {
    return filterData.map((element) => ({
        id: element.id,
        name: element.name,
        user_id: element.user_id,
        user_name: element.user_name,
        modified_by: element.user_name,
        item_type: element.type,
        type: element?.template_type,
        created_at: element.created_at,
        updated_at: element.updated_at,
        selectable_id: `${element.id},${element.type}`,

        is_favorite: element?.is_favorite,
        is_draft: element?.is_draft,
        labels: element?.labels,
        scenario_id: +element?.scenario_id,
        has_attachment: element?.has_attachment,
    }))
}

export function viewDetailsAdapter(template) {
    const templateData = templateAdapter(template.template_details)

    return {
        ...templateData,
        size: `${template?.template_details.size} KB`,
        created_by: template?.template_details.created_by?.full_name,
        created_at: template?.template_details.created_at,
        usage: template.usage?.map((scenario) => {
            return {
                id: scenario.id,
                name: scenario.name,
                packages: scenario.folders?.map((packageData) => {
                    return {
                        id: packageData.folder_id,
                        name: packageData.folder_name,
                    }
                }),
            }
        }),
    }
}
