export const getFileUrl = (file, callback) => {
  const reader = new FileReader()
  reader.onload = () => {
    callback(reader.result)
  }
  reader.onerror = () => {
    callback(undefined, reader.error)
  }
  reader.readAsDataURL(file)
}

export const previewFileInNewTab = (response) => {
  let blob = new Blob([response.data], {
    type: response.headers['content-type'],
  })

  let link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.target = '_blank'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadFile = (response, fileName, elId = undefined) => {
  let blob = new Blob([response.data], {
    type: response.headers['content-type'],
  })

  let headerLine = response.headers['content-disposition']

  if (headerLine) {
    let startFileNameIndex = headerLine.indexOf('"') + 1
    let endFileNameIndex = headerLine.lastIndexOf('"')
    fileName = headerLine.substring(startFileNameIndex, endFileNameIndex)
  }

  let link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  link.target = '_blank'

  const el = elId ? document.getElementById(elId) : document.body
  el.appendChild(link)
  link.click()
  el.removeChild(link)
}

export const downloadClientFile = (file) => {
  const link = document.createElement('a')
  link.href = URL.createObjectURL(file)
  link.download = file.name
  link.click()
  URL.revokeObjectURL(link.href)
}
