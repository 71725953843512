import ApiService from '@/services/ApiService'
import { parseError } from '@/utils/api'
// import { getVerUri } from '@/services/ApiService'
// const axios = ApiService._axios
import Gate from '@/services/Gate/Gate'
const DOCX = 'docx',
  XLSX = 'xlsx',
  XLS = 'xls',
  PDF = 'pdf',
  JPEG = 'jpeg',
  JPG = 'jpg',
  GIF = 'gif',
  EMP = 'emp',
  SVG = 'svg',
  TXT = 'txt'

export const TEMPLATE_UPLOAD_SUPPORTED_EXTENSIONS = {
  DOCX,
  XLSX,
  XLS,
  PDF,
  JPEG,
  JPG,
  GIF,
  EMP,
  SVG,
  TXT,
}

const MAX_FILE_SIZE_BYTE = 10000000 //10MB

export const DEFAULT_REQUEST_DOCUMENT_SETTINGS = {
  name: '',
  task_name: '',
  task_action: 'Request',
  type: 'Request documents',
  is_published: false,
  is_draft: true,
  description: '',
  due_date_days: 0,
  checkDays: true,
  reminders_enabled: false,
  reminders_every: 3,
  reminders_period: 7,
  reminders_days: [],
  reminders_until: '',
  recurrence_enabled: false,
  recurrence_every: 3,
  recurrence_period: 7,
  recurrence_type: 'project_ends',
  recurrence_until_repeats: 1,
  recurrence_until_date: null,
  recurrence_days: [],
}

/**
 *
 * @return {boolean}
 */

export const validateFileSize = (file) => {
  return file && file?.size <= MAX_FILE_SIZE_BYTE
}

const parseAndShowTemplateCreationErrors = function (error) {
  const { status, validationMessages } = parseError(error)
  if (status === 422) {
    const messages = Object.values(validationMessages)
    if (messages.length) {
      this.toast('error', messages.join(', '))
    } else {
      this.toast('error', error.response.data.message)
    }
  } else {
    this.toast('error', error.response.data.message)
  }
}

export const useTemplateCreationErrorsParser = () => {
  return { parseAndShowTemplateCreationErrors }
}

/**
 *
 * @return {boolean}
 */

export const validateExtension = (file) => {
  const extension = file.name.split('.').pop()
  return Object.values(TEMPLATE_UPLOAD_SUPPORTED_EXTENSIONS).includes(extension)
}

/**
 *
 * @return {boolean}
 */

export const templateFileIsConvertable = (file) => {
  const extension = file.name.split('.').pop()
  return [DOCX].includes(extension)
}

export default {
  namespaced: true,
  state: {
    templates: [],
    editableTemplate: null,
    editableTemplateEditor: null,
    tableKey: 0,
    loadingTemplates: false,
    paginationMeta: { per_page: 10 },
    indexQueryOptions: {},
    /* Editor viewer scale parameter percents */
    editorViewScalePercents: 100,
    entity: {},
    permissions: {
      view: false,
      list: false,
      create: false,
      edit: false,
      delete: false,
    },
  },
  getters: {
    indexParams(state) {
      return {
        ...state.indexQueryOptions,
        ...{
          page: state.paginationMeta.current_page,
          per_page: state.paginationMeta.per_page,
        },
      }
    },
    editableTemplate(state) {
      return state.editableTemplate ? state.editableTemplate : {}
    },
    isSignatureRequired(state) {
      return state.editableTemplate.task_action === 'Signature Required'
    },
    currentEditableTemplateHasSignaturesForBothSide(state) {
      const signatures =
        state.editableTemplateEditor?.commands.getNodesByType('signature')

      if (!signatures) return false

      return (
        signatures.length >= 2 &&
        signatures
          .map((s) => s.actor)
          .filter((value, index, self) => {
            return self.indexOf(value) === index
          }).length === 2
      )
    },
  },
  mutations: {
    SET_STATE(state, { key, value }) {
      if (typeof value === 'object') {
        state[key] = Object.assign(state[key], value)
      } else {
        state[key] = value
      }
    },
    SET_EDITABLE_TEMPLATE_EDITOR(state, editor) {
      state.editableTemplateEditor = editor
    },
    SET_EDITABLE_TEMPLATE_ATTRIBUTE(state, { key, value }) {
      state.editableTemplate[key] = value
    },
    SET_EDITABLE_TEMPLATE(state, value) {
      state.editableTemplate = value
    },
    SET_TEMPLATES: (state, value) => {
      state.templates = value
    },
    SET_LOADING_STATUS(state, value) {
      state.loadingTemplates = !!value
    },
    SET_TEMPLATES_PAGINATION_META(state, value) {
      state.paginationMeta = value
    },
    INCREASE_RENDER_KEY(state) {
      state.tableKey++
    },
    setTemplateEntity: (state, data) => (state.entity = data),
    setTemplatePermissions: async (state) => {
      state.permissions = {
        view: await Gate.can('view', 'template', state.entity.id),
        list: await Gate.can('list', 'template'),
        edit: await Gate.can('edit', 'template'),
        delete: await Gate.can('delete', 'template'),
        create: await Gate.can('create', 'template'),
      }
    },
  },
  actions: {
    fetchTemplatePermissions({ commit }, id) {
      commit('setTemplateEntity', {})
      return new Promise((resolve, reject) => {
        ApiService.api.app
          .getModelPermissions('template', id)
          .then((res) => {
            commit('setTemplateEntity', res.data.data)
            commit('setTemplatePermissions')
            resolve(res.data.data)
          })
          .catch((err) => {
            commit('setProjectEntity', {})
            commit('setTemplatePermissions')
            resolve({})
            reject(err)
          })
      })
    },
    async getTemplates({ commit, getters }, params) {
      commit('SET_LOADING_STATUS', true)
      const res = await ApiService.api.template.getTemplates({
        ...getters.indexParams,
        ...params,
      })
      commit('SET_TEMPLATES', res.data.data)
      commit('SET_TEMPLATES_PAGINATION_META', res.data.meta)
      commit('SET_LOADING_STATUS', false)
      return res
    },
    async getTemplate({ commit = commit }, { template_id }) {
      return await ApiService.api.template.getTemplate({ template_id })
    },
    async storeTemplate({ commit = commit }, payload) {
      return await ApiService.api.template.storeTemplate(payload)
    },
    async archiveTemplate({ commit = commit }, templateId) {
      return await ApiService.api.template
        .archiveTemplate(templateId)
        .catch((error) => error)
    },
  },
}
