export const GENERAL_CONTRACTOR_TYPE = 'general_contractor'
export const SUB_CONTRACTOR_TYPE = 'sub_contractor'

export const types = [
	GENERAL_CONTRACTOR_TYPE,
	SUB_CONTRACTOR_TYPE,
]

export const labels = {
	[GENERAL_CONTRACTOR_TYPE]: 'General Contractor',
	[SUB_CONTRACTOR_TYPE]: 'Sub-Contractor',
}
