export const cilArrowUpload = `<svg viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_833_47249)">
<path d="M77.5 72.5H2.5V77.5H77.5V72.5Z" fill="#677A89"/>
<path d="M61.7676 46.7677L58.2323 43.2323L43.75 57.7145V2.5H38.75V57.7145L24.2676 43.2323L20.7323 46.7677L41.25 67.2855L61.7676 46.7677Z" fill="#677A89"/>
</g>
<defs>
<clipPath id="clip0_833_47249">
<rect width="80" height="80" fill="white"/>
</clipPath>
</defs>
</svg>
`
