<template>
  <CButton color="primary" class="SmartTableButton">
    <slot />
  </CButton>
</template>

<style lang="scss">
    .SmartTableButton {
        box-shadow: none!important;
        padding: 5px 8px!important;
        white-space: nowrap;
        font-size: 14px;
        color: #fff;

        & svg {
            margin-right: 10px;
        }
    }
</style>
