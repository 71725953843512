<template>
  <div :class="{ 'c-dark-theme': $store.state.darkMode }" class="c-app">
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100 justify-content-center">
        <CCol md="6">
          <div class="w-100">
            <div class="clearfix">
              <h1 class="float-left display-3 mr-4">403</h1>
              <h4 class="pt-3">Oops! </h4>
              <p class="text-muted">You do not have the required permission</p>
              <p><router-link tag="button" to="/" class="btn btn-primary mt-3">Go to the Home page</router-link> </p>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
  export default {
    name: 'Page403'
  }
</script>
