<template>
  <CRow
    :key="key"
    :xs="{ cols: 1, gutter: 3 }"
    class="text-dark templates-filter"
  >
    <CCol>
      <CRow class="templates-filter__item">
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label">
          Template Type
        </CFormLabel>

        <CCol class="col-md-8 col-xxl-9">
          <MSelect
            v-model="form.type"
            :options="types"
            placeholder="Select type"
          />
        </CCol>
      </CRow>
    </CCol>

    <CCol>
      <CRow class="templates-filter__item">
        <CFormLabel class="col-md-4 col-xxl-3 col-form-label">
          Date Modified
        </CFormLabel>

        <CCol class="col-md-8 col-xxl-9">
          <CRow :xs="{ gutterX: 3 }" class="justify-content-between">
            <CCol>
              <CInputDate
                v-model="form.date_modified_from"
                :end="form.date_modified_to ? form.date_modified_to : null"
                type="clear"
                clearable
                placeholder="Date from"
              />
            </CCol>

            <CCol>
              <CInputDate
                v-model="form.date_modified_to"
                :start="
                  form.date_modified_from ? form.date_modified_from : null
                "
                type="clear"
                clearable
                placeholder="Date to"
              />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import mixin from './mixin'
export default {
  name: 'Templates',
  mixins: [mixin],
  data() {
    return {
      form: {
        type: [],
        date_modified_from: null,
        date_modified_to: null,
      },
      types: [
        {
          text: 'Contract',
          value: 'Contracts',
        },
        {
          text: 'Acknowledgement',
          value: 'Acknowledge',
        },
        {
          text: 'Request',
          value: 'Request documents',
        },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setOrigin()
    })
  },
  methods: {
    getBadgeItems(outForm = undefined) {
      const form = outForm ? outForm : this.form
      return [
        {
          label: 'Template Type',
          value: this.badgeBySelectOptions('type', 'types', false),
          key: ['type'],
        },
        {
          label: 'Date Modified',
          value: this.valueDateRange(
            form.date_modified_from,
            form.date_modified_to,
          ),
          key: ['date_modified_from', 'date_modified_to'],
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.templates-filter {
}
</style>
