<template>
  <div class="cn-toast">
    <CToaster class="" :placement="placement">
      <CToast
        v-for="(toast, index) in toasts"
        :key="index"
        :autohide="autohide"
        :delay="delay"
        :visible="visible"
        :class="['cn-toast__border-' + toast.type || '']"
        @close="handleClose(index)"
      >
        <div class="cn-toast__inner">
          <div class="cn-toast__icon">
            <CIcon
              v-if="icons[toast.type]"
              v-bind="icons[toast.type].svgStyles"
              :name="icons[toast.type].name"
              :class="['cn-toast__icon-color-' + toast.type || '']"
            />
          </div>
          <div class="cn-toast__content">
            <h5 v-if="toast.title">{{ toast.title }}</h5>
            <span class="cn-toast__text">{{ toast.content }}</span>
            <CRow
              v-if="Array.isArray(toast.buttons) && toast.buttons.length"
              class="cn-toast__buttons">
              <CCol>
                <CButton
                  v-for="(button, index) in toast.buttons"
                  :key="button.text + index"
                  :color="button.color"
                  :variant="button.variant"
                  @click="handleBtnClick(button, index)"
                >
                  {{ button.text }}
                </CButton>
              </CCol>
            </CRow>
          </div>
          <div class="cn-toast__close">
            <CToastClose  />
          </div>
        </div>
      </CToast>
    </CToaster>
  </div>
</template>

<script>

/**
 *
 * {
 *     type: 'info',
 *     title: 'Update subscription plan+++',
 *     content: 'You do not have the required on plan to continue.',
 *     buttons: [
 *       {},
 *     ],
 *   }
 */
export default {
  name: 'CNToast',
  props: {
    toasts: { Array, default: [] },
    visible: { Boolean, default: true },
    autohide: { Boolean, default: true },
    delay: { Number, default: 5000 },
    placement: { String, default: () => 'top-end' },
  },
  emits: ['close'],
  data() {
    return {
      icons: {
        info: {
          name: 'cilInfoCircle',
          svgStyles: { 'viewBox': '0 0 70 70' }
        },
        warning: {
          name: 'cilWarning',
          svgStyles: {}
        },
      },
    }
  },
  methods: {
    handleClose(index) {
      this.$emit('close', index)
    },
    handleBtnClick(btn, index) {
      if (btn.callback && typeof btn.callback === 'function') {
        btn.callback()
        this.handleClose(index)
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.cn-toast {

  &__buttons {
    margin-top: 18px;
  }

  &__close {
    display: flex;

    & .btn {
      height: 14px;
    }
  }

  & .top-0 {
    top: 63px !important;
  }

  &__text {
    font-size: 14px;
    color: #1C262F;
    width: 100%;
  }

  &__icon {
    & .icon {
      height: 18px;
      width: 18px;
    }
  }

  &__inner {
    display: flex;
    padding: 16px 9px 16px 16px;
  }

  &__content {
    flex: auto;
    padding-left: 8px;
    inline-size: 90%;
    overflow-wrap: break-word;

    & h5, p {
      margin: 0;
    }

    & h5 {
      font-size: 18px;
      margin-bottom: 15px;
    }
  }

  .btn {
    padding: 1px 9px !important;
  }

  &__border-info {
    border-left: 4px solid #0081c2;
  }

  &__border-warning {
    border-left: 4px solid #f56a00;
  }

  &__icon-color-info {
    color: #0081c2;
  }
  &__icon-color-warning {
    color: #f56a00;
  }

  .toast-container {
    width: 524px;
  }

  .toast {
    width: 100%;
    background: white;
  }

  .toast-body {
    margin-top: 8px;
  }

  .toast-header {
    border-bottom: unset;
  }

  .toast-header span {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.002em;
    color: #1c262f;
  }

  .toast-body {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.003em;
    color: #1c262f;
  }
}
</style>
