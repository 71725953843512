// General Contractor
export const GENERAL_OWNER = 'general_owner'
export const GENERAL_ADMIN = 'general_admin'
export const GENERAL_MANAGER = 'general_manager'
export const GENERAL_COLLABORATOR = 'general_collaborator'
export const GENERAL_VIEW_ONLY = 'general_view_only'

// Sub-contractor
export const SUB_OWNER = 'sub_owner'
export const SUB_ADMIN = 'sub_admin'
export const SUB_MANAGER = 'sub_manager'
export const SUB_COLLABORATOR = 'sub_collaborator'

export default {
  // General Contractor
  GENERAL_OWNER,
  GENERAL_ADMIN,
  GENERAL_MANAGER,
  GENERAL_COLLABORATOR,
  GENERAL_VIEW_ONLY,

  // Sub-contractor
  SUB_OWNER,
  SUB_ADMIN,
  SUB_MANAGER,
  SUB_COLLABORATOR,
}
