const Login = () => import('@/views/Auth/Login')
const RecoveryPassword = () => import('@/views/Auth/RecoveryPassword')
const SignUp = () => import('@/views/Auth/SignUp')
const ContactUs = () => import('@/views/Auth/ContactUs.vue')
import {canLoginView} from './middleware/auth'

export default [
	{
		path: '/login',
		name: 'Login',
    beforeEnter: async () => await canLoginView(),
		component: Login,
	},
	{
		path: '/recovery-password',
		name: 'RecoveryPassword',
		component: RecoveryPassword,
	},
	{
		path: '/sign-up',
		name: 'SignUp',
		component: SignUp,
	},
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs,
  },
]
