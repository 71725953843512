<template>
  <div class="p-0">
    <SmartTable
      :name="'table-setting-account-company-details-certification'"
      :items="certifications"
      :columns="certificationsFields.map((i) => ({ ...i, sorter: false }))"
      infinity
    >
    </SmartTable>
  </div>
</template>
<script>

export default {
  name: 'Certification',
  inject: ['toast'],
  props: {
    certifications: { type: Array, default: () => [] },
  },
  emits: ['updateData'],
  data() {
    return {
      loading: false,
      certificationsFields: [
        { key: 'name', label: 'Certifications' },
        { key: 'type', label: 'Type' },
        { key: 'number', label: 'Cert. #' },
        { key: 'exp_date', label: 'Expirations', format: value => this.$BtoF({ value }) },
        { key: 'agency', label: 'Agency' },
        { key: 'note', label: 'Note' },
      ],
    }
  },
}
</script>
