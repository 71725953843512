import './style.css'

export const Loading = {
  updated(el, binding) {
    if (binding.value === true) {
      let iDiv = item(binding.arg)

      if (binding.arg === 'fullscreen') {
        document.getElementById('app').appendChild(iDiv)
      } else {
        el.classList.add('loadingDirectiveRelative')
        el.appendChild(iDiv)
      }
    } else {
      let loading = document.getElementById('loadingDirective')
      if (loading) {
        loading.remove()
        el.classList.remove('loadingDirectiveRelative')
      }
    }

    function item(fullscreen = undefined) {
      let position =
        fullscreen === 'fullscreen'
          ? 'loadingDirectiveFixed'
          : 'loadingDirectiveAbsolute'
      let iDiv = document.createElement('div')
      iDiv.id = 'loadingDirective'
      iDiv.classList.add('loadingDirective')
      iDiv.classList.add(position)
      // '<div class="spinner-border text-primary ' + position + '"></div>'
      iDiv.innerHTML =
        '<div class="spinner-border text-primary ' + position + '"></div>'
      return iDiv
    }
  },
}
