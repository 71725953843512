import { Type } from './Type'

export class Project extends Type {
  async #checkAndFetchEntity(id = undefined) {
    let entityId = super.store().state.projects?.entity?.id
    if (id && parseInt(entityId) !== parseInt(id)) {
      await super.store().dispatch('fetchProjectPermissions', id)
    }
  }

  async tabAction(id) {
    if (!id || super.isSubPlan() || super.store().state.projects.entity.isExternal) {
      return false
    }
    await this.#checkAndFetchEntity(id)
    return super.store().state.projects.entity.update
  }

  async view(id) {
    if (!id) {
      super.store().commit('setProjectEntity', {})
      return false
    }

    if (super.isSubPlan() || !super.auth().can(super.permissions().PROJECTS_VIEW)) {
      return false
    }

    await this.#checkAndFetchEntity(id)
    return super.store().state.projects.entity.view
  }

  async edit(id) {
    if (!id) {
      super.store().commit('setProjectEntity', {})
      return false
    }

    if (super.isSubPlan() || !super.auth().can(super.permissions().PROJECTS_EDIT)) {
      return false
    }

    await this.#checkAndFetchEntity(id)

    return super.store().state.projects.entity.update
  }

  async viewTabs(id) {
    if (!id) {
      super.store().commit('setProjectEntity', {})
      return false
    }

    if (!super.auth().can(super.permissions().PROJECTS_VIEW)) {
      return false
    }

    await this.#checkAndFetchEntity(id)

    return super.store().state.projects.entity.view || super.store().state.projects.entity.is_external
  }

  async create() {
    if (super.isSubPlan() || !super.auth().can(super.permissions().PROJECTS_CREATE)) {
      return false
    }

    return true
  }

  async delete(id) {
    if (!id) {
      super.store().commit('setProjectEntity', {})
      return false
    }

    if (super.isSubPlan() || !super.auth().can(super.permissions().PROJECTS_DELETE)) {
      return false
    }

    await this.#checkAndFetchEntity(id)

    return super.store().state.projects.entity.delete
  }

  async editOrCreate(id = undefined) {
    if (!id) {
      super.store().commit('setProjectEntity', {})
      return await this.create()
    }

    return await this.edit(id)
  }

  async scopeCreate(id) {
    await this.#checkAndFetchEntity(id)

    return super.store().state.projects.entity.scopeCreate
  }

  async taskCreate(id) {
    await this.#checkAndFetchEntity(id)

    return super.store().state.projects.entity.taskCreate
  }
}
