export default {
  state: {
    contract: {},
  },
  mutations: {
    RESET_APPROVE_AND_REJECT(state, internalId) {
      state.contract.contract_approvals
        .filter((el) => el.user_id !== internalId).forEach((item, index) => {
          state.contract.contract_approvals[index]['approved'] = false
          state.contract.contract_approvals[index]['rejected'] = false
        })
    },
    GET_CONTRACT(state, value) {
      state.contract = value
    },
    CHANGE_USER_APPROVE(state, value) {
      const index = state.contract
        .contract_approvals.findIndex((t) => (t.id === value.id))

      if (index >= 0 && state.contract.contract_approvals[index]) {
        state.contract.contract_approvals[index] = value
      }
      // state.contract.contract_approvals.splice(index, 1)
      // state.contract.contract_approvals.push(value)
    },
  },
}
