<template>
<div class="SuperAdminCompanyDetails">
  <CRow class="justify-content-between mb-3 mt-2">
    <CCol>
      <h1>Company Details</h1>
    </CCol>
    <CCol class="col-auto">
      <CButton color="primary" @click="login">
        Log in as an admin
      </CButton>
    </CCol>
  </CRow>

  <CCard>
    <CCardBody>
      <CContainer v-loading="loading" style="max-width: 100%">
        <CAccordion :active-item-key="1">
          <AccordionItem :mobile-label="'Company Details'" :item-key="1">
            <CRow class="infoBlock borderBottom">
              <CCol class="pb-5" lg="12" xl="7">
                <CRow>
                  <CCol lg="5" xl="4" class="text-center">
                    <Avatar
                      :size="170"
                      class="companyLogo"
                      :rounded="false"
                      :src="company.logo"
                      :display-name="[company.name]" />
<!--                    <CImage v-if="company.logo" :src="company.logo" class="companyLogo" />-->
<!--                    <img v-else src="@/assets/images/company.png" class="companyLogo" />-->

                    <CCol class="companyActivity">
                      Last Activity: {{ last_activity_date && last_activity_date.date ? $BtoF({value: last_activity_date.date }) : 'N/A' }}
                    </CCol>
                  </CCol>
                  <CCol lg="7" xl="8" class="px-3">
                    <CRow :xs="{ cols: 2, gutter: 3 }" class="justify-content-between">
                      <CCol class="infoLabel"> Company Name </CCol>
                      <CCol class="infoText"> {{ company.name || 'N/A' }} </CCol>
                      <CCol class="infoLabel"> DBA </CCol>
                      <CCol class="infoText"> {{ company.dba || 'N/A' }} </CCol>
                      <CCol class="infoLabel"> Website </CCol>
                      <CCol class="infoText">
                        <router-link v-if="company.website" to="" @click="toCompanyWebSite(company.website)">
                          {{ company.website }}
                        </router-link>
                        <span v-else>N/A</span>
                      </CCol>
                      <CCol class="infoLabel"> Company ID </CCol>
                      <CCol class="infoText"> {{ company.id || 'N/A' }} </CCol>
                      <CCol class="infoLabel"> Tax ID / EIN: </CCol>
                      <CCol class="infoText"> {{ company.tid || 'N/A' }} </CCol>
<!--                      <CCol class="infoLabel"> Primary Contact </CCol>-->
<!--                      <CCol class="infoText">-->
<!--                        <router-link v-if="company.primary_contact_name" to="#">-->
<!--                          {{ company.primary_contact_name }}-->
<!--                        </router-link>-->
<!--                        <span v-else>N/A</span>-->
<!--                      </CCol>-->
<!--                      <CCol class="infoLabel"> Default Contract Signer </CCol>-->
<!--                      <CCol class="infoText">-->
<!--                        <router-link v-if="company.default_contract_signer_name" to="#">-->
<!--                          {{ company.default_contract_signer_name }}-->
<!--                        </router-link>-->
<!--                        <span v-else>N/A</span>-->
<!--                      </CCol>-->
<!--                      <CCol class="infoLabel"> Default Task Assignee </CCol>-->
<!--                      <CCol class="infoText">-->
<!--                        <router-link v-if="company.default_task_assignee_name" to="#">-->
<!--                          {{ company.default_task_assignee_name }}-->
<!--                        </router-link>-->
<!--                        <span v-else>N/A</span>-->
<!--                      </CCol>-->
                      <CCol class="infoLabel"> Company Type </CCol>
                      <CCol class="infoText"> {{ company.type_title }} </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
              <CCol lg="12" xl="5" class="p-0 position-relative">
                <div class="borderLeft"></div>
                <ActivityTable />
              </CCol>
            </CRow>
          </AccordionItem>
          <AccordionItem :pc-classes="'mt-5'" :label="'Certifications'" :item-key="2" >
            <Certification :certifications="certifications" />
          </AccordionItem>
          <AccordionItem :pc-classes="'mt-5'" :label="'Licence'" :item-key="3" >
            <Licence :licenses="licenses" />
          </AccordionItem>
          <AccordionItem :pc-classes="'mt-5'" :label="'Subsidiary Companies'" :item-key="4">
            <SubsidiaryCompanies
              :view-only="true"
              :subsidiary-companies="subsidiary_companies"
              :company-primary-contacts="[]"
              :company-default-signers-or-assigners="[]" />
          </AccordionItem>
          <AccordionItem :pc-classes="'mt-5'" :label="'Office and Subsidiary Locations '" :item-key="5" >
            <Office ref="office" :offices="offices" />
          </AccordionItem>
        </CAccordion>

      </CContainer>
    </CCardBody>
  </CCard>
</div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
