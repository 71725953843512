<template>
  <CMultiSelect
    :model-value="modelValue"
    size="sm"
    :options="options"
    :disabled="disabled"
    selection-type-counter-text="item(s)"
    selection-type="counter"
    @change="handleChange"
  />
</template>

<script>
export default {
  name: 'MSelect',
  props: {
    modelValue: { type: Array, default: [] },
    options: { type: Array, default: [] },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    handleChange: function (val) {
      let selected = val.length ? val.map((item) => item.value) : []
      this.$emit('update:modelValue', selected)
    },
  },
}
</script>

<style scoped></style>
