import {
  SIGNATURE_REQUIRED,
  CONTRACT_RETURNED_WITH_EDITS,
  CONTRACT_PENDING_INTERNAL_APPROVAL,
  CONTRACT_READY_FOR_SIGNATURE,
  CONTRACT_PENDING_EXTERNAL_SIGNATURE,
  CONTRACT_PENDING_INTERNAL_SIGNATURE,
  REQUEST_PENDING_SUBMISSION,
  REQUEST_PENDING_APPROVAL,
  ACKNOWLEDGE_PENDING_ACKNOWLEDGEMENT
} from "../../utils/constants/taskStatuses";

const TYPES = {
  scope: {
    drafting: {
      background: '#2BB0E0',
      icon: '\uf4fc'
    },
    pending: {
      background: '#0081C2',
      icon: '\uf53d'
    },
    'not sent': {
      background: '#005694',
      icon: '\uf061'
    }
  },
  contract: {
    [SIGNATURE_REQUIRED]: {
      background: '#9FAFBC',
      icon: '\uf1d8',
    },
    [CONTRACT_RETURNED_WITH_EDITS]: {
      background: '#2BB0E0',
      icon: '\uf304'
    },
    [CONTRACT_PENDING_INTERNAL_APPROVAL]: {
      background: '#0081C2',
      icon: '\uf017'
    },
    [CONTRACT_READY_FOR_SIGNATURE]: {
      background: '#005694',
      icon: '\uf5b7'
    },
    [CONTRACT_PENDING_EXTERNAL_SIGNATURE]: {
      background: '#C3CDD5',
      icon: '\uf4fd'
    },
    [CONTRACT_PENDING_INTERNAL_SIGNATURE]: {
      background: '#005694',
      icon: '\uf058'
    }
  },
  task: {
    [REQUEST_PENDING_SUBMISSION]: {
      background: '#9FAFBC',
      icon: '\uf783',
    },
    [REQUEST_PENDING_APPROVAL]: {
      background: '#0081C2',
      icon: '\uf274',
    },
    [ACKNOWLEDGE_PENDING_ACKNOWLEDGEMENT]: {
      background: '#C3CDD5',
      icon: '\uf06e',
    }
  }
}

function handleItems(arr) {
  if (arr && arr.length) {
    const total = arr.reduce((a,v) => a + v);
    return  arr.map(v => Math.max(v / total * 100, 3.5));
  }

  return arr
}

function chartAdapter(item) {
  const chartSetting = TYPES[item.slug] || null
  let backgrounds = []
  let icons = []

  if (chartSetting) {
    item.items.forEach(item => {
      if (chartSetting[item.status]) {
        backgrounds.push(chartSetting[item.status]['background'])
        icons.push(chartSetting[item.status]['icon'])
      }
    })
  }

  return {
    title: item.title,
    slug: item.slug,
    total: item.total,
    open: item.open,
    overdue: item.overdue,
    data: handleItems(item.items?.map((el) => el.total)),
    additionalData:
      item.items?.map((el) => ({
        title: el.status,
        total: el.total,
        open: el.open,
        overdue: el.overdue,
      })) || [],
    backgroundColor: backgrounds,
    icons: icons
  }
}

function sectionAdapter(section) {
  return {
    title: section.title,
    slug: section.slug,
    items: section.items?.map((el) => chartAdapter(el)) || [],
    option: section.option
  }
}
export function dashboardAdapter(data) {
  return data.map((el) => sectionAdapter(el)) || []
}
