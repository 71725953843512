export const toastsTypes = {
	success: {
		color: 'success',
		header: 'Success'
	},
	error: {
		color: 'danger',
		header: 'Error'
	},
}
