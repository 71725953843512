<template>
  <div class="login-wrap">
    <div class="login" v-if="!codeModal">
      <h1>Superadmin Login</h1>

      <CInputGroup class="mt-4">
        <CFormInput
          v-model="formData.email"
          maxlength="100"
          placeholder="Enter your Email"
          autocomplete="username email"
          :invalid="!!validationErrors.email"
          @blur="handleChangeField('email', $event)"
        />
      </CInputGroup>
      <CFormText
        v-if="validationErrors.email"
        :style="`color: ${validationErrors.email && 'red'}`"
        >{{ validationErrors.email }}</CFormText
      >

      <CInputGroup class="mt-4">
        <CFormInput
          v-model="formData.password"
          :type="visPass ? 'password' : 'text'"
          placeholder="Enter your Password"
          autocomplete="curent-password"
          :invalid="!!validationErrors.password"
          :class="{isInvalid: !!validationErrors.password}"
          class="no-append"
          @blur="handleChangeField('password', $event)"
          @keyup.enter="handleSubmit"
        />
          <img v-if="visPass" class="password-visibility-toggle" @click.self.stop="visPass = false" src="@/assets/eye.svg"/>
          <img v-else @click.self.stop="visPass = true" src="@/assets/eye-slash.svg" class="password-visibility-toggle"/>
      </CInputGroup>
      <CFormText
        v-if="validationErrors.password"
        :style="`color: ${validationErrors.password && 'red'}`"
        >{{ validationErrors.password }}</CFormText
      >

      <CFormText v-if="invalidCred" style="color: red" >{{ invalidCred }}</CFormText>

      <div class="d-block d-sm-flex justify-content-center mt-4 w-100 text-center">
        <submit-btn
          color="primary"
          class="px-4 btnLogin"
          :loading="submitLoading"
          :disabled="validator.validateAll(formData).hasErrors"
          @click="handleSubmit"
        >
          Log In
        </submit-btn>
      </div>
    </div>

    <div class="login code" v-else>
      <h1>User Verification</h1>
      <p class="code-sublabel m-0">The verification code has been sent to Telegram</p>

      <div class="mt-4 d-flex justify-content-center align-items-center flex-column">
        <CInputGroup class="align-items-center w-auto">
          <CFormInput
            type="number"
            :value="code[0]"
            ref="code-0"
            :class="`code-input ${codeErrors ? 'invalid ' : ''}`"
            maxlength="1"
            @input="$event => inputEvent($event, 0)"
          />
          <CFormInput
            type="number"
            :value="code[1]"
            ref="code-1"
            :class="`code-input ${codeErrors ? 'invalid ' : ''}`"
            maxlength="1"
            @input="$event => inputEvent($event, 1)"
          />
          <CFormInput
            type="number"
            :value="code[2]"
            ref="code-2"
            :class="`code-input ${codeErrors ? 'invalid ' : ''}`"
            maxlength="1"
            @input="$event => inputEvent($event, 2)"
          />
          <CFormInput
            type="number"
            :value="code[3]"
            ref="code-3"
            :class="`code-input ${codeErrors ? 'invalid ' : ''}`"
            maxlength="1"
            @input="$event => inputEvent($event, 3)"
          />
        </CInputGroup>
        <CFormText v-if="codeErrors" style="margin: 2px 16px; text-align: center; color: red">
          {{ codeErrors }}
        </CFormText>
      </div>

      <p class="code-text">
        Didn’t get a code?
        <a v-if="!counterNewCode" @click="!counterNewCode && resend()">Click to resend</a>
      </p>

      <CToast v-if="counterNewCode" :autohide="false" class="align-items-center w-100">
        <CToastBody>
          A new code was sent. You can receive another code in {{counterNewCode}} seconds
        </CToastBody>
      </CToast>

      <div class="d-block d-sm-flex justify-content-center mt-4 w-100 text-center">
        <CButton color="primary" variant="outline" class="mx-sm-4 w-100 mb-3" @click="goLogin">
          Go Back
        </CButton>
        <submit-btn ref="submit-btn" class="mx-sm-4 w-100 mb-3" color="primary" :loading="submitLoading" :disabled="code.filter(i => !!i).length !== 4" @click="handleSubmitCode">
          Submit
        </submit-btn>
      </div>
    </div>
  </div>
</template>

<script>
import  { mapActions } from 'vuex'
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import { parseError } from '@/utils/api'

export default {
  inject: ['toast'],
  created() {
    this.setRules()
  },
  data() {
    return {
      submitLoading: false,
      invalidCred: '',
      formData: {
        email: '',
        password: ''
      },

      validator: {},
      validationErrors: {},

      visPass: true,

      codeModal: false,
      code: ['', '', '', ''],
      codeErrors: null,
      codeInputKey: 0,

      counterNewCode: 0
    }
  },
  watch: {
    codeModal() {
      if (this.codeModal) {

      }
    },
    formData: {
      handler: function () {
        this.invalidCred = ''
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['loginSuperAdmin']),
    goLogin() {
      this.codeErrors = null
      this.codeModal = false
      this.code = ['', '', '', '']
    },
    handleChangeField(field, event) {
      this.validationErrors[field] = this.validator.validate(
        field,
        event.target.value,
      )
    },
    setRules() {
      this.validator = validator({
        email: [rules.required, rules.email],
        password: [rules.required],
      })
    },
    inputEvent(event, index) {
      this.codeErrors = null
      this.code[index] = event.data
      let value = event.target.value
      if (value) {
        if (index < 3) this.$refs['code-' + (index + 1)].$el.focus()
        else this.$refs['submit-btn'].$el.focus()
        this.$refs['code-' + index].$forceUpdate()
      }
      this.codeInputKey++
    },
    handleSubmit() {
      const validationResult = this.validator.validateAll(this.formData)
      if (!validationResult.hasErrors) {
        this.submitLoading = true
        this.$http.superadmin.login(this.formData)
          .then(() => {
            this.codeModal = true
          })
          .catch((error) => {
            let err = parseError(error)
            if (err.validationMessages) {
              this.validationErrors = err.validationMessages
            }
            if (err.message && !Object.keys(err.validationMessages).length) {
              this.invalidCred = err.message
            }
          })
          .finally(() => {
            this.submitLoading = false
          })
      } else {
        this.validationErrors = validationResult.validationErrors
      }
    },
    handleSubmitCode() {
      this.submitLoading = true
      this.loginSuperAdmin({
        code: this.code.join('')
      })
        .then(() => {
          this.$router.push({ name: 'SuperAdminCompany' })
        })
        .catch((err) => {
          err = parseError(err)
          if (err.status === 401) {
            this.codeErrors = err.message
          } else {
            this.toast('error', err.message)
          }
        })
        .catch((error) => {
          let err = parseError(error)
          if (err.validationMessages) {
            this.validationErrors.codeErrors = err.validationMessages.code || null
          }
          if (err.message) {
            this.toast('error', err.message)
          }
        })
        .finally(() => {
          this.submitLoading = false
        })
    },
    resend() {
      this.submitLoading = true
      this.$http.superadmin.resendCode({
        email: this.formData.email
      })
        .then((res) => {
          this.toast('success', res.data.message)
          this.timerToResend()
        })
        .catch((error) => {
          let err = parseError(error)
          if (err.validationMessages) {
            this.validationErrors.codeErrors = err.validationMessages.code || null
          }
          if (err.message) {
            this.toast('error', err.message)
          }
        })
        .finally(() => {
          this.submitLoading = false
        })
    },
    timerToResend() {
      this.counterNewCode = 60

      let timer = setInterval(() => {
        if (this.counterNewCode) {
          this.counterNewCode--
        } else {
          clearInterval(timer)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>

@media (max-width: 576px) {
  .btnLogin {
    width: 100% !important;
  }
}

.login {
  background: #E5E5E5;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(48, 60, 84, 0.08);
  width: 426px;
  padding: 40px 55px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-text {
    width: 100%;
  }

  &.code {
    width: 560px;
  }

  .code {

    &-sublabel {
      font-size: 16px;
      line-height: 21px;
      color: #303C54;
    }

    &-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #51525F;
      text-decoration: none;
      margin-top: 12px;
      text-align: center;

      a {
        cursor: pointer;
      }
    }

    &-input {
      -moz-appearance: textfield;
      width: 60px !important;
      max-width: 60px !important;
      box-sizing: border-box;
      margin: 0 8px 0 8px !important;
      border-radius: 5px !important;
      text-align: center;
      font-size: 14px;
      line-height: 21px;
      color: #303c54;

      &.invalid {
        border-color: #e55353;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    @media (max-width: 420px) {
      &-input {
        width: 50px !important;
        max-width: 50px !important;
      }
    }
  }

  &-wrap {
    height: 100vh;
    background: #E5E5E5;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    text-align: center;
  }
}
</style>

<style>
.is-invalid + .password-visibility-toggle{
  margin-right: 1.7rem;
}
.password-visibility-toggle{
  width: 20px;
  object-fit: contain;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
  cursor: pointer;
}
</style>
