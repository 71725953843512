import posthog from "posthog-js";

export default {
    install(app) {
        app.config.globalProperties.$posthog = posthog
            .init(process.env.VUE_APP_POSTHOG_APP_KEY, {
                api_host: "https://us.i.posthog.com",
                person_profiles: 'identified_only',
            });
    }
};
