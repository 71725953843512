import { dashboardAdapter } from '@/tools/DataAdapters/dashboard-charts'
import ApiService from '@/services/ApiService'
const state = () => ({})
const actions = {
  fetchDashboardData(data, content) {
    return ApiService.api.dashboard.getCharts(content).then((response) => {
      return dashboardAdapter(response.data?.items) || []
    })
  },
}
const getters = {}
const mutations = {}
export default {
  state,
  actions,
  getters,
  mutations,
}
