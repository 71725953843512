import moment from 'moment'
import { DateTime } from 'luxon'
import i18n from '../i18n'

const dateFront = process.env.VUE_APP_DATE_FRONT_FORMAT
const dateBack = process.env.VUE_APP_DATE_BACK_FORMAT
const timeFront = process.env.VUE_APP_TIME_FRONT_FORMAT
const timeBack = process.env.VUE_APP_TIME_BACK_FORMAT

export const BtoF = (p) => {
  const { value, time, date, ms } = { time: false, date: true, ms: false, ...p }
  if (!Date.parse(value)) {
    return i18n.global.t('default.empty')
  }

  let dateM = moment(
    value,
    `${date ? dateBack : ''}${time && date ? ' ' : ''}${time ? timeBack : ''}`,
  )
  return ms
    ? new Date(dateM.valueOf())
    : dateM.format(
        `${date ? dateFront : ''}${time && date ? ' ' : ''}${
          time ? timeFront : ''
        }`,
      )
}
export const FtoB = (p) => {
  const { value, time, date, ms } = { time: false, date: true, ms: false, ...p }
  return ms
    ? moment(value).format(
        `${date ? dateBack : ''}${time && date ? ' ' : ''}${
          time ? timeBack : ''
        }`,
      )
    : moment(
        value,
        `${date ? dateFront : ''}${time && date ? ' ' : ''}${
          time ? timeFront : ''
        }`,
      ).format(
        `${date ? dateBack : ''}${time && date ? ' ' : ''}${
          time ? timeBack : ''
        }`,
      )
}

// Global object luxon
export const luxon = () => DateTime

// Time by current time zone
export const timeByCurrentTimeZone = (date, fromISO = false) => {
  let defaultTimeZone
  if (fromISO) {
    defaultTimeZone = DateTime.fromISO(date, {
      zone: 'UTC',
      setZone: true,
    })
  } else {
    defaultTimeZone = DateTime.fromFormat(date, 'yyyy-LL-dd HH:mm:ss', {
      zone: 'UTC',
      setZone: true,
    })
  }
  const currentZone = DateTime.local().zoneName

  return defaultTimeZone.setZone(currentZone)
}

// date with time by current time zone 'LL/dd/yyyy HH:mm'
export const DwT = (date) => {
  return timeByCurrentTimeZone(date).toFormat('LL/dd/yyyy HH:mm')
}
