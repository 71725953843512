import Certification from './components/Certification'
import Licence from './components/Licence'
import Office from './components/Office'
import AccordionItem from '@/components/Page/AccordionItem'
import ActivityTable from './components/ActivityTable'
import { mapActions } from 'vuex'
import Avatar from "@/components/Avatar.vue";
import SubsidiaryCompanies from "@/views/Setting/Account/CompanyDetails/components/SubsidiaryCompanies.vue";

export default {
  inject: ['toast'],
  components: {
    SubsidiaryCompanies,
    Avatar,
    Certification,
    Licence,
    Office,
    AccordionItem,
    ActivityTable,
  },
  data() {
    return {
      loading: false,
      certifications: [],
      licenses: [],
      offices: [],
      company: {},
      last_activity_date: {},
      subsidiary_companies: []
    }
  },
  created() {
    this.$nextTick(() => this.fetchData())
  },
  methods: {
    ...mapActions(['loginSuperAdminCompany']),
    login() {
      this.loginSuperAdminCompany({ company_id: this.$route.params.id })
        .then(() => this.$router.push({ name: 'ProjectsList' }))
    },
    fetchData() {
      this.loading = true
      this.$http.superadmin
        .companyDetails(this.$route.params.id)
        .then((response) => {
          let res = response.data.data
          this.certifications = res.certifications
          this.licenses = res.licenses
          this.offices = res.offices
          this.company = res.company
          this.last_activity_date = res.last_activity_date
          this.subsidiary_companies = res.subsidiary_companies
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },
    showFile(file) {},
    isValidUrl(string) {
      try {
        new URL(string)
        return true
      } catch (err) {
        return false
      }
    },
    toCompanyWebSite(link) {
      link = this.isValidUrl(link) ? link : 'https://' + link
      window.open(link, '_blank')
    },
  },
}
