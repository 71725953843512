<template>
  <CRow :key="key" :xs="{ cols: 1, gutter: 3 }" class="text-dark">
    <CCol>
      <CRow>
        <CFormLabel class="filterLabelWidth col-12 col-form-label">Due in</CFormLabel>
        <CCol class="mx-0">
          <CFormSelect v-model="form.due_in">
            <option
              v-for="(period, key) in dueIn"
              :key="key"
              :value="period.value"
              :selected="period.value === form.due_in"
            >
              {{ period.label }}
            </option>
          </CFormSelect>
        </CCol>
      </CRow>
    </CCol>
    <CCol>
      <CRow>
        <CFormLabel class="filterLabelWidth cok-12 col-form-label">Priority</CFormLabel>
        <CCol class="mx-0">
          <CFormSelect v-model="form.priority">
            <option
              v-for="(priority, key) in taskPriorities"
              :key="key"
              :value="priority.value"
              :selected="form.priority === priority.value"
            >
              {{ priority.label }}
            </option>
          </CFormSelect>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import mixin from './mixin'
import MSelect from '../components/MSelect'
export default {
  name: 'Users',
  components: { MSelect },
  mixins: [mixin],
  data() {
    return {
      professions: [],
      form: {
        due_in: 'week',
        priority: '',
      },
      dueIn: [
        {value: 'day', label: 'Today'},
        {value: 'week', label: 'One week'},
        {value: 'month', label: 'One month'},
        {value: '3_month', label: '3 months'},
        {value: 'year', label: '1 year'},
      ],
      taskPriorities: [
        { value: '', label: 'Select Priority' },
        { value: 'Normal', label: 'Normal' },
        { value: 'High', label: 'High' },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setOrigin()
    })
  },
  methods: {
    getBadgeItems(outForm = undefined) {
      const form = outForm ? outForm : this.form
      return [
        { label: 'Due In', value: form.due_in, key: ['due_in'], disabled: true },
        { label: 'Priority', value: form.priority, key: ['priority'] },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  .filterLabelWidth {
    width: 113px !important;
  }
}
</style>
