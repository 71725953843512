<template>
    <app-preloader v-if="appLoading" />
    <router-view v-if="!appLoading && initialized" :key="`page-${$route.path}`" />

    <CNToast :toasts="$store.getters.toasts" @close="(index) => $store.commit('deleteToast', index)" />

    <CToaster placement="top-end" style="top: 63px !important">
        <CToast
            v-for="toast in toasts"
            :key="toast.id"
            style="background: white; border-left: 4px solid #0081c2"
            :autohide="true"
            :delay="10000"
            @close="handleUpdateShowToast($event, toast.id)">
            <CToastBody style="display: flex; align-items: flex-start; justify-content: space-between">
                {{ toast.message }}
                <CCloseButton @click="handleUpdateShowToast(false, toast.id)" />
            </CToastBody>
        </CToast>

        <CToast
            v-if="downloadToasts.length"
            style="background: white; border-left: 4px solid #0081c2"
            :autohide="false"
            @close="removeDownloadToast">
            <CToastHeader close-button>
                <span class="me-auto fw-bold">Preparation for download</span>
            </CToastHeader>
            <CToastBody>
                <CListGroup flush>
                    <CListGroupItem
                        v-for="downloadToast in downloadToasts"
                        :key="downloadToast.id"
                        style="background-color: transparent; padding-left: 0"
                        class="d-flex align-items-center justify-content-between">
                        <span>{{ downloadToast.message }}</span>

                        <template v-if="!downloadToast.error">
                            <CSpinner v-if="!downloadToast.isCompleted" size="sm" class="flex-shrink-0" />

                            <CIcon v-else name="cilCheckCircle" size="lg" style="color: #2eb85c" />
                        </template>

                        <CIcon v-else name="cilXCircle" size="lg" style="color: #db000b" />
                    </CListGroupItem>
                </CListGroup>
            </CToastBody>
        </CToast>
    </CToaster>

    <ModalComponent @resolve="resolveModalComponent" @reject="rejectModalComponent" />
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import AppPreloader from '@/components/Preloaders/AppPreloader';
import { toastsTypes } from './utils/toast';
import { authorizeMiddleware } from '@/router';
import ModalComponent from '@/components/ModalComponent';
import { downloadFile } from '@/utils/files';

export default {
    name: 'App',
    components: {
        AppPreloader,
        ModalComponent,
    },
    provide() {
        return {
            toast: (type, message, header) => {
                const defaultType = 'info';
                this.$notify({
                    type: defaultType,
                    title: header,
                    content: message,
                });
            },
            downloadToast: {
                open: (id, message, isCompleted) => this.pushDownloadToast(id, message, isCompleted),
                update: (id) => this.updateDownloadToast(id),
                clear: this.removeDownloadToast,
            },
            $modalComponent: {
                open: this.openModalComponent,
                close: this.closeModalComponent,
                loading: (loading) => this.loadingModalComponent(loading),
            },
        };
    },
    data() {
        return {
            toasts: [],
            downloadToasts: [],
            resolveModalComponent: () => {},
            rejectModalComponent: () => {},
        };
    },
    computed: {
        ...mapState({
            initialized: (state) => state.app.initialized,
            appLoading: (state) => state.app.loading,
            user: (state) => state.app.user,
        }),
        ...mapGetters(['modalComponent']),
    },
    watch: {
        modalComponent() {
            if (this.modalComponent) {
                setTimeout(() => {
                    this.setTeleportNameModalComponent(this.modalComponent.name);
                }, 1);
            } else {
                this.setTeleportNameModalComponent(null);
            }
        },
        user(val, oldVal) {
            if (this.initialized) {
                this.$nextTick(() => {
                    if (val && !oldVal && !this.$route.meta.auth && this.$route.name !== 'PageNotFound') {
                        this.$router.push({ name: 'Home' });
                        this.initNotificationSocket({ user: val });
                    } else if (!val && oldVal) {
                        this.$router.push({ name: 'Login' });
                    }
                });
            }
        },
    },
    methods: {
        ...mapMutations(['setModalComponent', 'closeModalComponent', 'setTeleportNameModalComponent']),
        ...mapActions(['initApp']),
        initNotificationSocket({ user }) {
            const userId = user?.id;

            if (userId) {
                this.$LaravelEcho
                    .private('user-notification.' + userId)
                    .listenToAll(() => {
                        this.$http.auth
                            .getNotifications()
                            .then((res) => this.$store.dispatch('setUnreadNotificationsCounter', res.data.unread));
                    })
                    .listen('.user-notification.schedule_tasks_archive_created', (data) => {
                        const token = data.params?.task_preview_archive?.download_token;

                        if (token) {
                            this.$http.tasks
                                .previewDataScheduleTasksArchive({ token })
                                .then((res) => {
                                    const fileName = res.data.name;

                                    this.$http.tasks
                                        .downloadScheduleTasksArchive({
                                            token: token,
                                        })
                                        .then((response) => {
                                            downloadFile(response, fileName);
                                        })
                                        .then(() => {
                                            this.updateDownloadToast(token);
                                        });
                                })
                                .catch((err) => {
                                    this.pushToast('error', err.response.data.message);
                                });
                        }
                    })
                    .listen('.user-notification.schedule_tasks_archive_failed', (data) => {
                        const token = data.params?.task_preview_archive?.download_token;

                        this.pushToast(
                            'error',
                            data.params?.task_preview_archive?.notification || 'Error, please try again later.',
                        );
                        this.updateDownloadToast(token, true);
                    })
                    .listen('.user-notification.document_download_job_finished', (data) => {
                        const token = data.params?.download_document_job?.download_token;

                        if (!token) return;

                        this.$http.common.checkBeforeDownload({ params: { token } }).then((response) => {
                            const fileName = response.data.name;

                            this.$http.common
                                .download({ token })
                                .then((response) => {
                                    downloadFile(response, fileName);
                                })
                                .then(() => {
                                    this.updateDownloadToast(token);
                                })
                                .catch((error) => {
                                    this.pushToast('error', error.response.data.message);
                                });
                        });
                    })
                    .listen('.user-notification.document_download_job_failed', (data) => {
                        const token = data.params?.download_document_job?.download_token;
                        const notification =
                            data.params.download_document_job?.notification || 'Error, please try again later.';

                        this.pushToast('error', notification);
                        this.updateDownloadToast(token, true);
                    });
            }
        },
        loadingModalComponent(loading) {
            this.$store.commit('setLoadingModalComponent', loading);
        },
        openModalComponent(data) {
            this.setModalComponent(data);
            return new Promise((resolve, reject) => {
                this.resolveModalComponent = () => {
                    resolve();
                };
                this.rejectModalComponent = () => {
                    reject();
                    this.closeModalComponent();
                };
            });
        },
        pushDownloadToast(id, message, isCompleted = false, error = false) {
            this.downloadToasts = [
                ...this.downloadToasts,
                {
                    id,
                    message,
                    isCompleted,
                    error,
                },
            ];
        },
        updateDownloadToast(id, error = false) {
            this.downloadToasts = this.downloadToasts.map((t) =>
                t.id === id ? { ...t, isCompleted: true, error } : t,
            );
        },
        removeDownloadToast() {
            this.downloadToasts = [];
        },
        pushToast(type, message, header) {
            message = message ? message.charAt(0).toUpperCase() + message.slice(1) : message;
            const typeData = toastsTypes[type];
            if (typeData) {
                this.toasts = [
                    ...this.toasts,
                    {
                        id: uuidv4(),
                        ...typeData,
                        message,
                        header: header || typeData.header,
                    },
                ];
            }
        },
        handleUpdateShowToast(show, id) {
            if (!show) {
                const newToasts = this.toasts.slice();
                const hiddenToast = newToasts.findIndex((t) => t.id === id);
                if (hiddenToast >= 0) {
                    newToasts.splice(hiddenToast, 1);
                    this.toasts = newToasts;
                }
            }
        },
    },
    created() {
        localStorage.removeItem('failedRedirection');
        this.$router.isReady().then(() => {
            const redirectRoute = () => {
                const to = authorizeMiddleware(this.$route);
                return to ? this.$router.push(to) : new Promise((res) => res());
            };
            this.initApp(redirectRoute).then(() => {
                this.initNotificationSocket({ user: this.user });
            });
        });
    },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
