export default {
  install(app) {
    app.config.globalProperties.$objPicker = (object, keysToPick) => {
      if (typeof object === 'object' && Object.keys(object).length && Array.isArray(keysToPick)){
        let picked = {};
        keysToPick.forEach(key => {
          Object.assign(picked, {[key] : object[key] ?? null})
        });
        return  picked
      } else {
        return {}
      }
    }
  }
}
