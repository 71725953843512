<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1 d-flex d-md-none" @click="$store.commit('toggleSidebar')">
        <img src="@/assets/menu-btn.svg" width="20" height="20" />
      </CHeaderToggler>
      <CHeaderToggler class="ps-1 d-none d-md-flex" @click="$store.commit('toggleUnfoldable')">
        <img v-if="!sidebarUnfoldable" src="@/assets/menu-btn.svg" width="20" height="20" />
        <img v-else src="@/assets/menu-btn-2.svg" width="20" height="20" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-md-none" to="/">
        <img src="@/assets/logo-min.svg" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <div id="header-nav" style="display: flex; align-items: center" />
      </CHeaderNav>

      <CHeaderNav class="me-2">
        <SearchResults />
      </CHeaderNav>
      <CHeaderNav class="me-2">
        <router-link :to="{ name: 'Settings' }">
          <CIcon class="my-1 mx-2" icon="cil-settings" size="lg" />
        </router-link>
      </CHeaderNav>
      <CHeaderNav class="me-2">
        <router-link :to="{ name: 'Notifications' }">
          <CIcon class="my-1 mx-2" icon="cil-bell" size="lg" />
        </router-link>
      </CHeaderNav>
      <CHeaderNav class="ms-3 me-4">
        <AppHeaderDropdownAccnt :is_superadmin="true" />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from '@/components/AppBreadcrumb'
import AppHeaderDropdownAccnt from '@/components/AppHeaderDropdownAccnt'
import AppHeaderDropdownMssgs from '@/components/AppHeaderDropdownMssgs'
import AppHeaderDropdownNotif from '@/components/AppHeaderDropdownNotif'
import AppHeaderDropdownTasks from '@/components/AppHeaderDropdownTasks'
import SearchResults from '@/views/TopNav/SearchResults/index.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
    AppHeaderDropdownMssgs,
    AppHeaderDropdownNotif,
    AppHeaderDropdownTasks,
    SearchResults,
  },
  setup() {
    const store = useStore()
    return {
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable)
    }
  },
}
</script>
