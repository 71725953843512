import {asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    getCollectionList: data => asJson(axios).get(getVerUri('company/templates-collection/list'), data),
    getTemplates: data => asJson(axios).get(getVerUri('company/templates'), {params: data}),
    getTemplate: data => asJson(axios).get(getVerUri(`company/templates/${data.template_id}`)),
    archiveTemplate: templateId => asJson(axios).delete(getVerUri(`company/templates/${templateId}/soft-delete`)),
    storeTemplate: data => asJson(axios).post(getVerUri(`company/templates`), data),
    updateTemplate: data => asJson(axios).put(getVerUri(`company/templates/${data.template_id}`), data.formData),
    getCodeList: data => asJson(axios).get(getVerUri('settings/shortcode/search'), data),
    getTemplateTaskSettings: data => asJson(axios).get(getVerUri(`library/template/${data.template_id}/task-settings`)),
})
