import ApiService from '@/services/ApiService'
import {
  scenarioTableAdapter,
  templatesListAdapter,
  scenariosListAdapter,
  packageTableAdapter,
  filterTableAdapter,
  viewDetailsAdapter,
} from '@/tools/DataAdapters/library'

const state = () => ({
  scenarios: [],
  files: [],
})
const actions = {
  getScenariosList({ commit }, data) {
    return ApiService.api.library.getAllScenarios(data).then((response) => {
      const scenarios = scenariosListAdapter(response.data.data)
      commit('setScenariosList', scenarios)
    })
  },
  getAllTemplates({ commit }, data) {
    return ApiService.api.library.getAllTemplates(data).then((response) => {
      const templates = templatesListAdapter(response.data.data)
      commit('setFiles', templates)
    })
  },
  getScenarioData({ commit }, data) {
    return ApiService.api.library.getScenarioData(data).then((response) => {
      const scenario = scenarioTableAdapter(response.data.data.collection)
      commit('setFiles', scenario)
    })
  },
  getPackageData({ commit }, data) {
    return ApiService.api.library.getPackageData(data).then((response) => {
      const packageData = packageTableAdapter(response.data.data.folder)
      commit('setFiles', packageData)
    })
  },
  getDataByFilter({ commit }, data) {
    return ApiService.api.library.getDataByFilter(data).then((response) => {
      const filterData = filterTableAdapter(response.data.data)
      commit('setFiles', filterData)
    })
  },
  getTemplateDetails(store, data) {
    return ApiService.api.library.getTemplateDetails(data).then((response) => {
      return viewDetailsAdapter(response.data)
    })
  },
  getLabelsList() {
    return ApiService.api.library.getLabels().then((response) => {
      return (
        response.data.data?.map((label) => ({
          value: label.id,
          label: label.name,
        })) || []
      )
    })
  },

  renameEntity(store, data) {
    return ApiService.api.library
      .entityRename({
        entity: data.key,
        entity_id: data.id,
        name: data.name,
      })
      .then((response) => {
        return response.data.message
      })
  },
  deleteEntity(store, data) {
    return ApiService.api.library
      .entityDelete({
        params: data,
      })
      .then((response) => {
        return response.data.message
      })
  },
  moveEntity(store, data) {
    return ApiService.api.library.entityMove(data).then((response) => {
      return response.data.message
    })
  },
  copyEntity(store, data) {
    return ApiService.api.library.entityDuplicate(data).then((response) => {
      return response.data.message
    })
  },
  restoreTemplates(store, data) {
    return ApiService.api.library.restoreTemplates(data)
  },

  addToFavorite(state, data) {
    return ApiService.api.library.addToFavorite(data).then((response) => {
      return response.data.message
    })
  },
  setAsPrimary(state, data) {
    return ApiService.api.library.setAsPrimary(data).then((response) => {
      return response.data.message
    })
  },
  addShortcut(state, data) {
    return ApiService.api.library.addShortcut(data).then((response) => {
      return response.data.message
    })
  },

  createNewScenario(store, data) {
    return ApiService.api.library.createScenario(data).then((response) => {
      return response.data.message
    })
  },
  createNewPackage(store, data) {
    return ApiService.api.library.createPackage(data).then((response) => {
      return response.data.message
    })
  },
}
const getters = {
  scenarios: (state) => state.scenarios,
  files: (state) => state.files,
}
const mutations = {
  setScenariosList: (state, data) => {
    state.scenarios = data
  },
  setFiles: (state, data) => {
    state.files = data
  },
}

export default {
  state,
  actions,
  getters,
  mutations,
}
