<template>
  <div v-if="disabled">
    <div
      class="CSelectSearch__value"
      :class="{
        'CSelectSearch__value--reverse': reverse,
      }"
      :style="`opacity: .6; border-color: ${!!error && 'red'}`"
    >
      {{ getValue || placeholder }}
      <div class="CSelectSearch__arrow">
        <img
          :class="[open ? 'rotateArrow' : '']"
          src="@/assets/arrow_down.svg"
        />
      </div>
    </div>
  </div>
  <div v-else v-click-outside="onClickOutside">
    <div style="position: relative; width: 100%">
      <div
        class="CSelectSearch__value"
        :class="{
          CSelectSearch__large: inputHeight,
          'CSelectSearch__value--reverse': reverse,
        }"
        :style="`border-color: ${!!error && 'red'}`"
        @click.self.stop="open = true"
      >
        <input
          v-if="search && open"
          v-model="searchField"
          placeholder="Search"
          class="CSelectSearch__searchField"
        />
        <div
          v-else
          :key="getOptions.length"
          :style="[!getValue ? 'color: #b1b7c1' : '']"
          class="CSelectSearch__text"
          @click.self.stop="open = !open"
        >
          {{ getValue || placeholder }}
        </div>

        <div v-if="loading" class="CSelectSearch__arrow">
          <CSpinner size="sm" color="primary" />
        </div>
        <div v-else-if="!visibleNotFound || visibleNotFoundWithSearch" class="CSelectSearch__arrow">
          <CIcon name="cilSearch" />
        </div>
        <div v-else class="CSelectSearch__arrow">
          <img
            :class="[open ? 'rotateArrow' : '']"
            src="@/assets/arrow_down.svg"
            @click.stop="open = !open"
          />
        </div>
      </div>

      <template v-if="!visibleNotFound && !getOptions.length" />
      <div v-else-if="open" class="CSelectSearch__drop">
        <template v-if="multi && getOptions.length > 1">
          <div class="CSelectSearch__drop-item" @click.stop="selectAll">
            Select All
          </div>
          <div class="CSelectSearch__drop-item" @click.stop="deselectAll">
            Deselect All
          </div>
          <hr style="margin: 0" />
        </template>
        <div
          v-for="item in getOptions"
          :key="`${item.value}`"
          class="CSelectSearch__drop-item"
          @click.stop="changeValue(item.value)"
        >
          <CFormCheck
            v-if="multi"
            :key="modelValue.findIndex((i) => i === item.value)"
            :checked="!!(modelValue.findIndex((i) => i === item.value) !== -1)"
            style="margin-right: 5px"
          />
          {{ item.label }}
        </div>
        <div
          v-if="!getOptions.length"
          style="opacity: 0.85; cursor: default"
          class="CSelectSearch__drop-item"
        >
          <slot name="empty_list"> Item not found </slot>
        </div>
      </div>
    </div>
    <CFormText
      v-if="error"
      class="selectError"
      :class="{ 'text-error': !!error }"
      :style="`color: ${error && 'red'}`"
    >
      {{ error }}
    </CFormText>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    modelValue: {},
    inputHeight: { type: Boolean, default: false },
    error: { type: String, default: '' },
    options: { type: Array, default: [] },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    itemsName: { type: String, default: 'items' },
    search: { type: Boolean, default: false },
    multi: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    visibleNotFound: { type: Boolean, default: true },
    visibleNotFoundWithSearch: { type: Boolean, default: false },
    viewAll: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    reverse: { type: Boolean, default: false },
  },
  emits: ['update:modelValue', 'fetchOptions', 'changeValue', 'touchField'],
  data() {
    return {
      open: false,
      searchField: '',
    }
  },
  computed: {
    getValue() {
      if (this.multi) {
        if (this.modelValue.length > 1) {
          return this.modelValue.length
            ? `Selected ${this.modelValue.length} ${this.itemsName}`
            : ''
        } else {
          const item = this.options.find(
            (item) => item.value === this.modelValue[0],
          )
          return item ? item.label : ''
        }
      } else {
        const item = this.options.find((item) => item.value === this.modelValue)
        return item ? item.label : ''
      }
    },
    getOptions() {
      return this.searchField
        ? this.viewAll
          ? this.options
          : this.options.filter(
              (item) =>
                item.label
                  .toLowerCase()
                  .indexOf(this.searchField.toLowerCase()) !== -1,
            )
        : this.options
    },
  },
  watch: {
    searchField() {
      this.$emit('fetchOptions', this.searchField)
    },
    open() {
      this.searchField = ''
      !this.open && this.$emit('touchField', this.open)
    },
  },
  methods: {
    selectAll() {
      const all = this.getOptions.map((i) => i.value)
      this.$emit('update:modelValue', all)
      this.$emit('changeValue', all)
    },
    deselectAll() {
      this.$emit('update:modelValue', [])
      this.$emit('changeValue', [])
    },
    changeValue(value) {
      if (this.multi) {
        let modelValue = this.modelValue
        const index = modelValue.findIndex((item) => item === value)
        if (index === -1) {
          modelValue.push(value)
        } else {
          modelValue[index] = null
        }
        this.$emit(
          'update:modelValue',
          modelValue.filter((i) => !!i),
        )
        this.$emit(
          'changeValue',
          modelValue.filter((i) => !!i),
        )
      } else {
        this.$emit('update:modelValue', value)
        this.$emit('changeValue', value)
        this.open = false
      }
    },
    onClickOutside() {
      this.open = false
    },
  },
}
</script>

<style lang="scss" scoped>
.CSelectSearch {
  $parent: &;

  &__value {
    position: relative;
    border-radius: 0.25rem;
    width: 100%;
    height: 38px;
    padding: 0.5rem 0.75rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    border: 1px solid #b1b7c1;
    // background: #B1B7C1;
    border-radius: 0.25rem;
    cursor: pointer;

    &--reverse {
      #{$parent} {
        &__arrow {
          left: 10px;
          right: auto;
        }

        &__text,
        &__searchField {
          padding-right: 0;
          padding-left: 30px;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.5;
        }
      }
    }
  }

  &__large {
    height: 56px;
  }

  &__arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    img {
      width: 0.75rem;
    }

    .rotateArrow {
      transform: rotate(180deg);
    }
  }

  &__drop {
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: 100;
    border: 1px solid #b1b7c1;
    border-radius: 0.25rem;
    background: #ffffff;
    max-height: 200px;
    overflow: auto;
    padding: 12px 0;

    &-item {
      padding: 0.375rem 0.75rem;
      cursor: pointer;
      font-size: 14px;
      color: #303c5488;

      &:hover {
        background: #0000001b;
        color: #303c54;
      }
    }
  }

  &__searchField {
    border: none;
    outline: none;
    width: 100%;
    background: transparent;
    // background: #B1B7C1;
    // color: #303C5488;
  }

  &__text {
    width: 100%;
    padding-right: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
