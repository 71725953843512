<template>
  <CRow v-if="type === 'line'" class="mb-3 CInputDate">
    <CFormLabel v-if="label" class="col-sm-4 col-form-label"
      >{{ label }} <span v-if="require" style="color: red">*</span></CFormLabel
    >
    <div class="col-sm-8">
      <DatePicker
        ref="picker"
        :available-dates="{ start: start, end: end }"
        :model-value="modelValue"
        :mode="mode"
        :masks="{
          input: `${format.date}${
            mode === 'dateTime' ? ' ' + format.time : ''
          }`,
          inputDateTime: `${format.date} ${format.time}`,
        }"
        :popover="{ positionFixed: true }"
        @update:modelValue="selected"
      >
        <template #default="{ inputValue, togglePopover }">
          <CFormInput
            style="width: 100%; background: white; min-height: 38px"
            :value="inputValue"
            :placeholder="placeholder"
            :disabled="disabled"
            :readonly="true"
            :style="`border-color: ${error ? 'red' : ''}`"
            @focus="togglePopover()"
          />
        </template>
      </DatePicker>
      <CFormText v-if="error" style="white-space: break-spaces; color: red">
        {{ error }}
      </CFormText>
    </div>
  </CRow>
  <CInputGroup v-else-if="type === 'clear'">
    <DatePicker
      ref="picker"
      :class="{ 'date-picker-redesign': redesign, 'fl_invalid': error, 'fl__placeholder': redesign }"
      :first-day-of-week="1"
      :model-value="modelValue"
      :available-dates="{ start: start, end: end }"
      :mode="mode"
      :masks="{
        input: `${format.date}${mode === 'dateTime' ? ' ' + format.time : ''}`,
        inputDateTime: `${format.date} ${format.time}`,
      }"
      :popover="{ positionFixed: true }"
      @update:modelValue="selected"
    >
      <template #default="{ inputValue, togglePopover }">
        <CInputGroup size="sm" class="position-relative">
          <CFormInput
            :value="inputValue"
            :placeholder="placeholder + (require ? '*' : '')"
            :disabled="disabled"
            :readonly="true"
            style="background: white; min-height: 38px"
            :style="`border-color: ${error ? 'red' : ''}`"
            @focus="togglePopover()">
          </CFormInput>
          <CIcon
            v-if="showClose"
            name="cilX"
            class="position-absolute pointer CInputDate__clear"
            @click="handleClear"
          />
        </CInputGroup>
      </template>
    </DatePicker>
    <CFormText
      v-if="error"
      :style="`white-space: break-spaces; color: ${error && 'red'}`"
      >{{ error }}</CFormText
    >
  </CInputGroup>
  <div v-else class="c-input-date" :style="{ marginBottom: '25px' }">
    <CFormLabel v-if="label"
      >{{ label }} <span v-if="require" style="color: red">*</span></CFormLabel
    >
    <CInputGroup>
      <DatePicker
        ref="picker"
        :model-value="modelValue"
        :mode="mode"
        style="width: 100%"
        :masks="{
          input: `${format.date}${
            mode === 'dateTime' ? ' ' + format.time : ''
          }`,
          inputDateTime: `${format.date} ${format.time}`,
        }"
        :popover="{ positionFixed: true }"
        :is-required="isRequired"
        @update:modelValue="selected"
      >
        <template #default="{ inputValue, togglePopover }">
          <CFormInput
            style="width: 100%; background: white; min-height: 38px"
            :value="inputValue"
            :readonly="true"
            :placeholder="placeholder"
            :disabled="disabled"
            :style="`border-color: ${error ? 'red' : ''}`"
            @focus="togglePopover()"
          />
        </template>
      </DatePicker>
    </CInputGroup>
    <CFormText
      v-if="error"
      :style="`white-space: break-spaces; color: ${error && 'red'}`"
    >
      {{ error }}
    </CFormText>
  </div>
</template>

<script>
import { Calendar, DatePicker } from 'v-calendar'

export default {
  components: {
    Calendar,
    DatePicker,
  },
  props: {
    redesign: { type: Boolean, default: false },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    mode: { type: String, default: 'date' },
    start: { type: Date, default: () => null },
    end: { type: Date, default: () => null },
    error: { type: String, default: '' },
    require: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    modelValue: {},
    isRequired: { type: Boolean, default: false }
  },
  emits: ['update:modelValue', 'touchField'],
  data() {
    return {
      format: {
        date: process.env.VUE_APP_DATE_FRONT_FORMAT,
        time: process.env.VUE_APP_TIME_FRONT_FORMAT,
      },
    }
  },
  computed: {
    showClose() {
      return this.clearable && this.modelValue
    },
  },
  watch: {
    modelValue() {
      this.$emit('touchField')
    },
  },
  mounted() {
    this.$refs.picker.$el.style.width = '100%'
  },
  methods: {
    handleClear() {
      this.$emit('update:modelValue', '')
    },
    selected(value) {
      this.$emit('update:modelValue', value)
    },
  },
}
</script>
<style lang="scss">
.date-picker-redesign {

  & input {
    border: 1px solid #9fafbc;
    border-radius: 8px !important;
    padding: 16px;
    height: 56px;
    transition: 0.3s;
    box-shadow: 0 0 0 4px transparent;
    font-size: 16px !important;
    padding-left: 16px !important;
  }
}

.CInputDate {
  &__clear {
    right: 15px;
    top: 8px;
  }
}
.c-input-date {
  margin-bottom: 1rem;
  width: 100%;

  &__label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  &__input input {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    border: 1px solid;
    color: #5c6873;
    background-color: #fff;
    border-color: #e4e7ea;
    border-radius: 0.25rem;

    &:focus-visible {
      border-color: #8ad4ee;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.25);
    }
  }
}
</style>
