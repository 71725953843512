import {asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    storeDiscussion: data => asJson(axios).post(getVerUri('discussions'), data),
    deleteDiscussion: data => asJson(axios).delete(getVerUri(`discussions/${data.discussionId}`)),
    updateDiscussion: data => asJson(axios).put(getVerUri(`discussions/${data.discussionId}`), data),
    approveDiscussion: data => asJson(axios).post(getVerUri(`discussions/${data.discussionId}/approve`), data.params ?? {}),
    rejectDiscussion: data => asJson(axios).post(getVerUri(`discussions/${data.discussionId}/reject`), data.params ?? {}),
    openDiscussion: data => asJson(axios).post(getVerUri(`discussions/${data.discussionId}/open`)),
    resetDiscussionApprovement: data => asJson(axios).post(getVerUri(`discussions/${data.discussionId}/reset`)),
    storeReply: data => asJson(axios).post(getVerUri('discussion-replies'), data),
    updateReply: data => asJson(axios).put(getVerUri(`discussion-replies/${data.replyId}`), data),
    deleteReply: data => asJson(axios).delete(getVerUri(`discussion-replies/${data.replyId}`)),
    changeDiscussion: data => asJson(axios).put(getVerUri(`discussions/${data.discussionId}/general-contractor-comment`), data.params),
})
