export function handleError(error) {
    let exception = null;
    let validation = null;

    const forbidden = error.response.status === 403;

    if (error.response.status === 500) {
        exception = 'Something wrong happened. Please contact the support.';
    }

    if (error.response.status === 422) {
        const errors = JSON.parse(error.request.response).errors;
        validation = {};

        Object
            .keys(errors)
            .forEach(key => {
                const value = errors[key];
                validation[key] = Array.isArray(value) ? value[0] : value;
            });
    }

    return Promise.reject({exception, forbidden, validation});
}
