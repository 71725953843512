import {asJson, getVerUri} from '@/services/ApiService';

export default (axios) => ({
    fetchReferenceBook: (data) => asJson(axios).get(getVerUri('reference-book/list'), data),
    fetchPageData: (data) => asJson(axios).get(getVerUri('page/get-data'), data),
    downloadInitJob: (data) => asJson(axios).post(getVerUri('documents/init-job'), data),
    checkBeforeDownload: (data) => asJson(axios).get(getVerUri('documents/check-before-download'), data),
    download: (data) => axios({
        method: 'GET',
        url: getVerUri('documents/download'),
        params: data,
        responseType: 'blob',
    }),
    downloadSingleDocument: (data) => axios({
        method: 'GET',
        url: getVerUri('documents/download-single'),
        responseType: 'blob',
        params: data,
    })
});
