<template>
  <CButton @click="createAccountBtn" color="primary" style="white-space: nowrap" class="mb-4 create__btn">Create new account</CButton>
  <ConfirmDialog ref="confirmDialog" />
  <teleport to="#form-create" v-if="teleportVisibleModalComponent">
    <CRow :key="key">
      <CCol :xs="12" :md="6" class="mb-4">
        <CFormLabel>Owner`s First Name *</CFormLabel>
        <CInputGroup>
          <CFormInput
            v-model="formData.owner_first_name"
            maxlength="255"
            placeholder="Enter Owner`s First Name"
            :invalid="!!validationErrors.owner_first_name"
            @blur="handleChangeField('owner_first_name', $event)"
          />
        </CInputGroup>
        <CFormText v-if="validationErrors.owner_first_name" style="color: red">
          {{ validationErrors.owner_first_name }}
        </CFormText>
      </CCol>
      <CCol :xs="12" :md="6" class="mb-4">
        <CFormLabel>Owner`s Last Name *</CFormLabel>
        <CInputGroup>
          <CFormInput
            v-model="formData.owner_last_name"
            maxlength="255"
            placeholder="Enter Owner`s Last Name"
            :invalid="!!validationErrors.owner_last_name"
            @blur="handleChangeField('owner_last_name', $event)"
          />
        </CInputGroup>
        <CFormText v-if="validationErrors.owner_last_name" style="color: red">
          {{ validationErrors.owner_last_name }}
        </CFormText>
      </CCol>
      <CCol :xs="12" :md="6" class="mb-4">
        <CFormLabel>Owner`s Email *</CFormLabel>
        <CInputGroup>
          <CFormInput
            v-model="formData.owner_email"
            maxlength="100"
            placeholder="Enter Owner`s Email"
            :invalid="!!validationErrors.owner_email"
            @blur="handleChangeField('owner_email', $event)"
          />
        </CInputGroup>
        <CFormText v-if="validationErrors.owner_email" style="color: red">
          {{ validationErrors.owner_email }}
        </CFormText>
      </CCol>
      <CCol :xs="12" :md="6" class="mb-4">
        <CFormLabel>Company Name *</CFormLabel>
        <CInputGroup>
          <CFormInput
            v-model="formData.company_name"
            maxlength="255"
            placeholder="Enter Company Name"
            :invalid="!!validationErrors.company_name"
            @blur="handleChangeField('company_name', $event)"
          />
        </CInputGroup>
        <CFormText v-if="validationErrors.company_name" style="color: red">
          {{ validationErrors.company_name }}
        </CFormText>
      </CCol>
      <CCol :xs="12" :md="6" class="mb-4">
        <CFormLabel>Company Tax ID *</CFormLabel>
        <CInputGroup>
          <CFormInput
            v-model="formData.company_tax_id"
            v-mask="['###-#######']"
            placeholder="Enter Company Tax ID"
            :invalid="!!validationErrors.company_tax_id"
            @blur="handleChangeField('company_tax_id', $event)"
          />
        </CInputGroup>
        <CFormText v-if="validationErrors.company_tax_id" style="color: red">
          {{ validationErrors.company_tax_id }}
        </CFormText>
      </CCol>
      <CCol :xs="12" :md="6" class="mb-4">
        <CFormLabel>Website *</CFormLabel>
        <CInputGroup>
          <CFormInput
            v-model="formData.company_website"
            maxlength="100"
            placeholder="Enter Website"
            :invalid="!!validationErrors.company_website"
            @blur="handleChangeField('company_website', $event)"
          />
        </CInputGroup>
        <CFormText v-if="validationErrors.company_website" style="color: red">
          {{ validationErrors.company_website }}
        </CFormText>
      </CCol>
      <CCol :cols="12">
        <CFormLabel>Role</CFormLabel>
        <div class="d-flex radio-wrap flex-wrap">
          <CFormCheck
            id="general_contractor"
            :key="'general_contractor' + formData.company_type"
            type="radio"
            name="general_contractor"
            label="General contractor"
            :checked="formData.company_type === 'general_contractor'"
            @click="formData.company_type = 'general_contractor'"
          />
          <CFormCheck
            id="sub_contractor"
            :key="'sub_contractor' + formData.company_type"
            type="radio"
            name="sub_contractor"
            label="Subcontractor"
            :checked="formData.company_type === 'sub_contractor'"
            @click="formData.company_type = 'sub_contractor'"
          />
        </div>
      </CCol>
    </CRow>
  </teleport>
</template>

<script>
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import { parseError } from '@/utils/api'
import { mask } from 'vue-the-mask'
import modalComponent from '@/mixin/modalComponent'
import { mapActions } from 'vuex'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import {equalObjectsByKeys} from "../../../utils/helper";

export default {
  directives: { mask },
  components: { ConfirmDialog },
  mixins: [modalComponent],
  inject: ['toast'],
  created() {
    this.setRules()
  },
  data: () => ({
    busyData: false,
    origin: {},
    formData: {
      owner_first_name: '',
      owner_last_name: '',
      owner_email: '',
      company_name: '',
      company_tax_id: '',
      company_website: '',
      company_type: 'sub_contractor',
    },

    validator: {},
    validationErrors: {},
  }),
  methods: {
    ...mapActions(['loginSuperAdminCompany']),
    async login(id) {
      await this.loginSuperAdminCompany({ company_id: id })
        .then(() => this.$router.push({ name: 'ProjectsList' }))
        .catch(() => {
          this.$router.push({ name: 'SuperAdminCompany' })
          this.toast('error', 'Error login to Company ID: ' + id)
        })
    },
    clear() {
      this.formData = {
        owner_first_name: '',
        owner_last_name: '',
        owner_email: '',
        company_name: '',
        company_tax_id: '',
        company_website: '',
        company_type: 'sub_contractor',
      }
      this.validationErrors = {}
      this.origin = JSON.parse(JSON.stringify(this.formData))
      this.setRules()
    },
    handleChangeField(field, event) {
      this.validationErrors[field] = this.validator.validate(
        field,
        event.target.value,
      )
    },
    setRules() {
      this.validator = validator({
        owner_first_name: [rules.required],
        owner_last_name: [rules.required],
        owner_email: [rules.required, rules.email, rules.strMax(255)],
        company_name: [rules.required],
        company_tax_id: [rules.required],
        company_website: [rules.required, rules.url],
      })
    },
    createAccountBtn() {
      if(!this.busyData) this.clear()
      this.createAccount()
    },
    createAccount() {
      setTimeout(() => {
        document.querySelector('.modalComponent')
          .getElementsByClassName('btn-primary')[0].style.width = 'auto'
      }, 50)
      this.$modal.open({
          success: 'Send invite and go to account',
          autoClose: false,
          close: 'Close',
          size: 'lg',
          label: 'Create new account in Contracts Connected',
          sublabel: 'The SuperAdmin can create an account for a new company and prefill the company`s information if needed.',
          slot: 'form-create',
        })
        .then(async () => {
          this.createAccount()
          const validationResult = this.validator.validateAll(this.formData)
          if (!validationResult.hasErrors) {
            this.$modal.loading(true)
            await this.$http.superadmin.superuserAccountCreate(this.formData)
              .then(async (res) => {
                this.$modal.loading(false)
                await this.login(res.data.id)
                this.$modal.close()
                this.toast('success', 'The invite has been sent successfully')
              })
              .catch((errors) => {
                this.$modal.loading(false)
                let err = parseError(errors)
                if (err.status === 422) this.validationErrors = err.validationMessages
                else this.toast('error', err.message) // err.message || errors.response.data
              })
          } else {
            this.validationErrors = validationResult.validationErrors
          }
        }).catch(() => this.cancel())
    },
    async cancel() {
      if (!equalObjectsByKeys(this.formData, this.origin)) {
        this.busyData = true
        await this.$refs.confirmDialog
          .confirm({
            text: 'Unsaved changes will be lost. <br>Do you really want to cancel?',
            backdrop: 'static',
            reverse: true,
          })
          .then((res) => {
            if (!res) this.createAccount()

            this.busyData = false
          })
      }
    },
  }
}
</script>
<style>
.toaster {
  z-index: 9999999999999 !important;
}
</style>
<style lang="scss" scoped>
.create {
  &__btn {
    margin-left: 15px;

    @media (max-width: 767px) {
      margin-left: auto;
      margin-right: 0;
    }

    @media (max-width: 450px) {
      width: 100%;
    }
  }
}
.radio-wrap .form-check {
  margin-right: 10px;
}
</style>

<style lang="scss">
@media (max-width: 768px) {
  :global(.modalComponent-btn__wrap) {
    display: block !important;

    .modalComponent-btn:first-child {
      width: 100% !important;
      margin: 0 0 20px 0 !important;
    }
    .modalComponent-btn:last-child {
      width: 100% !important;
    }
  }
}

:global(.modalComponent-btn:last-child) {
  width: 200px !important;
  border: 10px solid green !important;
}
</style>
