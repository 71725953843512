import CPagination from '@/components/CPagination'
import onTimeoutSearch from '@/mixin/searchTimeout'

export default {
  components: { CPagination },
  mixins: [onTimeoutSearch],
  mounted() {
    this.$nextTick(() => {
      this.applyPrefiltering()
      this.fetchData && this.fetchData()
    })
  },
  data() {
    return {
      key: 0,
      activePage: 1,
      pages: 1,
      perPage: '10',
      sort: { column: '', state: ''},
      search: '',
      searchDate: {},
      loadingTable: false,
      selected: []
    }
  },
  computed: {
    eventChangeSearchDate() {
      return JSON.stringify(this.searchDate)
    }
  },
  watch: {
    pages() {
      if (this.activePage > this.pages) {
        this.activePage = this.activePage - 1
      }
    },
    perPage() {
      this.activePage = 1
      this.fetchData && this.fetchData()
    },
    activePage() {
      if (this.activePage > this.pages) {
        this.activePage = this.pages
      } else if (this.activePage <= 0) {
        this.activePage = 1
      } else {
        this.fetchData && this.fetchData()
      }
    },
    search() {
      this.fetchData && this.onTimeoutSearch(() => this.fetchData())
    },
    eventChangeSearchDate() {
      this.fetchData && this.fetchData()
    }
  },
  methods: {
    onSort(sort) {
      this.sort = sort
      this.fetchData && this.fetchData()
    },
    applyPrefiltering(){
      if (
        this.$route.query &&
        this.$route.query.prefilter &&
        Object.keys(this.$route.query).some(param => param.match(/^filter\[.+\]$/))
      ) {
        const query = this.$route.query
        Object.keys(this.$route.query).filter(param => param.match(/^filter\[.+\]$/))
          .forEach(key => {
            let val = query[key];
            let normalizedKey = key.replace('filter[', '').replace(']', '')
            if (this[normalizedKey] !== undefined){
              this[normalizedKey] = val
            }
          })
      }
    }
  }
}
