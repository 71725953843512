<template>
  <CFormLabel v-if="$slots.label"><slot name="label" /></CFormLabel>
  <CInputGroup :class="class">
    <CInputGroupText v-if="$slots.prependContent"><slot name="prepend-content"/></CInputGroupText>
    <slot v-if="$slots.input" name="input" />
    <CFormInput
      v-else
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :type="type"
      :invalid="!!invalidFeedback"
      :value="modelValue"
      @input="handleChangeField"
    />
    <CFormFeedback invalid v-if="$slots.invalidFeedback">
      <slot name="invalid-feedback"/>
    </CFormFeedback>
  </CInputGroup>
</template>

<script>
export default {
  emits: ['input', 'update:modelValue'],
  props: {
    invalidFeedback: {},
    addInputClasses: {},
    placeholder: {},
    autocomplete: {},
    type: {},
    class: {},
    modelValue: {}
  },
  methods: {
    handleChangeField(event) {
      this.$emit('input', event)
      this.$emit('update:modelValue', event.target.value)
    }
  }
}
</script>
