<template>
  <CCol class="pc-hide" style="text-align: center">
    <span class="mx-2"> no search results found </span>
  </CCol>
</template>

<script>
export default {
  name: 'AccordionEmptyTable',
}
</script>

<style scoped></style>
