import Gate from '@/services/Gate/Gate'
import router from '../index'
import store from '../../store'
import taskStatuses from '@/utils/constants/taskStatuses'

export const canCreate = async ({ to }) => {
  await Gate.can('taskCreate', 'project', to.params.id).then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}

export const canReviewAndApprove = async ({ to }) => {
  await Gate.can('reviewAndApproveView', 'task', to.params.task_id).then(
    async (res) => {
      if (!res) await router.replace({ name: 'PermissionsError' })
      return res
    },
  )
}

export const canSendForSignature = async ({ to }) => {
  await Gate.can('sendForSignatureView', 'task', to.params.task_id).then(
    async (res) => {
      if (!res) await router.replace({ name: 'PermissionsError' })
      return res
    },
  )
}

export const canSignView = async ({ to }) => {
  await Gate.can('signView', 'task', to.params.task_id).then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })

    let entity = store.state.tasks.entity
    if(entity.is_external && entity.status === taskStatuses.CONTRACT_PENDING_INTERNAL_SIGNATURE && to.name === 'TaskSign'){
      router.replace({ name: 'TaskFinish', params: to.params })
    }

    return res
  })
}

export const canFinishView = async ({ to }) => {
  await Gate.can('finishView', 'task', to.params.task_id).then(
    async (res) => {
      if (!res) await router.replace({ name: 'PermissionsError' })
      return res
    },
  )
}

export const canView = async ({ to }) => {
  await Gate.can('view', 'task', to.params.task_id).then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}
