<template>
  <Popper
    ref="popper"
    :disabled="disable"
    :content="content"
    :hover="true"
    :placement="placement"
    :arrow="arrow"
    open-delay="500"
    :offset-distance="`${offsetDistance}`"
    :style="style"
    @open:popper="handleOpen"
    @close:popper="handleClose"
  >
    <slot />
  </Popper>
</template>

<script>
import Popper from 'vue3-popper'

export default {
  components: {
    Popper,
  },
  props: {
    content: {},
    offsetDistance: { type: Number, default: 0 },
    arrow: { type: Boolean, default: false },
    hide: { type: Boolean, default: false },
    placement: { type: String, default: 'bottom' },
    style: { default: '' },
  },
  data() {
    return {
      disable: this.hide,
      opened: false,
    }
  },
  watch: {
    hide(to) {
      this.disable = to
    },
  },
  created() {
    //TODO: extract listener to reduce listeners amount
    window.addEventListener('poppers:close', this.handleExternalClose)
  },
  unmounted() {
    window.removeEventListener('poppers:close', this.handleExternalClose)
  },
  methods: {
    handleOpen() {
      this.opened = true
    },
    handleClose() {
      this.opened = false
    },
    handleExternalClose() {
      if (this.opened) {
        this.disable = true
        setTimeout(() => {
          this.disable = false
        }, 50)
      }
    },
  },
}
</script>

<style lang="scss">
.popper {
  font-size: 12px;
  z-index: 99999 !important;
}
:root {
  --popper-theme-background-color: #333333;
  --popper-theme-background-color-hover: #333333;
  --popper-theme-text-color: #ffffff;
  --popper-theme-text-size: 10px;
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 5px 10px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
