import {asFormData, asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    getTask: (data) => asJson(axios).get(getVerUri('task'), data),
    fetchTasks: (data) => asJson(axios).get(getVerUri('task/list'), data),
    getTasksKanban: (data) => asJson(axios).get(getVerUri('task/dashboard'), data),
    editTasks: (data) => asJson(axios).post(getVerUri('task/edit'), data),
    skip: (id) => asJson(axios).post(getVerUri('task/skip/' + id)),
    stopRecurrence: (id) => asJson(axios).put(getVerUri('task/recurrence/' + id + '/stop')),
    updateRecurrence: (id, data) => asJson(axios).put(getVerUri('task/recurrence/' + id + '/update'), data),
    editTasksMass: (data) => asJson(axios).post(getVerUri('task/bulk-edit'), data),
    downloadTaskAttachment: (data) => asJson(axios).post(getVerUri('task/attachment/download/'), data, {responseType: 'blob'}),
    setSigners: (data) => asJson(axios).post(getVerUri('scope/set-signers'), data),
    getAssignTasks: (data) => asJson(axios).get(getVerUri('scope/assign-tasks'), data),
    getShortcodes: (data) => asJson(axios).get(getVerUri('scope/shortcodes'), data),
    confirmShortcodes: (data) => asJson(axios).post(getVerUri('scope/confirm-shortcodes'), data),
    prefillEmailTemplate: (data) => asJson(axios).get(getVerUri('scope/prefill-email'), data),
    getScheduledTasks: (data) => asJson(axios).get(getVerUri('scope/scheduled-tasks'), data),
    previewDataScheduleTasksArchive: (data) => axios({
        method: 'GET',
        url: getVerUri('scope/scheduled-tasks/check-before-download'),
        params: data,
    }),
    downloadScheduleTasksArchive: (data) => axios({
        method: 'GET',
        url: getVerUri('scope/scheduled-tasks/download'),
        params: data,
        responseType: 'blob',
    }),
    addScheduleTasksToArchive: (data) => asJson(axios).post(getVerUri('scope/scheduled-tasks/archivate'), null, {params: data}),
    taskPDFPreview: (data) => axios({
        method: 'GET',
        url: getVerUri('task/preview'),
        responseType: 'blob',
        params: data,
    }),
    sendNotifyTeam: (data) => asJson(axios).post(getVerUri('scope/notify-team'), data),
    setContractSigners: (data) => asJson(axios).post(getVerUri('contract/signers'), data),
    uploadDocument: (data) => asJson(axios).post(getVerUri('task/request-documents/upload-document'), data),
    approveDocument: (data) => asJson(axios).post(getVerUri('task/request-documents/approve-document'), data),
    submitForReviewDocument: (data) => asFormData(axios).post(getVerUri('task/request-documents/submit-for-review'), data),
    rejectDocument: (data) => asJson(axios).post(getVerUri('task/request-documents/reject-document'), data),
    deleteDocument: (documentId) => asJson(axios).delete(getVerUri('task/request-documents/delete-document?document_id=' + documentId)),
    downloadDocument: (data) => axios({
        method: 'GET',
        url: getVerUri('task/request-documents/download-document'),
        responseType: 'blob',
        params: data,
    }),
    finalize: (data) => asJson(axios).post(getVerUri('task/finalize'), data),
    createTaskSetting: (data) => asJson(axios).post(getVerUri('settings/template/task-settings/create'), data),
    updateOrCreateAllTemplateTaskSettings: (data) => asJson(axios).post(getVerUri('settings/template/task-settings/update-or-create-all'), data),
    confirmAcknowledge: (data) => asJson(axios).post(getVerUri('scope/acknowledge/confirm'), data),
    addNewTemplate: (data) => asJson(axios).post(getVerUri('scope/add-new-template'), data),
    cancelTemplateChanges: (data) => asJson(axios).post(getVerUri('scope/cancel-changes'), data),
    updateTasks: (data) => asJson(axios).post(getVerUri('scope/update-tasks'), data),
    revokeTask: taskId => asJson(axios).post(getVerUri('task/revoke/' + taskId))
})
