<template>
  <CButton
    class="d-block d-lg-none"
    variant="ghost"
    color="link"
    @click="showModal = !showModal"
  >
    <CIcon name="cilSearch" />
  </CButton>
  <CCol v-if="!showModal" class="d-none d-lg-flex">
    <SearchForm
      v-model="search"
      input-id="headerInput"
      :loading="loading"
      @search="handleSearch"
    />
  </CCol>

  <CModal
    class="modal-fullscreen modal-fullscreen-xxl-down searchResultsDialog"
    :visible="showModal"
    :backdrop="false"
    @close="close"
    @show="show"
  >
    <CModalHeader>
      <CCol class="modalSearch" xs="10" lg="4" xl="3">
        <SearchForm
          v-model="search"
          input-id="modalInput"
          :loading="loading"
          @search="handleSearch"
        />
      </CCol>
    </CModalHeader>
    <CModalBody>
      <CRow :xs="{ gutterY: 4 }" class="searchResults">
        <CAccordion :active-item-key="0" always-open>
          <AccordionItem
            v-for="(item, index) in results"
            :key="index"
            :item-key="index"
          >
            <template #header>
              <TableTitle
                v-if="item.type"
                :icon="types[item.type]['icon']"
                :title="types[item.type]['title']"
                :total="item.total"
                :count="item.count"
              />
            </template>
            <CCol class="mb-2">
              <Projects
                v-if="item.type === 'projects'"
                class="mb-3"
                :items="item"
                :search="search"
              />
              <Tasks
                v-if="item.type === 'tasks'"
                :search="search"
                :items="item"
                class="mb-3"
              />
              <Companies
                v-if="item.type === 'companies'"
                :items="item"
                :search="search"
                class="mb-3"
              />
              <Users
                v-if="item.type === 'users'"
                :search="search"
                :items="item"
                class="mb-3"
              />
              <Documents
                v-if="item.type === 'documents'"
                :items="item"
                :search="search"
                class="mb-3"
              />
              <KnowledgeBase
                v-if="item.type === 'knowledge_base'"
                :items="item"
                :search="search"
                class="mb-3"
              />
            </CCol>
          </AccordionItem>
        </CAccordion>
      </CRow>
    </CModalBody>
  </CModal>
</template>

<script src="./index.js"></script>

<style lang="scss">
@import 'style';
</style>
