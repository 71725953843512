<template>
  <VTooltip
    popper-class="c-overdue-tooltip"
    placement="top-start"
    :arrow-padding="15"
    :skidding="-30"
    :distance="5"
  >
    <CIcon class="c-overdue-tooltip__icon" name="cilCircleInfo" />

    <template #popper>
      <div class="c-overdue-tooltip__content">Overdue: {{ overdueDate }}</div>
    </template>
  </VTooltip>
</template>

<script>
export default {
  name: 'COverdueTooltip',
  props: {
    overdueDate: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.c-overdue-tooltip {
  &.v-popper__popper.v-popper--theme-tooltip {
    .v-popper__inner {
      background-color: #c7000d;
    }
  }
  &.v-popper--theme-tooltip .v-popper__arrow-outer {
    border-color: #c7000d;
  }
}
</style>

<style scoped lang="scss">
.c-overdue-tooltip {
  $parent: &;

  &__content {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: #ffffff;
  }

  &__icon {
    color: #ff1f26;
    cursor: pointer;

    &:hover {
      #{$parent} {
        &__content {
          display: block;
        }
      }
    }
  }
}
</style>
