import { Project } from './Type/Project'
import { Task } from './Type/Task'
import { Scope } from './Type/Scope'
import { Company } from './Type/Company'
import { Employee } from './Type/Employee'
import { Note } from './Type/Note'
import { Setting } from './Type/Setting'
import { Template } from './Type/Template'
import { Dashboard } from './Type/Dashboard'

export class Gate {
  getType(type = '') {
    switch (type) {
      case 'project':
        return new Project()
      case 'task':
        return new Task()
      case 'scope':
        return new Scope()
      case 'company':
        return new Company()
      case 'employee':
        return new Employee()
      case 'note':
        return new Note()
      case 'setting':
        return new Setting()
      case 'template':
        return new Template()
      case 'dashboard':
        return new Dashboard()
      default:
        throw 'Gate type not found'
    }
  }

  async can(action = '', type = '', param = undefined) {
    type = this.getType(type)

    if (!type) return false

    return type[action] ? await type[action](param) : false
  }
}

export default new Gate()
