<template>
    <div :class="wrapperClasses" :style="wrapperStyles">
        <div class="logo-input__preview" :style="previewStyles">
            <Avatar v-if="avatarUrl || displayName.length" :src="avatarUrl" :rounded="previewRounded" :size="size"
                    :display-name="displayName"/>
            <span v-else>
                <img v-if="isCompanyLogo" :style="{ width: `${size}px`, height: `${size}px`, objectFit: 'cover' }"
                     src="@/assets/company-no-image.png"/>
                <img v-else src="@/assets/image.png"/>
            </span>
            <input v-if="enabled" :id="inputId" ref="fileInput" type="file" accept=".jpg,.jpeg,.png"
                   @change="handleChangeFile"/>
        </div>
        <avatar-cropper v-bind="options" ref="cropper" v-model="showCropper"
                        :class="{ 'square-cropper': !previewRounded }" mimes="image/png, image/jpeg"
                        :labels="{ submit: 'Save', cancel: 'Cancel' }" :upload-handler="customUploader"/>
        <label :for="inputId" class="logo-input__label">
            <CIcon v-if="enabled" name="cilPen"/>
            <span v-if="enabled">
                {{ uploadText }}
                <CSpinner v-if="uploading" size="sm"/>
            </span>
        </label>
        <slot name="error"/>
    </div>
</template>

<script>
import {getFileUrl} from '@/utils/files'
import AvatarCropper from 'vue-avatar-cropper'
import Avatar from "@/components/Avatar.vue";

export default {
    name: 'LogoInput',
    components: {Avatar, AvatarCropper},
    props: {
        size: {
            type: Number,
            default: 100,
            validator(val) {
                return val > 10 && val < 3000
            },
        },
        vertical: Boolean,
        classes: [String, Array, Object],
        imageUrl: String,
        uploading: {
            type: Boolean,
            default: false,
        },
        enabled: {
            type: Boolean,
            default: true,
        },
        isCompanyLogo: {
            type: Boolean,
            default: false,
        },
        uploadText: {
            type: String,
            default: 'Upload Photo',
        },
        previewRounded: {
            type: Boolean,
            default: true,
        },
        inputId: {
            type: String,
            default: 'logoFileInput'
        },
        displayName: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            showCropper: false,
            url: null,
        }
    },
    computed: {
        avatarUrl() {
            return this.url || (this.imageUrl + '?time=' + new Date().getTime());
        },
        options() {
            return this.previewRounded ? {} : {'cropper-options': {viewMode: 1}}
        },
        wrapperClasses() {
            return [
                'logo-input',
                {'logo-input-vertical': this.vertical},
                this.classes,
            ]
        },
        wrapperStyles() {
            return this.vertical ? {height: `${this.size}px`} : {width: `${this.size}px`};
        },
        previewStyles() {
            const styles = {
                width: `${this.size}px`,
                height: `${this.size}px`,
                'border-radius': this.previewRounded ? '200px' : 'unset',
            }
            if (this.url || this.displayName.length) {
                styles.background = 'none'
            }
            return styles
        },
    },
    methods: {
        customUploader(cropper) {
            cropper.getCroppedCanvas({width: 512, height: 512})
                .toBlob(async (blob) => {
                    this.$emit('input', {target: {value: blob}})
                    if (blob) {
                        getFileUrl(blob, (url) => {
                            this.url = url
                        });
                    } else {
                        this.url = null
                    }
                }, 'image/png', 1)
        },
        handleChangeFile(e) {
            if (e.target.files[0]) {
                const file = e.target.files[0]

                this.$nextTick(() => {
                    this.$refs.cropper.onFileChange(file)
                })
            } else {
                this.$emit('input', {target: {value: null}})
            }

            e.target.value = null
        },
    },
}
</script>

<style scoped lang="scss">
:deep(.avatar-cropper-close) {
    display: none;
}

:deep(.avatar-cropper-container) {
    border-radius: 5px;

    .cropper-crop-box,
    .cropper-view-box {
        border-radius: 50%;
    }

    .avatar-cropper-footer {
        display: flex;
        justify-content: center !important;

        .avatar-cropper-btn {
            &:first-child {
                background-color: white !important;
                color: black !important;
            }

            &:first-child,
            &:last-child {
                width: auto !important;
                padding: 0.375rem 0.75rem !important;
                margin: 10px 15px;
                display: inline-block;
                font-weight: 400;
                line-height: 1.5;
                color: var(--cui-btn-color, rgba(44, 56, 74, 0.95));
                text-align: center;
                text-decoration: none;
                vertical-align: middle;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                background-color: var(--cui-btn-bg, transparent);
                border: 1px solid var(--cui-btn-border-color, transparent);
                font-size: 1rem;
                border-radius: 0.25rem;
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }

            &:first-child {
                margin-left: 1rem;
            }
        }

        .avatar-cropper-btn + .avatar-cropper-btn {
            background-color: #00548d;
            color: white;

            &:hover {
                background-color: #00487b;
                box-shadow: 0 0 3px #00487b;
            }
        }
    }
}

:deep(.square-cropper) {
    .cropper-crop-box,
    .cropper-view-box {
        border-radius: 0;
    }
}

.logo-input__label {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #0081C2;
    cursor: pointer;

    & span {
        border-bottom: 1px solid;
    }

    &:hover {
        color: #01547d;
    }
}

.logo-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &__preview {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        border-radius: 50%;
        background: #ebedef;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        cursor: pointer;
        overflow: hidden;
    }

    input[type='file'] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;

        &::-webkit-file-upload-button {
            cursor: pointer;
        }
    }

    &.logo-input-vertical {
        flex-direction: row;
        width: 100%;

        .logo-input__label {
            margin-left: 20px;
        }

        .logo-input__preview {
            margin-bottom: 0;
        }
    }

    &.is-invalid {
        .logo-input__preview {
            border-color: #e55353;
        }
    }
}
</style>
