<template>
  <div class="p-0">
    <h4 style="margin-left: 30px">User’s last activity</h4>
    <SmartTable
      :name="'table-setting-account-company-details-activity'"
      :items="activities"
      :columns="activitiesFields.map((i) => ({ ...i, sorter: true }))"
      :sorter-value="sort"
      infinity
      @sorter-change="onSort"
    >
      <template #last_activity="{ item }">
        <div class="d-flex">
          {{
            item.last_activity ? $BtoF({ value: item.last_activity }) : 'N/A'
          }}
        </div>
      </template>
    </SmartTable>
  </div>
</template>

<script>
import table from '@/mixin/table'
export default {
  name: 'ActivityTable',
  mixins: [table],
  data() {
    return {
      activities: [],
      loading: false,
      activitiesFields: [
        {
          key: 'user_name',
          label: 'User name',
          _style: '',
          // link: {
          //   name: 'CompanyUser',
          //   params: { id: 'company_id', user_id: 'user_id' },
          //   iconNewTab: false,
          // },
        },
        { key: 'user_role', label: 'Role' },
        {
          key: 'last_activity',
          label: 'Last log in',
          format: (value) => this.$BtoF({ value }),
        },
      ],
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      const params = {}

      if (this.sort && this.sort.column) {
        params.sort_field = this.sort && this.sort.column
      }

      if (this.sort && this.sort.state) {
        params.sort_direction = this.sort && this.sort.state
      }

      this.$http.superadmin
        .companyDetailsActivity(this.$route.params.id, {
          params
        })
        .then((res) => (this.activities = res.data.data))
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style scoped></style>
