import ApiService from '@/services/ApiService'
import Gate from '@/services/Gate/Gate'

export default {
  state: {
    entity: {},
    permissions: {
      view: false,
      create: false,
      edit: false,
      delete: false,
      chat: false,
    },
  },
  getters: {
    scopePermissions: (state) => state.permissions,
  },
  mutations: {
    setScopeEntity: (state, data) => (state.entity = data),
    setScopePermissions: async (state) => {
      state.permissions = {
        view: await Gate.can('view', 'scope', state.entity.id),
        create: await Gate.can('create', 'scope'),
        edit: await Gate.can('edit', 'scope', state.entity.id),
        delete: await Gate.can('delete', 'scope', state.entity.id),
        chat: await Gate.can('chat', 'scope', state.entity.id),
      }
    },
  },
  actions: {
    fetchScopePermissions({ commit }, id) {
      commit('setScopeEntity', {})
      return new Promise((resolve, reject) => {
        ApiService.api.app
          .getModelPermissions('scope', id)
          .then((res) => {
            commit('setScopeEntity', res.data.data)
            commit('setScopePermissions')
            resolve(res.data.data)
          })
          .catch((err) => {
            commit('setScopeEntity', {})
            commit('setScopePermissions')
            resolve({})
            reject(err)
          })
      })
    },
  },
}
