import {getData} from './utils/DataHandler';
import BaseConfigs from './BaseConfigs';

export default class BaseMethods extends BaseConfigs {
    async index(params) {
        return this.asJson().get(this.endpointRoute, {params}).then(getData);
    }

    async store(data) {
        return this.asJson().post(this.endpointRoute, data).then(getData);
    }

    async show(id, params) {
        const url = this.endpointRoute + '/' + id;

        return this.asJson().get(url, {params}).then(getData);
    }

    async update(id, data) {
        const url = this.endpointRoute + '/' + id;

        return this.asJson().put(url, data).then(getData);
    }

    async destroy(id) {
        const url = this.endpointRoute + '/' + id;

        return this.asJson().delete(url).then(getData);
    }
}
