import { Type } from './Type'

export class Template extends Type {
  async #checkAndFetchEntity(id = undefined) {
    let entityId = super.store().state.template?.entity?.id
    if (id && parseInt(entityId) !== parseInt(id)) {
      await super.store().dispatch('template/fetchTemplatePermissions', id)
    }
  }

  async list() {
    return super.auth().can(super.permissions().TEMPLATES_VIEW)
  }

  async create() {
    return super.auth().can(super.permissions().TEMPLATES_CREATE)
  }

  async view(id) {
    if (!id) {
      super.store().commit('setTemplateEntity', {})
      return false
    }

    await this.#checkAndFetchEntity(id)
    return super.store().state.template.entity.view
  }

  async edit() {
    return super.auth().can(super.permissions().TEMPLATES_EDIT)
  }

  async delete() {
    return super.auth().can(super.permissions().TEMPLATES_DELETE)
  }
}
