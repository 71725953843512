// import permissions from '@/utils/constants/permissions'

export default [
  {
    component: 'CNavItem',
    name: 'Companies',
    to: { name: 'SuperAdminCompany' },
    icon: 'cilFactory',
  }
]
