<template>
  <CRow :key="key" :xs="{ cols: 1, gutter: 3 }" class="text-dark GCFilter_SuperAdminCompany">
    <CCol>
      <CRow>
        <CFormLabel class="col-row-label col-form-label">Company Name</CFormLabel>
        <CCol class="col-row-value">
          <CFormInput v-model="form.company_name" placeholder="Enter the company name" />
        </CCol>
      </CRow>
    </CCol>
    <CCol>
      <CRow>
        <CFormLabel class="col-row-label col-form-label">Owner Name</CFormLabel>
        <CCol class="col-row-value col-xxl-10">
          <CFormInput v-model="form.owner_name" placeholder="Enter the owner name" />
        </CCol>
      </CRow>
    </CCol>

    <CCol>
      <CRow>
        <CFormLabel class="col-row-label col-form-label">Creation Date</CFormLabel>
        <CCol class="col-row-value">
          <CRow>
            <CCol class="col-6">
              <CInputDate v-model="form.creation_date_from" :end="form.creation_date_to ? form.creation_date_to : null" clearable type="clear" placeholder="Start date" />
            </CCol>
            <CCol class="col-6">
              <CInputDate v-model="form.creation_date_to" :start="form.creation_date_from ? form.creation_date_from : null" clearable type="clear" placeholder="End date" />
            </CCol>
          </CRow>
        </CCol>

      </CRow>
    </CCol>


    <CCol>
      <CRow :xs="{ cols: 1, gutterY: 3 }" :lg="{ cols: 3 }">
        <CCol>
          <CRow>
            <CFormLabel class="col-row-label col-form-label">Users:</CFormLabel>
            <CCol class="col-row-value">
              <CRow class="align-items-center">
                <CCol>
                  <CFormInput v-model="form.users_count_from" class="p-lg-0 text-lg-center" type="number" placeholder="Min" @blur="checkRange('users_count_from', 'users_count_to', 'max')" />
                </CCol>
                <CCol class="col-auto p-0">-</CCol>
                <CCol>
                  <CFormInput v-model="form.users_count_to" class="p-lg-0 text-lg-center" type="number" placeholder="Max" @blur="checkRange('users_count_to', 'users_count_from', 'min')" />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
        <CCol>
          <CRow>
            <CFormLabel class="col-row-label col-form-label">Projects:</CFormLabel>
            <CCol class="col-row-value">
              <CRow class="align-items-center">
                <CCol>
                  <CFormInput v-model="form.projects_count_from" class="p-lg-0 text-lg-center"  placeholder="Min" @blur="checkRange('projects_count_from', 'projects_count_to', 'max')" />
                </CCol>
                <CCol class="col-auto p-0">-</CCol>
                <CCol>
                  <CFormInput v-model="form.projects_count_to" class="p-lg-0 text-lg-center"  placeholder="Max" @blur="checkRange('tasks_max', 'projects_count_to', 'min')" />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
        <CCol>
          <CRow>
            <CFormLabel class="col-row-label col-form-label">Contracts:</CFormLabel>
            <CCol class="col-row-value">
              <CRow class="align-items-center">
                <CCol>
                  <CFormInput v-model="form.contracts_count_from" class="p-lg-0 text-lg-center" type="number" placeholder="Min" @blur="checkRange('contracts_count_from', 'contracts_count_to', 'max')" />
                </CCol>
                <CCol class="col-auto p-0">-</CCol>
                <CCol>
                  <CFormInput v-model="form.contracts_count_to" class="p-lg-0 text-lg-center" type="number" placeholder="Max" @blur="checkRange('contracts_count_to', 'contracts_count_from', 'min')" />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCol>

    <CCol>
      <CRow>
        <CFormLabel class="col-row-label col-form-label">Last activity</CFormLabel>
        <CCol class="col-row-value">
         <CRow>
           <CCol class="col-6">
             <CInputDate v-model="form.last_activity_from" :end="form.last_activity_to ? form.last_activity_to : null" clearable type="clear" placeholder="Start date" />
           </CCol>
           <CCol class="col-6">
             <CInputDate v-model="form.last_activity_to" :start="form.last_activity_from ? form.last_activity_from : null" clearable type="clear" placeholder="End date" />
           </CCol>
         </CRow>
        </CCol>

      </CRow>
    </CCol>

  </CRow>
</template>

<script>
import mixin from './mixin'
export default {
  name: 'Company',
  mixins: [mixin],
  data() {
    return {
      form: {
        company_name: '',
        owner_name: '',
        creation_date_from: null,
        creation_date_to: null,
        users_count_from: null,
        users_count_to: null,
        projects_count_from: null,
        projects_count_to: null,
        contracts_count_from: null,
        contracts_count_to: null,
        last_activity_from: '',
        last_activity_to: '',
      },
      types: [
        { value: 'general_contractor', text: 'General Contractor' },
        { value: 'sub_contractor', text: 'Sub Contractor' },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => this.setOrigin())
  },
  methods: {
    getBadgeItems(outForm = undefined) {
      const form = outForm ? outForm : this.form
      return [
        { label: 'Company', value: form.company_name, key: ['company_name'] },
        { label: 'Owner', value: form.owner_name, key: ['owner_name'] },
        { label: 'Creation', value: this.valueDateRange(form.creation_date_from, form.creation_date_to), key: ['creation_date_from', 'creation_date_to'] },
        { label: 'Users', value: this.valueRange(form.users_count_from, form.users_count_to), key: ['users_count_from', 'users_count_to'] },
        { label: 'Projects', value: this.valueRange(form.projects_count_from, form.projects_count_to), key: ['projects_count_from', 'projects_count_to'] },
        { label: 'Contracts', value: this.valueRange(form.contracts_count_from, form.contracts_count_to), key: ['contracts_count_from', 'contracts_count_to'] },
        { label: 'Activity', value: this.valueDateRange(form.last_activity_from, form.last_activity_to), key: ['last_activity_from', 'last_activity_to'] },
      ]
    },
  },
}
</script>

<style lang="scss">
.GCFilter_SuperAdminCompany {
  position: relative;

  @media (min-width: 992px) {
    .col-row-label {
      width: 124px;
    }

    .col-row-value {
      width: calc(100% - 124px);
    }
  }
}
</style>
