<template>
  <CCol class="tableTitle">
    <h5>
      <CIcon :name="icon" />
      {{ title }}
    </h5>
    <span v-if="count" class="mx-2" style="padding-top: 0.1875rem">
      {{ count }} of {{ total }} results shown
    </span>
    <span v-else class="mx-2" style="padding-top: 0.1875rem">
      no search results found
    </span>
  </CCol>
</template>

<script>
export default {
  name: 'TableTitle',
  props: {
    icon: { type: String, default: '' },
    title: { type: String, default: '' },
    count: { type: Number, default: 0 },
    total: { type: Number, default: 0 },
  },
}
</script>

<style scoped>
.tableTitle span {
  font-size: 16px;
  font-weight: 400;
  line-height: 21.28px;
}

.tableTitle h5 {
  display: inline;
}
</style>
