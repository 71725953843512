<template>
  <div class="app-preloader">
    <base-preloader />
  </div>
</template>

<script>
  import BasePreloader from './BasePreloader'

  export default {
    name: 'AppPreloader',
    components: {
      BasePreloader,
    }
  }
</script>

<style scoped lang="scss">
  .app-preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(235, 237, 239, 0.5);
  }
</style>
