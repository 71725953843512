<template>
  <CRow :key="key" :xs="{ cols: 1, gutter: 3 }" class="text-dark">
    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-2 col-form-label">
          Status
        </CFormLabel>
        <CCol class="col-md-8 col-xxl-10">
          <MSelect
            v-model="form.status"
            :options="statuses"
            placeholder="Select Status"
            style="z-index: 9999999999 !important"
          />
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import mixin from './mixin'
export default {
  name: 'InvitedEmployees',
  mixins: [mixin],
  data() {
    return {
      form: {
        status: [],
      },
      statuses: [],
    }
  },
  async mounted() {
    await this.fetchStatuses()
    await this.$nextTick(() => this.setOrigin())
  },
  methods: {
    fetchStatuses() {
      this.$http.common
        .fetchReferenceBook({
          params: {
            only: 'invite_employee_statuses',
          },
        })
        .then((response) => {
          this.statuses =
            response.data.data?.invite_employee_statuses?.map((el) => ({
              value: el.key,
              text: el.title,
            })) || []
        })
    },
    getBadgeItems() {
      return [
        {
          label: 'Status',
          value: this.badgeBySelectOptions('status', 'statuses', false),
          key: ['status'],
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.RangeInput {
  width: 50px;
}
</style>
