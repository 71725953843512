import tippy from 'tippy.js'
import ShortcodesSuggestionsList from './ShortcodesSuggestionsList.vue'
import { createVNode, render, reactive } from 'vue'

/**
 * Destroy tippy and suggestions vNode instances
 */
const closeSuggestions = () => {
  window.shortcodeSuggestionDestroy?.()
  window.shortcodeSuggestionDestroy = null
  window.shortcodeSuggestionTippy?.destroy()
  window.shortcodeSuggestionTippy = null
}

/**
 * Options shared with shortcodes suggestions vue component
 */
const options = reactive({
  selectionStart: null,
  value: null,
  data: null,
  textBeforeCaret: null,
  textAfterCaret: null,
  query: null,
  focusIndexPosition: 0,
  insertShortcode: null,
  selectedShortcode: '',
  element: null,
})

/**
 * Insert selected shortcode into focused input/textarea. Shared options object used
*/
const insertShortcode = () => {
  const target = options.element
  if (!target) return
  const splittedTextBeforeCaret = options.textBeforeCaret.split('{{')
  if (options.query) {
    splittedTextBeforeCaret.pop()
  }
  const newInputValue = splittedTextBeforeCaret.join('{{') + `${options.query ? '{{' : ''}${options.selectedShortcode}}}` + options.textAfterCaret
  closeSuggestions()
  target.value = newInputValue
  const newCaretPosition = options.selectionStart + options.selectedShortcode.length + (options.query ? 2 - options.query.length : 2)
  target.focus()
  target.setSelectionRange(newCaretPosition, newCaretPosition)
  const event = new Event('input')
  target.dispatchEvent(event)
}

/**
 * Render vue component with shortcodes list
 */
const renderComponent = ({ el, component, props }) => {
  let vnode = createVNode(component, props)
  render(vnode, el)

  return {
    destroy: () => {
      render(null, el)
      vnode = undefined
    },
    vnode,
  }
}

/**
 * Render the shortcodes suggestions component, use tippy for positioning
 */
const showSuggestions = async (target, options) => {
  if (window.shortcodeSuggestionTippy) return
  options.element = target
  options.insertShortcode = insertShortcode
  const result = renderComponent({
    el: target.parentElement,
    component: ShortcodesSuggestionsList,
    props: { options },
  })
  window.shortcodeSuggestionDestroy = result.destroy
  window.shortcodeSuggestionTippy = tippy(target, {
    appendTo: () => document.body,
    content: result.vnode.el,
    showOnCreate: true,
    interactive: true,
    trigger: 'manual',
    placement: 'bottom-start',
  })
}

/**
 * Input events listener.
 */
const inputWatcher = (e) => {
  const { selectionStart, value } = e.target
  const { data } = e
  const textBeforeCaret = value ? value.slice(0, selectionStart) : ''
  const textAfterCaret = value ? value.slice(selectionStart) : ''
  const newOptions = {
    selectionStart,
    value,
    data,
    textBeforeCaret,
    textAfterCaret,
    query: null }

  if (textBeforeCaret.match(/(\S+)?{{([a-zA-Z_:\.-]+)?$/gm)){
    if (!window.shortcodeSuggestionTippy){
      showSuggestions(e.target, options)
    }
    const query = textBeforeCaret.split('{{').pop()

    if (query && query.length){
      Object.assign(newOptions, { query })
    }
  } else {
    closeSuggestions()
  }

  Object.assign( options, newOptions )
}

/**
 * Keydown events listener
 */
const keydownWatcher = (e) => {
  const { key } = e
  if (['ArrowDown', 'ArrowUp'].includes(key) && window.shortcodeSuggestionTippy){
    e.preventDefault()
    if ('ArrowDown' === key){
      options.focusIndexPosition--
    } else {
      options.focusIndexPosition++
    }
  }

  if ('Enter' === key && window.shortcodeSuggestionTippy){
    if (options.selectedShortcode ){
      e.preventDefault()
      insertShortcode()
    }
  }
}

/**
 * Directive declaration
 */
export const ShortcodesSuggestion = {
  async created(el) {
    el.oninput = inputWatcher
    el.onkeydown = keydownWatcher
  },
  beforeUnmount(el) {
    closeSuggestions()
    el.oninput = null
    el.onkeydown = null
  },
}
