<template>
  <CCol v-if="items.count > 0">
    <CButton color="link" class="w-link-all" @click="toAll()">
      See All
    </CButton>
  </CCol>
  <AccordionEmptyTable v-else />
</template>

<script>
import AccordionEmptyTable from './AccordionEmptyTable'
export default {
  name: 'Documents',
  components: { AccordionEmptyTable },
  props: {
    items: {
      type: Object,
      default: () => {
        return { data: [], total: 0 }
      },
    },
    search: { type: String, default: '' },
  },
  data() {
    return {
      columns: [],
    }
  },
  methods: {
    toAll() {
      if (this.$route.href === '/users') {
        window.location.href = '/users?search=' + this.search
      } else {
        this.$router.push({ name: 'Users', query: { search: this.search } })
      }
    },
  },
}
</script>

<style scoped></style>
