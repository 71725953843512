import { mapGetters } from 'vuex'

export default {
    inject: ['$modalComponent'],
    data:() => ({
      modalComponentName: Math.random().toString(16).slice(2)
    }),
    computed: {
      ...mapGetters(['teleportNameModalComponent', 'modalComponent']),
      teleportVisibleModalComponent() {
        return this.teleportNameModalComponent === this.modalComponentName
      },
      teleportVisibleModalComponentSlot() {
        return this.modalComponent?.slot
      },
      teleportVisibleModalComponentIcon() {
        return this.modalComponent?.icon
      },
      $modal() {
        return {
          ...this.$modalComponent,
          open: this.modalComponentOpen
        }
      }
    },
    methods: {
      modalComponentOpen(data) {
        return this.$modalComponent.open({
          ...data,
          name: this.modalComponentName
        })
      }
    }
}