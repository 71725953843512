<template>
  <CCol v-if="items.count > 0">
    <SmartTable
      :name="'search-table-users'"
      :items="items.data"
      :columns="columns.map((i) => ({ ...i, sorter: false }))"
      infinity
    >
    </SmartTable>
    <CButton color="link" class="w-link-all" @click="toAll()">
      See All
    </CButton>
  </CCol>
  <AccordionEmptyTable v-else />
</template>

<script>
import AccordionEmptyTable from './AccordionEmptyTable'
export default {
  name: 'Users',
  components: { AccordionEmptyTable },
  props: {
    items: {
      type: Object,
      default: () => {
        return { data: [], total: 0 }
      },
    },
    search: { type: String, default: '' },
  },
  data() {
    return {
      columns: [
        {
          key: 'full_name',
          label: 'Name',
          _style: { textAlign: 'center' },
          link: {
            name: 'CompanyUser',
            params: { id: 'company_id', user_id: 'id' },
          },
        },
        {
          key: 'company_name',
          label: 'Company Name',
          _style: { textAlign: 'center' },
          link: { name: 'Company', params: { id: 'id' } },
        },
        {
          key: 'projects_count',
          label: 'Projects #',
          _style: { textAlign: 'center' },
          link: {
            name: 'CompanyUserProjects',
            params: { id: 'company_id', user_id: 'id' },
          },
          format: (v) => `${v || 0}`,
        },
        {
          key: 'task_open_count',
          label: 'Task Open #',
          _style: { textAlign: 'center' },
          link: {
            name: 'CompanyUserTasks',
            params: { id: 'company_id', user_id: 'id' },
          },
          format: (v) => `${v || 0}`,
        },
        {
          key: 'task_overdue_count',
          label: 'Task Overdue #',
          _style: { textAlign: 'center' },
          link: {
            name: 'CompanyUserTasks',
            params: { id: 'company_id', user_id: 'id' },
          },
          format: (v) => `${v || 0}`,
        },
        {
          key: 'email',
          label: 'Email',
          _style: { textAlign: 'center' },
          email: true,
        },
        {
          key: 'phone',
          label: 'Phone',
          _style: { textAlign: 'center' },
        },
      ],
    }
  },
  methods: {
    toAll() {
      if (this.$route.href === '/users') {
        window.location.href = '/users?search=' + this.search
      } else {
        this.$router.push({ name: 'Users', query: { search: this.search } })
      }
    },
  },
}
</script>

<style scoped></style>
