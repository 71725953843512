import TheContainer from '@/layouts/SuperAdminLayout'
import Login from '@/views/SuperAdmin/Login'
import SignUp from '@/views/SuperAdmin/SignUp'
import Company from '@/views/SuperAdmin/CompanyList'
import CompanyDetails from '@/views/SuperAdmin/CompanyDetails'

import store from '../store'

export default [
  {
    path: '/superadmin-login',
    name: 'SuperadminLogin',
    component: Login,
  },
  {
    path: '/sign-up-owner',
    name: 'SuperadminSignUp',
    component: SignUp,
  },
  {
    path: '/',
    name: 'SuperAdminHome',
    component: TheContainer,
    redirect: '/superadmin/company',
    meta: {
      auth: true,
      superadmin: true,
      guard: async (route, authUser) => {
        if (authUser.company) {
          authUser.company = null
          store.commit('setUser', authUser)
          await store.dispatch('logoutSuperAdminCompany')
        }

        return true
      },
    },
    children: [
      {
        path: '/superadmin/company',
        name: 'SuperAdminCompany',
        component: Company,
        meta: { auth: true, superadmin: true },
      },
      {
        path: '/superadmin/company/:id',
        name: 'SuperAdminCompanyDetails',
        component: CompanyDetails,
        meta: { auth: true, superadmin: true, her: true },
      },
    ],
  },
]
