import moment from "moment/moment";

export const equalObjectsByKeys = (obj, origin, keys = undefined) => {
  keys = !keys ? Object.keys(origin) : keys
  for (let key of keys) {
    if (typeof obj[key] !== 'object' && obj[key] !== origin[key]) {
      return false
    }
  }
  return true
}
function isObject(object) {
  return object != null && typeof object === 'object'
}

export const isEqual = (obj1, obj2) => {
  var props1 = Object.getOwnPropertyNames(obj1)
  var props2 = Object.getOwnPropertyNames(obj2)
  if (props1.length != props2.length) {
    return false
  }
  for (var i = 0; i < props1.length; i++) {
    let val1 = obj1[props1[i]]
    let val2 = obj2[props1[i]]
    let isObjects = isObject(val1) && isObject(val2)
    if ((isObjects && !isEqual(val1, val2)) || (!isObjects && val1 !== val2)) {
      return false
    }
  }
  return true
}

export const snakeCase = (str) => {
  return str
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_')
}

export const hasRoutePermission = (route) => {
  return Array.isArray(route.permissions) && route.permissions.length
}

export const isEqualArrays = (a, b) => {
  if (!Array.isArray(a) || !Array.isArray(b)) return false
  if (a.length !== b.length) return false

  a.sort()
  b.sort()

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

export const parseErrorFromResponse = (errors) => {
  return Object.keys(errors).reduce((acc, error) => {
    if (acc[error]) return
    acc[error] = errors[error][0]

    return acc
  }, {})
}

export const convertDate = (date) => {
  return moment(date).format('YYYY-MM-DD')
}



