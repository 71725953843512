import app from './app'
import publicData from './public'
import auth from './auth'
import projects from './projects'
import users from './users'
import companies from './companies'
import setting from './setting'
import template from './template'
import emailTemplate from './emailTemplate'
import tasks from './tasks'
import offices from './offices'
import userProfiles from './userProfiles'
import topNav from './topNav'
import shortcodes from './shortcodes'
import library from './library'
import sendCommunication from './sendCommunication'
import contracts from './contracts'
import discussions from './discussions'
import chat from './chat'
import superadmin from './superadmin'
import common from './common'
import location from './location'
import scope from './scope'
import dashboard from './dashboard'
import onboarding from './onboarding'

export default {
  app,
  publicData,
  auth,
  projects,
  users,
  companies,
  setting,
  template,
  emailTemplate,
  tasks,
  offices,
  userProfiles,
  topNav,
  shortcodes,
  library,
  sendCommunication,
  contracts,
  discussions,
  chat,
  superadmin,
  common,
  location,
  scope,
  dashboard,
  onboarding
}
