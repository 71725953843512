import { Type } from './Type'

export class Employee extends Type {
  async #checkAndFetchEntity(id = undefined) {
    let entityId = super.store().state.users?.entity?.id
    if (id && parseInt(entityId) !== parseInt(id)) {
      await super.store().dispatch('fetchEmployeePermissions', id)
    }
  }

  async list() {
    return super.auth().can(super.permissions().EMPLOYEES_VIEW)
  }

  async view(id) {
    if (!id) {
      super.store().commit('setEmployeeEntity', {})
      return false
    }

    await this.#checkAndFetchEntity(id)
    return super.store().state.users.entity.view
  }

  async create() {
    return super.auth().can(super.permissions().EMPLOYEES_CREATE)
  }

  async edit(id) {
    if (!id) {
      super.store().commit('setEmployeeEntity', {})
      return false
    }

    await this.#checkAndFetchEntity(id)
    return super.store().state.users.entity.edit
  }

  async delete(id) {
    if (!id) {
      super.store().commit('setEmployeeEntity', {})
      return false
    }

    await this.#checkAndFetchEntity(id)
    return super.store().state.users.entity.delete
  }

  async communication() {
    return super.auth().can(super.permissions().EMPLOYEES_COMMUNICATION)
      || super.auth().can(super.permissions().USERS_COMMUNICATION)
  }
}
