<script>
export default {
  name: "ModalStatement",
  props: ['title', 'text']
}
</script>

<template>
  <div class="base-modal__title-wrapper">
    <h1 class="base-modal__title base-modal__title_centered">{{ title }}</h1>
    <p v-show="text" class="base-modal__text base-modal__text_centered">{{ text }}</p>
  </div>
</template>

<style scoped lang="scss">

</style>
