import axios from 'axios';
import router from '@/router';
import store from '../store';
import modules from '@/api';

export const baseURL = process.env.VUE_APP_BASE_API_URL;

const api = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
    },
    xsrfCookieName: 'XSRF-TOKEN',
});

api.defaults.withCredentials = true;

api.interceptors.response.use(undefined, function (error) {
    // Too Many Attempts
    if (error.response && error.response.status === 429) {
        router.push({name: 'ToManyAttempts'});
    }

    const user = Object.keys(store.getters.user).length;

    if (error.response && error.response.status === 401 && user) {
        if (router.currentRoute.value.name !== 'Login') {
            window.location.href = '/login';
        }
    }

    return Promise.reject(error);
});

const ApiService = {
    api: {},
    _axios: api,

    setModules(modules) {
        Object.entries(modules).forEach((m) => {
            this.setModule(m[0], m[1]);
        });
    },

    setModule(name, setCallback) {
        this.api[name] = setCallback(this._axios);
    }
};

ApiService.setModules(modules);

export default ApiService;

export const DEFAULT_API_VERSION = 1;
export const getVerUri = (uri, ver = DEFAULT_API_VERSION) => `/api/v${ver}/${uri}`;
export const asFormData = (axios) => defineAxiosContentType(axios, "multipart/form-data");
export const asJson = (axios) => defineAxiosContentType(axios, "application/json");

const defineAxiosContentType = (axios, contentType) => {
    axios.defaults.headers = {
        ...axios.defaults.headers,
        "Content-Type": contentType
    };

    return axios;
}
