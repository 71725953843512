import ApiService from '@/services/ApiService'
import { BtoF } from '@/services/MomentService'

export default {
    state: {
        offices: []
    },
    getters: {
        offices: state => state.offices
    },
    mutations: {
        setOffices: (state, data) => state.offices = data
    },
	actions: {
        fetchOffices({ commit }, data) {
            commit('setOffices', [])
            return new Promise((resolve, reject) => {
                ApiService.api.offices.fetchOffices(data)
                    .then(res => {
                        commit('setOffices', res.data.data)
                        resolve(res.data.data)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    }
}
