import Gate from '@/services/Gate/Gate'
import router from '../index'
import store from '@/store'

export const canViewHome = async (to) => {
  if (to.name === 'SettingCompanyUsersEdit' && parseInt(to.params.id) === store.getters.user.id) {
    return true
  }
  await Gate.can('view', 'setting').then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}

export const canViewAccountDetails = async () => {
  await Gate.can('accountDetails', 'setting').then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}

export const canEditTemplates = async () => {
  await Gate.can('templatesView', 'setting').then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}

export const canEditProjects = async () => {
  await Gate.can('projectView', 'setting').then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}

export const canEdit = async () => {
  await Gate.can('edit', 'setting').then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}
