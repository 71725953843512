<template>
  <CCol v-if="items.count > 0">
    <SmartTable
      id="tasks-search"
      :name="'search-table-tasks'"
      :items="mergedData"
      :columns="columns.map((i) => ({ ...i, sorter: false }))"
      infinity
    >
    </SmartTable>
    <CButton :disabled="lazyData.loading || items.total <= mergedData.length" color="link" class="w-link-all" @click="showMore()">
      {{ lazyData.loading ? 'Loading...' : 'Show more' }}
    </CButton>
  </CCol>
  <AccordionEmptyTable v-else />
</template>

<script>
import AccordionEmptyTable from './AccordionEmptyTable'
export default {
  name: 'Tasks',
  components: { AccordionEmptyTable },
  props: {
    items: {
      type: Object,
      default: () => {
        return { data: [], total: 0 }
      },
    },
    search: { type: String, default: '' },
  },
  data() {
    return {
      columns: [
        {
          key: 'name',
          label: 'Task Name',
          _style: { textAlign: 'center' },
          link: {
            name: 'TaskContracts',
            params: { id: 'project_id', task_id: 'id' },
          },
        },
        {
          key: 'task_id',
          label: 'Task ID',
          _style: { textAlign: 'center' },
          link: {
            name: 'TaskContracts',
            params: { id: 'project_id', task_id: 'id' },
          },
        },
        {
          key: 'due_date',
          label: 'Due Date',
          _style: { textAlign: 'center' },
          format: (value) => (value ? this.$BtoF({ value }) : null),
        },
        {
          key: 'status',
          label: 'Status',
          _style: { textAlign: 'center' },
        },
        {
          key: 'priority',
          label: 'Priority',
          _style: { textAlign: 'center' },
        },
        {
          key: 'external_company_name',
          label: 'Company Name',
          _style: { textAlign: 'center' },
          link: { name: 'Company', params: { id: 'external_company_id' } },
        },
      ],
      lazyData: {
        per_page: 3,
        page: 1,
        loading: false,
        items: []
      }
    }
  },
  methods: {
    showMore() {
      this.lazyData.loading = true
      this.lazyData.page++;
      const params = {
        search: this.search,
        type: 'tasks',
        page: this.lazyData.page,
        per_page: this.lazyData.per_page
      }

      this.$http.topNav.searchResults({ params })
        .then(res => {
          this.lazyData.items = this.lazyData.items.concat(res.data.search_results[0].data)
        })
        .finally(() => this.lazyData.loading = false)
    },
    scrollDown() {
      const tableElement = document.getElementsByClassName('SmartTable infinityScroll')[1]
      if (!tableElement) return

      setTimeout(() => tableElement.scrollTop = 10000000)
    }
  },
  computed: {
    mergedData() {
      return [...this.items.data, ...this.lazyData.items]
    }
  },
  watch: {
    mergedData() {
      this.scrollDown()
    }
  }
}
</script>

<style scoped></style>
