import i18n from '@/i18n'

export const parseError = error => {
  const result = {
    status: 500,
    message: i18n.global.t('messages.something_went_wrong'),
    validationMessages: {},
  }

  if (error.response || (error.data && error.status)) {
    const response = error.response || error
    const responseData = response.data

    result.status = response.status

    if (response.status < 500) {
      if (response.status === 401 && !responseData.message) {
        result.message = i18n.global.t('messages.auth_failed')
      } else if (response.status === 403 && !responseData.message) {
        result.message = i18n.global.t('messages.unauthorized')
      } else if (response.status === 422) {
        result.message = responseData.message || i18n.global.t('messages.given_data_invalid')

        if (responseData.errors) {
          Object.entries(responseData.errors).forEach(field => {
            result.validationMessages[field[0]] = field[1][0]
          })
        }
      } else if (responseData.message) {
        result.message = responseData.message
      }
    }
  }

  return result
}
