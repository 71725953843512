export const cilInfoCircle = `<svg width="68" height="68" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3340_31862)">
<path d="M16.6352 3.41305C14.881 1.66292 12.5038 0.680688 10.0259 0.68213C7.54797 0.683573 5.17193 1.66857 3.41976 3.42074C1.66759 5.17291 0.682596 7.54894 0.681154 10.0269C0.679712 12.5048 1.66194 14.882 3.41207 16.6362C5.16628 18.3863 7.54346 19.3686 10.0214 19.3671C12.4993 19.3657 14.8754 18.3807 16.6275 16.6285C18.3797 14.8764 19.3647 12.5003 19.3661 10.0224C19.3676 7.54443 18.3854 5.16725 16.6352 3.41305ZM10.0236 18.1248C5.55719 18.1248 1.92348 14.4911 1.92348 10.0246C1.92348 5.5582 5.55719 1.92445 10.0236 1.92445C14.4901 1.92445 18.1238 5.55816 18.1238 10.0246C18.1238 14.4911 14.4901 18.1248 10.0236 18.1248Z" fill="var(--ci-primary-color, currentColor)"/>
<path d="M11.3644 3.74969H10.0002V5.11391H11.3644V3.74969Z" fill="var(--ci-primary-color, currentColor)"/>
<path d="M11.1453 14.0241C11.083 14.1305 10.994 14.2188 10.8871 14.2802C10.7803 14.3416 10.6592 14.3741 10.5359 14.3743C10.4263 14.3743 10.3181 14.3488 10.2201 14.2997C10.122 14.2506 10.0367 14.1793 9.97105 14.0915C9.90535 14.0037 9.86102 13.9018 9.84159 13.7939C9.82215 13.6859 9.82815 13.575 9.85909 13.4698L11.2524 8.73239C11.3926 8.25679 11.345 7.74555 11.1195 7.304C10.8939 6.86245 10.5076 6.52422 10.0401 6.35901C9.57263 6.1938 9.05958 6.21419 8.60668 6.41598C8.15378 6.61777 7.79552 6.98558 7.60573 7.44364L7.14799 8.54653L8.30256 9.02567L8.7603 7.92275C8.82744 7.76011 8.95445 7.62945 9.11513 7.55774C9.27581 7.48603 9.45788 7.47874 9.62377 7.53738C9.78967 7.59602 9.92672 7.71611 10.0066 7.87286C10.0866 8.02962 10.1033 8.21107 10.0533 8.37978L8.65991 13.1172C8.57415 13.4087 8.55754 13.7163 8.6114 14.0154C8.66526 14.3146 8.7881 14.597 8.97018 14.8404C9.15226 15.0837 9.38856 15.2813 9.66032 15.4174C9.93208 15.5535 10.2318 15.6244 10.5358 15.6244C10.8775 15.6238 11.2132 15.5338 11.5095 15.3636C11.8059 15.1933 12.0526 14.9485 12.2252 14.6536L12.8151 13.6414L11.7351 13.0119L11.1453 14.0241Z" fill="var(--ci-primary-color, currentColor)"/>
</g>
<defs>
<clipPath id="clip0_3340_31862">
<rect width="20" height="20" rx="8" fill="var(--ci-primary-color, currentColor)"/>
</clipPath>
</defs>
</svg>
`
