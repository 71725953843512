import $P from '@/utils/constants/permissions'
import { canCreate, canView, canViewNotes } from './middleware/company'
import { canView as viewEmployee } from './middleware/employee'

const Companies = () => import('@/views/Companies')
const CompaniesList = () => import('@/views/Companies/List')
const CompaniesExisted = () => import('@/views/Companies/List/Existed')
const CompaniesInvited = () => import('@/views/Companies/List/Invited')

const Company = () => import('@/views/Companies/Company')
const CompanyInfo = () => import('@/views/Companies/Company/Info')
const CompanyTasks = () => import('@/views/Companies/Company/Tasks')
const CompanyTasksKanban = () => import('@/views/Companies/Company/TasksKanban')
const CompanyProjects = () => import('@/views/Companies/Company/Projects')
const CompanyDocuments = () => import('@/views/Companies/Company/Documents')
const CompanyNotes = () => import('@/views/Companies/Company/Notes')
const CompanyUsers = () => import('@/views/Companies/Company/Users')

const UsersList = () => import('@/views/Companies/Company/Users/List')
const User = () => import('@/views/Companies/Company/Users/User')
const UserInfo = () => import('@/views/Companies/Company/Users/User/Info')
const UserTasks = () => import('@/views/Companies/Company/Users/User/Tasks')
const UserTasksKanban = () =>
  import('@/views/Companies/Company/Users/User/TasksKanban')
const UserProjects = () =>
  import('@/views/Companies/Company/Users/User/Projects')

const CompanyImport = () => import('@/views/Companies/Import')
const CompanyInvite = () => import('@/views/Companies/Invite')

export default [
  {
    path: '/companies',
    name: 'Companies',
    redirect: {
      name: 'CompaniesList',
    },
    component: Companies,
    children: [
      {
        path: '',
        name: 'CompaniesList',
        component: CompaniesList,
        redirect: {
          name: 'CompaniesExisted',
        },
        meta: { permissions: [$P.COMPANIES_VIEW] },
        children: [
          {
            path: '',
            name: 'CompaniesExisted',
            component: CompaniesExisted,
            meta: { permissions: [$P.COMPANIES_VIEW] },
          },
          {
            path: 'invited',
            name: 'CompaniesInvited',
            component: CompaniesInvited,
            beforeEnter: async () => await canCreate(),
          },
        ],
      },
      {
        path: ':id',
        name: 'Company',
        redirect: {
          name: 'CompanyInfo',
        },
        component: Company,
        beforeEnter: async (to) => await canView({ to: to }),
        children: [
          {
            path: 'info',
            name: 'CompanyInfo',
            component: CompanyInfo,
            meta: { permissions: [$P.COMPANIES_VIEW] },
          },
          {
            path: 'tasks',
            name: 'CompanyTasks',
            component: CompanyTasks,
            meta: {
              permissions: [$P.TASKS_AND_DOCUMENTS_VIEW, $P.COMPANIES_VIEW],
            },
          },
          {
            path: 'tasks/kanban',
            name: 'CompanyTasksKanban',
            component: CompanyTasksKanban,
            redirect: {
              name: 'CompanyTasks',
            },
            meta: { permissions: [$P.COMPANIES_VIEW] },
          },
          {
            path: 'projects',
            name: 'CompanyProjects',
            component: CompanyProjects,
            meta: { permissions: [$P.COMPANIES_VIEW, $P.PROJECTS_VIEW] },
          },
          {
            path: 'documents',
            name: 'CompanyDocuments',
            component: CompanyDocuments,
            meta: {
              permissions: [$P.TASKS_AND_DOCUMENTS_VIEW, $P.COMPANIES_VIEW],
            },
          },
          {
            path: 'notes',
            name: 'CompanyNotes',
            component: CompanyNotes,
            beforeEnter: async (to) => await canViewNotes({ to: to }),
          },
          {
            path: 'users',
            name: 'CompanyUsers',
            redirect: {
              name: 'CompanyUsersList',
            },
            component: CompanyUsers,
            children: [
              {
                path: '',
                name: 'CompanyUsersList',
                component: UsersList,
                meta: { permissions: [$P.COMPANIES_VIEW, $P.USERS_VIEW] },
              },
              {
                path: ':user_id',
                name: 'CompanyUser',
                redirect: {
                  name: 'CompanyUserDetails',
                },
                component: User,
                beforeEnter: async (to) => await viewEmployee(to),
                children: [
                  {
                    path: 'info',
                    name: 'CompanyUserDetails',
                    component: UserInfo,
                  },
                  {
                    path: 'tasks',
                    name: 'CompanyUserTasks',
                    component: UserTasks,
                  },
                  {
                    path: 'tasks/kanban',
                    name: 'CompanyUserTasksKanban',
                    component: UserTasksKanban,
                    redirect: {
                      name: 'CompanyUserTasks',
                    },
                  },
                  {
                    path: 'projects',
                    name: 'CompanyUserProjects',
                    component: UserProjects,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'import',
        name: 'CompanyImport',
        component: CompanyImport,
        beforeEnter: async () => await canCreate(),
      },
      {
        path: 'invite',
        name: 'CompanyInvite',
        beforeEnter: async () => await canCreate(),
        component: CompanyInvite,
      },
    ],
  },
]
