import Gate from '@/services/Gate/Gate'
import router from '../index'
import store from '@/store'

export const canListView = async () => {
  await Gate.can('list', 'employee').then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}

export const canCreate = async () => {
  await Gate.can('create', 'employee').then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}

export const canCommunication = async () => {
  await Gate.can('communication', 'employee').then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}

export const canView = async (to) => {
  await Gate.can('view', 'employee', to.params.user_id).then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}

export const canEdit = async (to) => {
  if (to.name === 'SettingCompanyUsersEdit' && parseInt(to.params.id) === store.getters.user.id) {
    return true
  }

  await Gate.can('edit', 'employee', to.params.id).then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}
