import { Type } from './Type'

export class Setting extends Type {
  async accountDetails() {
    return super.auth().can(super.permissions().BILLING_ACCOUNT_VIEW)
      && super.auth().can(super.permissions().ACCOUNT_CONFIGURATION_EDIT)
  }

  async templatesView() {
    return super.auth().can(super.permissions().TEMPLATES_EDIT)
      && super.auth().can(super.permissions().ACCOUNT_CONFIGURATION_VIEW)
  }

  async projectView() {
    return super.auth().can(super.permissions().PROJECTS_DELETE)
      && super.auth().can(super.permissions().ACCOUNT_CONFIGURATION_EDIT)
  }

  async companyDetails() {
    return super.auth().can(super.permissions().COMPANIES_VIEW)
      && super.auth().can(super.permissions().ACCOUNT_CONFIGURATION_VIEW)
  }

  async view() {
    return super.auth().can(super.permissions().ACCOUNT_CONFIGURATION_VIEW)
  }

  async edit() {
    return super.auth().can(super.permissions().ACCOUNT_CONFIGURATION_EDIT)
  }

  async create() {
    return super.auth().can(super.permissions().ACCOUNT_CONFIGURATION_CREATE)
  }

  async delete() {
    return super.auth().can(super.permissions().ACCOUNT_CONFIGURATION_DELETE)
  }
}
