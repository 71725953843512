<template>
  <CFooter style="background: #677A89">
    <div>
      <!-- <a href="https://coreui.io" target="_blank">CoreUI</a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} creativeLabs.</span
      > -->
    </div>
    <div class="ms-auto">
      <span class="me-1" style="color: #ffffff; font-size: 12px">© {{ new Date().getFullYear() }} Contracts Connected. All rights reserved</span>
      <!-- <span class="me-1">Powered by</span> -->
      <!-- <a href="https://coreui.io/vue" target="_blank">CoreUI for Vue</a> -->
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
