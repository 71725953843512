<template>
  <div :class="['cn-form-checkbox', invalid ? 'validation-error' : '']">
    <label class="container">
      {{ label }}
      <input
        type="checkbox"
        :value="modelValue"
        :checked="modelValue"
        :disabled="disabled"
        :placeholder="placeholder"
        @change="$emit('update:modelValue', $event.target.checked)"
      />
      <span :class="['checkmark', align]"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'CNCheckbox',
  props: {
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: 'center',
    },
    // eslint-disable-next-line vue/require-default-prop
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],
  mounted() {},
  methods: {},
}
</script>
<style lang="scss" scoped>
.cn-form-checkbox {
  .container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: 6px;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark.center {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .checkmark.left {
    left: 0;
  }

  .checkmark.right {
    right: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid #677a89;
    border-radius: 4px;
  }

  .container input[disabled] ~ .checkmark,
  .container:hover input[disabled] ~ .checkmark {
    border-color: #c3cdd5;
    background-color: white;
  }
  .container:hover input:is(:checked) ~ .checkmark:after {
    left: 5px;
    height: 13px;
  }
  .container:hover input ~ .checkmark {
    box-shadow: 0 0 0 0.2rem rgba(171, 231, 250, 0.5);
    background-color: #ffffff;
    border-color: #0895d1;
    border-width: 2px;
  }

  .container input:checked ~ .checkmark {
    background-color: #2196f3;
    border-color: #2196f3;
  }

  .container:hover input:is(:disabled) ~ .checkmark {
    border-color: #c3cdd5;
    background-color: white;
    box-shadow: unset;
    border-width: 1px;
  }
  .container input:is(:disabled):is(:checked) ~ .checkmark,
  .container:hover input:is(:disabled):is(:checked) ~ .checkmark {
    border-color: #c3cdd5;
    background-color: #c3cdd5;
    box-shadow: none;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: 6px;
    top: 0px;
    width: 6px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.validation-error {
  .checkmark {
    border-color: #db000b;
  }

  .container:hover input ~ .checkmark {
    box-shadow: 0 0 0 0.2rem rgba(219, 0, 11, 0.5);
    background-color: #ffffff;
    border-color: #db000b;
    //border-width: 2px;
  }

  .container input:checked ~ .checkmark {
    background-color: #db000b;
    border-color: #db000b;
  }
}
</style>
