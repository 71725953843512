export const cilFolderNew = `
  <svg
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.9065 7.38961C22.8016 7.25445 22.6671 7.14515 22.5134 7.07013C22.3597 6.99512 22.1908 6.95638 22.0197 6.95691H18.9166V4.5C18.9163 4.21278 18.802 3.93742 18.5989 3.73433C18.3958 3.53123 18.1204 3.417 17.8332 3.41667H11.4442L10.6111 1.41656C10.5291 1.21894 10.3903 1.0501 10.2123 0.931433C10.0343 0.812769 9.82506 0.749624 9.61111 0.750002H1.83333C1.54611 0.750337 1.27076 0.86458 1.06767 1.06767C0.864579 1.27076 0.750335 1.54612 0.75 1.83333V18.9167H20.4706L23.1111 8.35477C23.1531 8.18896 23.1565 8.01572 23.121 7.84839C23.0855 7.68106 23.0121 7.52411 22.9065 7.38961ZM2.25 17.4167V2.25H9.33333L10.4443 4.91667H17.4167V6.95691H6.44939C6.1984 6.95658 5.95453 7.04033 5.75668 7.19477C5.55883 7.34922 5.41841 7.56548 5.35781 7.80905L2.95608 17.4167H2.25ZM19.2995 17.4167H4.50225L6.74217 8.45691H21.5394L19.2995 17.4167Z"
      fill="#677A89"
    />
  </svg>`
