<template>
  <CRow :key="key" :xs="{ cols: 1, gutter: 3 }" class="text-dark">
    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-2 col-form-label">
          Company Name
        </CFormLabel>
        <CCol class="col-md-8 col-xxl-10">
          <CFormInput v-model="form.company_name" placeholder="Company Name" />
        </CCol>
      </CRow>
    </CCol>
    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-2 col-form-label">
          Company Status
        </CFormLabel>
        <CCol class="col-md-8 col-xxl-10">
          <MSelect
            v-model="form.status"
            :options="statuses"
            placeholder="Select Status"
            style="z-index: 9999999999 !important"
          />
        </CCol>
      </CRow>
    </CCol>
    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-2 col-form-label">
          Invite Date
        </CFormLabel>
        <CCol class="col-md-4 col-xxl-5">
          <CInputDate
            v-model="form.invite_date_from"
            :end="form.invite_date_to ? form.invite_date_to : null"
            type="clear"
            clearable
            placeholder="Date from"
          />
        </CCol>
        <CCol class="col-md-4 col-xxl-5">
          <CInputDate
            v-model="form.invite_date_to"
            :start="form.invite_date_from ? form.invite_date_from : null"
            type="clear"
            clearable
            placeholder="Date to"
          />
        </CCol>
      </CRow>
    </CCol>
    <CCol>
      <CRow>
        <CFormLabel class="col-md-4 col-xxl-2 col-form-label">
          Link expires in
        </CFormLabel>
        <CCol class="col-md-8 col-xxl-10">
          <CRow class="align-items-center">
            <CCol class="col-auto">
              <CFormInput
                v-model="form.expires_in_from"
                class="RangeInput"
                type="number"
                placeholder="Min"
                @blur="checkRange('expires_in_from', 'expires_in_to', 'max')"
              />
            </CCol>
            <CCol class="col-auto p-0">-</CCol>
            <CCol class="col-auto">
              <CFormInput
                v-model="form.expires_in_to"
                class="RangeInput"
                type="number"
                placeholder="Max"
                @blur="checkRange('expires_in_to', 'expires_in_from', 'min')"
              />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import mixin from './mixin'
export default {
  name: 'InvitedCompany',
  mixins: [mixin],
  data() {
    return {
      form: {
        company_name: '',
        status: [],
        expires_in_from: '',
        expires_in_to: '',
        invite_date_from: null,
        invite_date_to: null,
      },
      statuses: [
        { value: 'Accepted', text: 'Accepted' },
        { value: 'Declined', text: 'Declined' },
        { value: 'Canceled', text: 'Canceled' },
        { value: 'Expired', text: 'Expired' },
        { value: 'Pending response', text: 'Pending response' },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => this.setOrigin())
  },
  methods: {
    getBadgeItems(outForm = undefined) {
      const form = outForm ? outForm : this.form
      return [
        {
          label: 'Company',
          value: form.company_name,
          key: ['company_name'],
        },
        { label: 'Status', value: this.badgeBySelectOptions('status', 'statuses', false), key: ['status'] },
        {
          label: 'Invite',
          value: this.valueDateRange(
            form.invite_date_from,
            form.invite_date_to,
          ),
          key: ['invite_date_from', 'invite_date_to'],
        },
        {
          label: 'ExpiresIn',
          value: this.valueRange(
            form.expires_in_from,
            form.expires_in_to,
          ),
          key: ['expires_in_from', 'expires_in_to'],
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.RangeInput {
  width: 50px;
}
</style>
