<template>
  <AppSidebar />
  <div
    class="wrapper bg-light dark:bg-transparent"
  >
    <AppHeader />
    <CContainer fluid class="container-w">
      <router-view v-slot="{ Component }">
        <!-- <transition name="fade">
          <div :key="route.name" style="position: relative">   -->
            <component :is="Component" />
          <!-- </div>
        </transition> -->
      </router-view>
    </CContainer>
  </div>
  <!-- <AppAside /> -->
</template>
<script>
import { CContainer } from '@coreui/vue-pro'
import AppAside from './AppAside'
import AppHeader from './AppHeader'
import AppSidebar from './AppSidebar'

export default {
  name: 'DefaultLayout',
  components: {
    AppAside,
    AppHeader,
    AppSidebar,
    CContainer,
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.container-w {
  min-height: calc(100vh - 88px);
  padding-left: 30px;
  padding-right: 30px;
}
</style>
