import autosize from 'autosize/dist/autosize'

export const Autosize = {
  created(el, binding, vnode) {
    autosize(vnode.el)
    el.style.maxHeight = '200px'
    el.style.resize = 'none'
  },
  mounted(payload){
    payload.dispatchEvent(new Event('input'))
  },
  updated(el) {
    // el.dispatchEvent(new Event('change'))
  },
}
