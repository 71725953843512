<template>
    <div class="subsidiary-companies">
        <ConfirmDialog ref="confirmRemove"/>

        <div v-if="!viewOnly" class="addAction mb-3 me-3">
            <h4 class="form-title">Subsidiary Companies</h4>

            <CButton color="primary" variant="outline" class="p-0" @click="openNewSubsidiaryCompanyPopup">
                Add Subsidiary
            </CButton>
        </div>

        <SmartTable :columns="subsidiaryColumns" :items="subsidiaryCompanies" infinity>
            <template #attachment_path="{ item }">
                <CIcon v-if="item.attachment_path" variant="ghost" name="cil-description" style="cursor: pointer"
                       @click="downloadAttachment(item)"/>
            </template>

            <template v-if="!viewOnly" #button="{ item }">
                <div class="d-flex">
                    <CIcon name="cil-pen" class="mx-2 pointer" @click="openNewSubsidiaryCompanyPopup($event, item)"/>
                    <CIcon name="cil-trash" class="mx-2 pointer" @click="handleRemove(item)"/>
                </div>
            </template>
        </SmartTable>

        <CompanyDetailsModal title="Add Subsidiary Company" :visible="newSubsidiaryCompanyPopup"
                             @close="() => { newSubsidiaryCompanyPopup = false; updateCompanyData = false; }">
            <ConfirmDialog ref="confirmDialog"/>

            <CInputGroup>
                <CNInput required v-model="form.name" max-length="255" label="Company name"
                         :invalid="!!validationErrors.name" @blur="handleChangeField('name')"/>
                <CFormText v-if="validationErrors.name" :style="`color: ${validationErrors.name && 'red'}`">
                    {{ validationErrors.name }}
                </CFormText>
            </CInputGroup>

            <CInputGroup>
                <CNInput required v-model="form.dba" max-length="255" label="DBA" :invalid="!!validationErrors.dba"
                         @blur="handleChangeField('dba')"/>
                <CFormText v-if="validationErrors.dba" :style="`color: ${validationErrors.dba && 'red'}`">
                    {{ validationErrors.dba }}
                </CFormText>
            </CInputGroup>


            <CInputGroup>
                <CNInput required v-model="form.website" max-length="255" label="Website"
                         :invalid="!!validationErrors.website" @blur="handleChangeField('website')"/>
                <CFormText v-if="validationErrors.website" :style="`color: ${validationErrors.website && 'red'}`">
                    {{ validationErrors.website }}
                </CFormText>
            </CInputGroup>

            <CInputGroup>
                <CNInput required v-model="form.tax" v-mask="['##-#######']" max-length="255" label="Tax ID"
                         :invalid="!!validationErrors.tax" @blur="handleChangeField('tax')"/>
                <CFormText v-if="validationErrors.tax" :style="`color: ${validationErrors.tax && 'red'}`">
                    {{ validationErrors.tax }}
                </CFormText>
            </CInputGroup>

            <CNSelect required :searchable="true" :caret="true" v-model="form.primary_contact_id" class="w-100"
                      label="Primary Contact" :error="validationErrors.primary_contact_id"
                      :invalid="!!validationErrors.primary_contact_id"
                      :options="companyPrimaryContacts.map(c => ({ ...c, name: c.label }))"
                      search @touchField="handleChangeField('primary_contact_id')"
                      @change="handleChangeField('primary_contact_id')"/>

            <CNSelect required :searchable="true" :caret="true" v-model="form.assignee_id" class="w-100"
                      label="Default Assignee" :error="validationErrors.assignee_id"
                      :invalid="!!validationErrors.assignee_id"
                      :options="companyDefaultSignersOrAssigners.map(c => ({ ...c, name: c.label }))"
                      search @touchField="handleChangeField('assignee_id')" @change="handleChangeField('assignee_id')"/>

            <CNSelect required :searchable="true" :caret="true" v-model="form.signer_id" class="w-100"
                      label="Default Signer" :error="validationErrors.signer_id" :invalid="!!validationErrors.signer_id"
                      :options="companyDefaultSignersOrAssigners.map(c => ({ ...c, name: c.label }))"
                      search @touchField="handleChangeField('signer_id')" @change="handleChangeField('signer_id')"/>

            <template #upload>
                <UploadFile v-model="form.attachment" :redesign="true" :file-icon="'cil-file'"
                            :btn-icon="'cil-paperclip'" :btn-name="'Attach'" :with-validation="false"
                            :accept="'image/jpeg,image/png,.xls,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                            @change="handleChangeField('attachment')"/>
                <CFormText v-if="validationErrors.attachment" style="color: red">
                    {{ validationErrors.attachment }}
                </CFormText>
            </template>

            <template #footer>
                <CButton color="primary" variant="outline" class="w-100" @click="closeNewSubsidiaryCompanyPopup">
                    Cancel
                </CButton>

                <CButton color="primary" class="w-100" @click="addSubsidiaryCompany">
                    <CSpinner v-if="loading" color="light" size="sm"/>
                    Save
                </CButton>
            </template>

        </CompanyDetailsModal>
    </div>
</template>

<script>
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import {equalObjectsByKeys} from '@/utils/helper'
import CSelectSearch from '@/components/CSelectSearch'
import UploadFile from '@/components/UploadFile'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import {parseError} from '@/utils/api'
import {mask} from 'vue-the-mask'
import {notifyByPlan} from '@/services/Notify/Toasts'
import CompanyDetailsModal from "@/components/Modals/CompanyDetailsModal.vue";
import documentDownload from "@/mixin/documentDownload";
import SubsidiaryCompanies from "@/api/v2/endpoints/SubsidiaryCompanies";

export default {
    name: 'SubsidiaryCompanies',
    components: {CompanyDetailsModal, CSelectSearch, UploadFile, ConfirmDialog},
    directives: {mask},
    inject: ['toast'],
    mixins: [documentDownload],
    props: {
        viewOnly: {type: Boolean, default: false},
        subsidiaryCompanies: {type: Array, default: () => []},
        companyPrimaryContacts: {type: Array, default: () => []},
        companyDefaultSignersOrAssigners: {type: Array, default: () => []},
    },
    emits: ['updateData'],
    data() {
        return {
            subsidiaryColumns: [
                {
                    key: 'name',
                    label: 'Company name',
                    sorter: false,
                },
                {
                    key: 'dba',
                    label: 'DBA',
                    sorter: false,
                },
                {
                    key: 'website',
                    label: 'Website',
                    sorter: false,
                },
                {
                    key: 'tax',
                    label: 'Tax ID',
                    sorter: false,
                },
                {
                    key: 'primary_contact_name',
                    label: 'Primary contact',
                    sorter: false,
                },
                {
                    key: 'assignee_name',
                    label: 'Default assignee',
                    sorter: false,
                },
                {
                    key: 'signer_name',
                    label: 'Default signer',
                    sorter: false,
                },
                {
                    key: 'attachment_path',
                    label: 'File',
                    sorter: false,
                    _props: {class: 'tableIconCenter'},
                    _style: 'min-width: 10px',
                },
            ],

            updateCompanyData: false,
            form: {
                name: '',
                dba: '',
                website: '',
                tax: '',
                primary_contact_id: '',
                assignee_id: '',
                signer_id: '',
                attachment: null,
            },
            origin: {},
            validator: {},
            validationErrors: {},

            loading: false,

            newSubsidiaryCompanyPopup: false,
        }
    },
    created() {
        this.$nextTick(() => this.setRules())
    },
    methods: {
        clearFormData(form) {
            for (let key in form) {
                form[key] = null
            }
        },
        openNewSubsidiaryCompanyPopup(e, subsidiary = undefined) {
            if (this.$store.getters.isSubPlan) {
                notifyByPlan()
                return false
            }

            e.preventDefault()
            if (subsidiary) {
                this.form.id = subsidiary.id
                this.form = JSON.parse(
                    JSON.stringify({
                        id: subsidiary.id,
                        assignee_id: subsidiary.assignee_id,
                        dba: subsidiary.dba,
                        name: subsidiary.name,
                        primary_contact_id: subsidiary.primary_contact_id,
                        signer_id: subsidiary.signer_id,
                        tax: subsidiary.tax,
                        website: subsidiary.website,
                        attachment: subsidiary.attachment_path,
                    }),
                )
            } else {
                this.clearFormData(this.form)
            }
            this.origin = JSON.parse(JSON.stringify(this.form))
            this.validationErrors = {}
            this.newSubsidiaryCompanyPopup = true
        },
        async closeNewSubsidiaryCompanyPopup() {
            if (!this.checkForm()) {
                await this.$refs.confirmDialog
                    .confirm({
                        text: this.$t('messages.unsaved'),
                        cancelText: 'Cancel',
                        confirmText: 'OK',
                        reverse: true,
                    })
                    .then((res) => {
                        if (res) {
                            this.newSubsidiaryCompanyPopup = false
                            this.form.id = null
                            this.clearFormData(this.form)
                        }
                    })
            } else {
                this.newSubsidiaryCompanyPopup = false
                this.form.id = null
            }
        },

        setRules() {
            this.validator = validator({
                name: [rules.required, rules.strMax(255)],
                dba: [rules.required, rules.strMax(255)],
                website: [rules.required, rules.strMax(255), rules.url],
                tax: [rules.required, rules.strMax(255)],
                primary_contact_id: [rules.required],
                assignee_id: [rules.required],
                signer_id: [rules.required],
                attachment: [
                    rules.nullable,
                    () => (typeof this.form.path === 'object' ? 0 : -1),
                    rules.extensions([
                        '.xlsx',
                        '.doc',
                        '.docx',
                        '.xls',
                        '.pdf',
                        '.jpeg',
                        '.png',
                        '.jpg',
                    ]),
                    rules.fileSize({size: 10, type: 'MB'}),
                ],
            })
        },
        handleChangeField(field) {
            this.validationErrors[field] = this.validator.validate(
                field,
                this.form[field],
            )
        },
        checkForm() {
            return equalObjectsByKeys(
                this.form,
                this.origin,
                Object.keys(this.origin),
            )
        },
        makeFormData() {
            const formData = new FormData()
            for (let key in this.form) {
                formData.append(key, this.form[key] || '')
            }
            return formData
        },

        addSubsidiaryCompany() {
            const validationResult = this.validator.validateAll(this.form)

            if (validationResult.hasErrors) {
                this.validationErrors = validationResult.validationErrors
                return
            }
            this.loading = true
            const request = this.form.id
                ? this.$http.setting.updateSubsidiaryCompany(this.form.id, this.makeFormData())
                : this.$http.setting.addSubsidiaryCompany(this.makeFormData());

            request
                .then((response) => {
                    this.toast('success', response.data.message)
                    this.newSubsidiaryCompanyPopup = false
                    this.form.id = null
                    this.$emit('updateData')
                })
                .catch((err) => {
                    err = parseError(err)
                    if (err.status === 422) {
                        this.validationErrors = err.validationMessages
                    } else {
                        this.toast('error', err.message)
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },

        async handleRemove(item) {
            await this.$refs.confirmRemove
                .confirm({
                    text:
                        'Are you sure you want to delete subsidiary company "' +
                        item.name +
                        '"?',
                    confirmText: this.$t('Delete'),
                    cancelText: this.$t('Cancel'),
                    reverse: true,
                })
                .then((res) => {
                    if (res) this.remove(item.id)
                })
        },
        remove(id) {
            this.$http.setting
                .removeSubsidiaryCompany(id)
                .then((response) => {
                    this.toast('success', response.data.message)
                    this.$emit('updateData')
                })
                .catch((error) => {
                    error = parseError(error)
                    this.toast('error', error.message)
                })
        },
        downloadAttachment(subsidiaryCompany) {
            this.handleDownloadPromise(
                SubsidiaryCompanies.downloadAttachment(subsidiaryCompany.id),
                subsidiaryCompany.id,
                `Subsidiary Company: ${subsidiaryCompany.name}`
            );
        },
    },
}
</script>
