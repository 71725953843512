import {asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    login: data => {
        return new Promise((res, rej) => {
            asJson(axios).get('/api/sanctum/csrf-cookie')
                .then(() => {
                    asJson(axios).post(getVerUri('auth/login'), data)
                        .then(response => {
                            res(response)
                        })
                        .catch(err => {
                            rej(err)
                        })
                })
                .catch(err => {
                    rej(err)
                })
        })
    },

    user: () => asJson(axios).get(getVerUri('auth/user')),
    logout: () => asJson(axios).post(getVerUri('auth/logout')),
    forgotPassword: data => asJson(axios).post(getVerUri('auth/password/forgot'), data),
    resetPassword: data => asJson(axios).post(getVerUri('auth/password/reset'), data),
    sendVerificationLink: (data) => asJson(axios).post(getVerUri('auth/email/verification-notification'), data),
    signupCheckEmail: email => asJson(axios).post(getVerUri('auth/sign-up/find-company-by-email'), {email}),
    signupSearchCompany: search => asJson(axios).get(getVerUri('auth/sign-up/search-company'), {params: {search}}),
    getCompanyByToken: token => asJson(axios).get(getVerUri('auth/sign-up/company-by-token'), {params: {token: token}}),
    signup: data => asJson(axios).post(getVerUri('auth/sign-up'), data),
    signupFormData: () => asJson(axios).get(getVerUri('auth/sign-up/form-data')),
    getNotifications: () => asJson(axios).get(getVerUri('auth/user-notifications')),
    contactUS: data => asJson(axios).post(getVerUri('contact-form'), data)
});
