const TOOLTIP_NONE_TEXT = 'Check to turn off all permissions in the row'

export default {
  state: {
    exceptKeys: ['type', 'none', 'site_feature'],
    controlKeys: ['delete', 'create', 'edit'],
    formKeys: [
      'view',
      'edit',
      'delete',
      'assign',
      'approve',
      'sign',
      'communication',
      'create',
    ],
    configTypes: {
      projects: {
        view: { disabled: [], dependent: [] },
        communication: { disabled: [], dependent: [] },
        create: { disabled: [], dependent: [] },
        edit: {
          disabled: ['view', 'approve', 'assign'],
          dependent: ['view'],
        },
        delete: { disabled: [], dependent: [] },
        assign: { disabled: [], dependent: ['delete'] },
        approve: { disabled: [], dependent: [] },
        sign: { disabled: [], dependent: [] },
      },
      tasks_documents: {
        view: { disabled: [], dependent: [] },
        communication: { disabled: [], dependent: [] },
        create: { disabled: ['create'], dependent: [] },
        edit: { disabled: ['communication', 'approve'], dependent: ['view'] },
        delete: { disabled: [], dependent: [] },
        assign: { disabled: [], dependent: [] },
        approve: { disabled: [], dependent: ['communication'] },
        sign: { disabled: [], dependent: ['edit'] },
      },
      companies: {
        view: { disabled: [], dependent: [] },
        communication: { disabled: [], dependent: [] },
        create: { disabled: [], dependent: ['view'] },
        edit: { disabled: [], dependent: ['view'] },
        delete: { disabled: [], dependent: ['view', 'edit'] },
        assign: { disabled: [], dependent: [] },
        approve: { disabled: [], dependent: [] },
        sign: { disabled: [], dependent: [] },
      },
      users: {
        view: { disabled: [], dependent: [] },
        communication: { disabled: [], dependent: [] },
        create: { disabled: [], dependent: [] },
        edit: { disabled: [], dependent: [] },
        delete: { disabled: [], dependent: [] },
        assign: { disabled: [], dependent: [] },
        approve: { disabled: [], dependent: [] },
        sign: { disabled: [], dependent: [] },
      },
      employees: {
        view: { disabled: [], dependent: [] },
        communication: { disabled: [], dependent: ['view'] },
        create: { disabled: [], dependent: [] },
        edit: { disabled: [], dependent: ['view'] },
        delete: { disabled: [], dependent: ['view'] },
        assign: { disabled: [], dependent: [] },
        approve: { disabled: [], dependent: [] },
        sign: { disabled: [], dependent: [] },
      },
      templates: {
        view: { disabled: [], dependent: [] },
        communication: { disabled: [], dependent: [] },
        create: { disabled: [], dependent: ['view'] },
        edit: { disabled: [], dependent: ['view'] },
        delete: { disabled: [], dependent: ['view'] },
        assign: { disabled: [], dependent: [] },
        approve: { disabled: [], dependent: [] },
        sign: { disabled: [], dependent: [] },
      },
      insights_report: {
        view: { disabled: [], dependent: [] },
        communication: { disabled: [], dependent: [] },
        create: { disabled: [], dependent: [] },
        edit: { disabled: [], dependent: [] },
        delete: { disabled: [], dependent: [] },
        assign: { disabled: [], dependent: [] },
        approve: { disabled: [], dependent: [] },
        sign: { disabled: [], dependent: [] },
      },
      account_billing_info: {
        view: { disabled: [], dependent: [] },
        communication: { disabled: [], dependent: [] },
        create: { disabled: [], dependent: [] },
        edit: { disabled: [], dependent: ['view'] },
        delete: { disabled: [], dependent: ['view'] },
        assign: { disabled: [], dependent: [] },
        approve: { disabled: [], dependent: [] },
        sign: { disabled: [], dependent: [] },
      },
      account_setup_configuration: {
        view: { disabled: [], dependent: [] },
        communication: { disabled: [], dependent: [] },
        create: { disabled: [], dependent: ['view'] },
        edit: { disabled: [], dependent: ['view'] },
        delete: { disabled: [], dependent: ['view'] },
        assign: { disabled: [], dependent: [] },
        approve: { disabled: [], dependent: [] },
        sign: { disabled: [], dependent: [] },
      },
    },
    permissionsKey: {
      billing_account_view: 'view',
      billing_account_communication: 'communication',
      billing_account_create: 'create',
      billing_account_edit: 'edit',
      billing_account_delete: 'delete',
      billing_account_assign: 'assign',
      billing_account_approve: 'approve',
      billing_account_sign: 'sign',

      employees_view: 'view',
      employees_communication: 'communication',
      employees_create: 'create',
      employees_edit: 'edit',
      employees_delete: 'delete',
      employees_assign: 'assign',
      employees_approve: 'approve',
      employees_sign: 'sign',

      account_configuration_view: 'view',
      account_configuration_communication: 'communication',
      account_configuration_create: 'create',
      account_configuration_edit: 'edit',
      account_configuration_delete: 'delete',
      account_configuration_assign: 'assign',
      account_configuration_approve: 'approve',
      account_configuration_sign: 'sign',

      task_and_documents_view: 'view',
      task_and_documents_communication: 'communication',
      task_and_documents_create: 'create',
      task_and_documents_edit: 'edit',
      task_and_documents_delete: 'delete',
      task_and_documents_assign: 'assign',
      task_and_documents_approve: 'approve',
      task_and_documents_sign: 'sign',

      companies_view: 'view',
      companies_communication: 'communication',
      companies_create: 'create',
      companies_edit: 'edit',
      companies_delete: 'delete',
      companies_assign: 'assign',
      companies_approve: 'approve',
      companies_sign: 'sign',

      projects_view: 'view',
      projects_communication: 'communication',
      projects_create: 'create',
      projects_edit: 'edit',
      projects_delete: 'delete',
      projects_assign: 'assign',
      projects_approve: 'approve',
      projects_sign: 'sign',

      tasks_view: 'view',
      tasks_communication: 'communication',
      tasks_create: 'create',
      tasks_edit: 'edit',
      tasks_delete: 'delete',
      tasks_assign: 'assign',
      tasks_approve: 'approve',
      tasks_sign: 'sign',

      templates_view: 'view',
      templates_communication: 'communication',
      templates_create: 'create',
      templates_edit: 'edit',
      templates_delete: 'delete',
      templates_assign: 'assign',
      templates_approve: 'approve',
      templates_sign: 'sign',

      users_view: 'view',
      users_communication: 'communication',
      users_create: 'create',
      users_edit: 'edit',
      users_delete: 'delete',
      users_assign: 'assign',
      users_approve: 'approve',
      users_sign: 'sign',

      insights_n_reports_view: 'view',
      insights_n_reports_communication: 'communication',
      insights_n_reports_create: 'create',
      insights_n_reports_edit: 'edit',
      insights_n_reports_delete: 'delete',
      insights_n_reports_assign: 'assign',
      insights_n_reports_approve: 'approve',
      insights_n_reports_sign: 'sign',
    },
    tooltips: {
      projects: {
        none: TOOLTIP_NONE_TEXT,
        view: 'Allow access to Project Info: Details and Team section for viewers',
        communication:
          'Allow the user to participate in discussion of Scope details on the Scope Edit page',
        create:
          ' Allow the user to Create Projects, Set Project Team, Select Project Collection',
        edit: 'Allow the user to edit Project Details, Project Team, Project Collections, and view Scopes',
        delete: 'Allow the user to Archive projects from All projects list',
        assign: 'Allow the user to create and edit Scopes on Projects',
        approve: 'Allow the user to approve or reject Project Scopes',
      },
      tasks_documents: {
        none: TOOLTIP_NONE_TEXT,
        view: ' Allow access to Task Details, Review and Approve pages, Finished and Closed tasks for viewers',
        communication:
          'Allow the user to participate in discussion, add comments and suggest edits during Document review in the Task',
        create:
          'Feature under construction. Allow the user to upload new documents and contracts to create Quick tasks',
        edit: 'Allow the user to edit Task Details and Send for Signature pages',
        delete:
          'Allow the user to Revoke Tasks and Archive Tasks and Documents',
        assign:
          'Allow the user to create, assign and re-assign Tasks through the Task Builder',
        approve:
          'Allow the user to approve or reject comments and suggested edits during Document Review in the task',
        sign: 'Allow the user to sign Documents. Notice',
      },
      companies: {
        none: TOOLTIP_NONE_TEXT,
        view: 'Allow the user to send comment and Add notes for the external Companies',
        communication:
          'Allow the user to send comment and Add notes for the external Companies',
        create:
          'Allow the user to add Companies into the list and send invites to join Contracts Connected',
        edit: 'Allow the user to edit Notes to Companies',
        delete:
          'Allow the user to Archive companies from the Companies list and delete Notes',
      },
      users: {
        none: TOOLTIP_NONE_TEXT,
        view: 'Allow the user to view Pages of external Users',
        communication:
          'Allow the user to send comment through the List of All users',
        create:
          'Allow the user to invite new Users to join Contracts Connected',
      },
      employees: {
        none: TOOLTIP_NONE_TEXT,
        view: 'Allow the user to view The list of Employees',
        communication: 'Allow the user to send internal communications',
        create: 'Allow the user to send internal communications',
        edit: 'Allow the user to edit profiles of Company Employees',
        delete: 'Allow the user to Archive Company Employees',
      },
      templates: {
        none: TOOLTIP_NONE_TEXT,
        view: 'Allow the user to view the Templates and download them',
        create: 'Allow the user to Add new templates, folders and collections',
        edit: 'Allow the user to edit templates, template preferences, names of folders and collections',
        delete: 'Allow the user to delete templates, folders and collections',
      },
      insights_report: {
        none: TOOLTIP_NONE_TEXT,
        view: '',
        create: '',
        edit: '',
        delete: '',
      },
      account_billing_info: {
        none: TOOLTIP_NONE_TEXT,
        view: 'Allow the user to view Account Details and billing pages',
        edit: 'Allow the user to edit Account Details and billing pages',
        delete: 'Allow the user to delete Corporate Account',
      },
      account_setup_configuration: {
        none: TOOLTIP_NONE_TEXT,
        view: 'Allow the user to access and view the Settings section',
        create: 'Allow the user to Edit the Settings section',
        edit: '',
        delete: '',
      },
    },
    columns: [
      { key: 'site_feature', label: 'Site Feature' },
      // { key: 'none', label: 'None', _props: { class: 'text-center' } }, // Lock edit permissions
      {
        key: 'view',
        label: 'View & Download',
        _props: { class: 'text-center' },
      },
      {
        key: 'communication',
        label: 'Communication',
        _props: { class: 'text-center' },
      },
      { key: 'create', label: 'Add', _props: { class: 'text-center' } },
      { key: 'edit', label: 'Edit', _props: { class: 'text-center' } },
      { key: 'delete', label: 'Delete', _props: { class: 'text-center' } },
      {
        key: 'assign',
        label: 'Assign & Re-assign',
        _props: { class: 'text-center' },
      },
      { key: 'approve', label: 'Approve', _props: { class: 'text-center' } },
      { key: 'sign', label: 'Sign', _props: { class: 'text-center' } },
    ],
  },
  getters: {
    getTooltips: (state) => state.tooltips,
  },
  mutations: {},
  actions: {},
}
