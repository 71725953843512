<template>
  <div ref="shortcodesList" class="shortcodes" @keydown="onKeyDown">
    <div v-if="loading" class="text-secondary text-center my-2 mx-3">
      <span class="btn-spinner c-loading-button-spinner spinner-border spinner-border-sm"></span> loading...
    </div>
    <div v-else>
      <template v-if="filteredShortcodes.length">
        <button
          v-for="(shortcode, index) in filteredShortcodes"
          :key="shortcode"
          class="shortcode"
          :class="{ 'is-selected': index === selectedIndex }"
          @click.prevent="selectItem(shortcode)"
        >
          {{ shortcode }}
        </button>
      </template>
      <div v-else class="item">
        No result
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import ApiService from '@/services/ApiService'

export default defineComponent({
  name: 'ShortcodesSuggestionsList',
  props: {
    options: {
      type: Object,
      default: () => ({}),
    }
  },
  data: () => ({
    shortcodes: [],
    selectedIndex: null,
    loading: false,
  }),
  computed: {
    filteredShortcodes(){
      return this.options.query
        ? this.shortcodes.filter(s => s.startsWith(this.options.query))
        : this.shortcodes
    },
  },
  watch: {
    'options.focusIndexPosition': function(to, from){
      if ( ( to < from) && this.filteredShortcodes[this.selectedIndex + 1]){
        this.selectedIndex++
      } else if ( ( to > from ) && this.filteredShortcodes[this.selectedIndex - 1]) {
        this.selectedIndex--
      }
    },
    filteredShortcodes(){
      // eslint-disable-next-line vue/no-mutating-props
      this.options.selectedShortcode = this.filteredShortcodes[0] ?? ''
      this.selectedIndex = 0
    },
    selectedIndex(to){
      if (this.filteredShortcodes && this.filteredShortcodes[to]) {
        // eslint-disable-next-line vue/no-mutating-props
        this.options.selectedShortcode = this.filteredShortcodes[to]
        this.$nextTick(() => {
          let windowTopPosition = window.scrollY
          const focusedShortcodeElement = this.$refs.shortcodesList.querySelector('.is-selected')

          let count = focusedShortcodeElement.offsetTop - this.$refs.shortcodesList.scrollTop - 60
          this.$refs.shortcodesList.scrollBy({ top: count, left: 0 })
          //restore window position
          window.scrollTo({ top: windowTopPosition })
        })
      }
    }
  },
  async mounted() {
    this.shortcodes = await this.getShortcodes()
    await this.$nextTick(() => {
      this.selectedIndex = 0
      // eslint-disable-next-line vue/no-mutating-props
      this.options.selectedShortcode = this.filteredShortcodes[this.selectedIndex] ?? ''
    })
  },
  methods: {
    async getShortcodes(){
      this.loading = true
      let res = await ApiService.api.shortcodes.getCompanyShortcodes()
      this.loading = false

      return res.data.data.map(s => s.placeholder.replace('{{', '').replace('}}', ''))
    },
    selectItem(shortcode){
      // eslint-disable-next-line vue/no-mutating-props
      this.options.selectedShortcode = shortcode
      this.options.insertShortcode()
    },
    onKeyDown( event ) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler() {
      this.selectedIndex = ((this.selectedIndex + this.shortcodes.length) - 1) % this.shortcodes.length
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.shortcodes.length
    },

    enterHandler() {
      this.selectItem(this.selectedIndex)
    },
  },
})
</script>

<style scoped lang="scss">
.shortcodes {
  padding: 0.2rem;
  position: relative;
  border-radius: 0.5rem;
  background: white;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 10px 20px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: scroll;
}

.shortcode {
  display: block;
  margin: 0;
  width: 100%;
  text-align: left;
  background: transparent;
  border-radius: 0.4rem;
  border: 1px solid transparent;
  padding: 0.2rem 0.4rem;

  &.is-selected {
     border-color: #000;
   }
}
</style>
