import $P from '@/utils/constants/permissions'
import { canCommunication } from './middleware/employee'

const Users = () => import('@/views/Users')
const UsersList = () => import('@/views/Users/List/index')
const SendCommunication = () => import('@/views/Users/SendCommunication')

export default [
  {
    path: '/users',
    name: 'Users',
    component: Users,
    redirect: {
      name: 'UsersList',
    },
    children: [
      {
        path: '',
        name: 'UsersList',
        component: UsersList,
        meta: { permissions: [$P.USERS_VIEW] },
      },
      {
        path: 'send-communication',
        name: 'SendCommunication',
        component: SendCommunication,
        beforeEnter: async () => await canCommunication(),
      }
    ],
  },
]
