import {asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    getAllScenarios: (data) => asJson(axios).get(getVerUri('library/collection/list'), data),
    getAllTemplates: (data) => asJson(axios).get(getVerUri('library/template/list'), data),
    getScenarioData: (data) => asJson(axios).get(getVerUri('library/collection'), data),
    getPackageData: (data) => asJson(axios).get(getVerUri('library/folder'), data),
    getDataByFilter: (data) => asJson(axios).get(getVerUri('library/items/filter'), data),
    getTemplateDetails: (data) => asJson(axios).get(getVerUri('library/template/info'), data),
    createPackage: (data) => asJson(axios).post(getVerUri('library/folder/create'), data),
    createScenario: (data) => asJson(axios).post(getVerUri('library/collection/create'), data),
    entityRename: (data) => asJson(axios).put(getVerUri('library/items/rename'), data),
    entityDelete: (data) => asJson(axios).delete(getVerUri('library/items/delete'), data),
    entityMove: (data) => asJson(axios).post(getVerUri('library/items/move'), data),
    entityDuplicate: (data) => asJson(axios).post(getVerUri('library/items/duplicate'), data),
    restoreTemplates: (data) => asJson(axios).post(getVerUri('library/template/restore'), data),
    addToFavorite: (data) => asJson(axios).post(getVerUri(`library/items/add-to-favorite`), data),
    addShortcut: (data) => asJson(axios).post(getVerUri('library/template/add-shortcut'), data),
    createTemplate: (data) => ({axios, url: getVerUri('library/template/create')}),
    uploadNewVersion: (templateId, content) => asJson(axios).put(getVerUri('library/template/upload-new-version/' + templateId), {content}),
    setAsPrimary: ({templateId, packageId}) => {
        return asJson(axios).post(getVerUri(`library/template/${templateId}/mark-as-primary-in-folder/${packageId}`))
    },
    getLabels: (data) => asJson(axios).get(getVerUri('library/labels'), data),
    createLabel: (data) => asJson(axios).post(getVerUri('library/labels'), data),
    createTaskTemplate: (data) => ({axios, url: getVerUri('library/template/request')}),
    updateTemplate: (id) => ({axios, url: getVerUri('library/template/request/' + id)}),
    addLabels: (data) => asJson(axios).post(getVerUri('library/template/add-labels'), data),
    removeLabels: (data) => asJson(axios).delete(getVerUri('library/labels'), data),
    getTemplate: (data) => asJson(axios).get(getVerUri(`company/templates/${data.id || data.template_id}`), data),
    addLabel: (templateId, data) => asJson(axios).post(getVerUri('library/template/add-labels/' + templateId), data),
    editTemplate: (data) => asJson(axios).put(getVerUri(`company/templates/${data.id || data.template_id}`), data),
    deleteItem: (data) => asJson(axios).post(getVerUri('library/items/delete'), data),
    librarySearch: (data) => asJson(axios).post(getVerUri('library/items/search'), data),
    relatedTasks: (data) => asJson(axios).get(getVerUri('scope/related-tasks'), data),
    previewPrimaryContract: (id, scopeId) => axios({
        method: 'GET',
        url: getVerUri(`library/template/preview/get-primary-contract/${id}?scope_id=${scopeId}`),
        responseType: 'blob',
    }),
});
