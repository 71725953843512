// Projects
const PROJECTS_VIEW = 'projects_view';
const PROJECTS_COMMUNICATION = 'projects_communication';
const PROJECTS_CREATE = 'projects_create';
const PROJECTS_EDIT = 'projects_edit';
const PROJECTS_DELETE = 'projects_delete';
const PROJECTS_ASSIGN = 'projects_assign';
const PROJECTS_APPROVE = 'projects_approve';

// Task & Documents
const TASKS_AND_DOCUMENTS_VIEW = 'task_and_documents_view';
const TASKS_AND_DOCUMENTS_COMMUNICATION = 'task_and_documents_communication';
const TASKS_AND_DOCUMENTS_CREATE = 'task_and_documents_create';
const TASKS_AND_DOCUMENTS_EDIT = 'task_and_documents_edit';
const TASKS_AND_DOCUMENTS_DELETE = 'task_and_documents_delete';
const TASKS_AND_DOCUMENTS_ASSIGN = 'task_and_documents_assign';
const TASKS_AND_DOCUMENTS_APPROVE = 'task_and_documents_approve';
const TASKS_AND_DOCUMENTS_SIGN = 'task_and_documents_sign';

// Companies
const COMPANIES_VIEW = 'companies_view';
const COMPANIES_COMMUNICATION = 'companies_communication';
const COMPANIES_CREATE = 'companies_create';
const COMPANIES_EDIT = 'companies_edit';
const COMPANIES_DELETE = 'companies_delete';

// Users
const USERS_VIEW = 'users_view';
const USERS_COMMUNICATION = 'users_communication';
const USERS_CREATE = 'users_create';

// Employees
const EMPLOYEES_VIEW = 'employees_view';
const EMPLOYEES_COMMUNICATION = 'employees_communication';
const EMPLOYEES_CREATE = 'employees_create';
const EMPLOYEES_EDIT = 'employees_edit';
const EMPLOYEES_DELETE = 'employees_delete';

// Templates
const TEMPLATES_VIEW = 'templates_view';
const TEMPLATES_CREATE = 'templates_create';
const TEMPLATES_EDIT = 'templates_edit';
const TEMPLATES_DELETE = 'templates_delete';

// Insights and reports
const INSIGHTS_N_REPORTS_VIEW = 'insights_n_reports_view';
const INSIGHTS_N_REPORTS_CREATE = 'insights_n_reports_create';
const INSIGHTS_N_REPORTS_EDIT = 'insights_n_reports_edit';
const INSIGHTS_N_REPORTS_DELETE = 'insights_n_reports_delete';

// Account and billing
const BILLING_ACCOUNT_VIEW = 'billing_account_view';
const BILLING_ACCOUNT_EDIT = 'billing_account_edit';
const BILLING_ACCOUNT_DELETE = 'billing_account_delete';

// Account setup and configuration
const ACCOUNT_CONFIGURATION_VIEW = 'account_configuration_view';
const ACCOUNT_CONFIGURATION_CREATE = 'account_configuration_create';
const ACCOUNT_CONFIGURATION_EDIT = 'account_configuration_edit';
const ACCOUNT_CONFIGURATION_DELETE = 'account_configuration_delete';



export default {
  // Projects
  PROJECTS_VIEW,
  PROJECTS_COMMUNICATION,
  PROJECTS_CREATE,
  PROJECTS_EDIT,
  PROJECTS_DELETE,
  PROJECTS_ASSIGN,
  PROJECTS_APPROVE,

  // Task & Documents
  TASKS_AND_DOCUMENTS_VIEW,
  TASKS_AND_DOCUMENTS_COMMUNICATION,
  TASKS_AND_DOCUMENTS_CREATE,
  TASKS_AND_DOCUMENTS_EDIT,
  TASKS_AND_DOCUMENTS_DELETE,
  TASKS_AND_DOCUMENTS_ASSIGN,
  TASKS_AND_DOCUMENTS_APPROVE,
  TASKS_AND_DOCUMENTS_SIGN,

  // Companies
  COMPANIES_VIEW,
  COMPANIES_COMMUNICATION,
  COMPANIES_CREATE,
  COMPANIES_EDIT,
  COMPANIES_DELETE,

  // Users
  USERS_VIEW,
  USERS_COMMUNICATION,
  USERS_CREATE,

  // Employees
  EMPLOYEES_VIEW,
  EMPLOYEES_COMMUNICATION,
  EMPLOYEES_CREATE,
  EMPLOYEES_EDIT,
  EMPLOYEES_DELETE,

  // Templates
  TEMPLATES_VIEW,
  TEMPLATES_CREATE,
  TEMPLATES_EDIT,
  TEMPLATES_DELETE,

  // Insights and reports
  INSIGHTS_N_REPORTS_VIEW,
  INSIGHTS_N_REPORTS_CREATE,
  INSIGHTS_N_REPORTS_EDIT,
  INSIGHTS_N_REPORTS_DELETE,

  // Account and billing
  BILLING_ACCOUNT_VIEW,
  BILLING_ACCOUNT_EDIT,
  BILLING_ACCOUNT_DELETE,

  // Account setup and configuration
  ACCOUNT_CONFIGURATION_VIEW,
  ACCOUNT_CONFIGURATION_CREATE,
  ACCOUNT_CONFIGURATION_EDIT,
  ACCOUNT_CONFIGURATION_DELETE,
}
