<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler
        class="ps-1 d-flex d-md-none"
        @click="$store.commit('toggleSidebar')"
      >
        <img src="@/assets/menu-btn.svg" width="20" height="20" />
      </CHeaderToggler>
      <CHeaderToggler
        class="ps-1 d-none d-md-flex"
        @click="$store.commit('toggleUnfoldable')"
      >
        <img
          v-if="!sidebarUnfoldable"
          src="@/assets/menu-btn.svg"
          width="20"
          height="20"
        />
        <img v-else src="@/assets/menu-btn-2.svg" width="20" height="20" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-md-none" to="/">
        <img src="@/assets/logo-min.svg" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CBreadcrumb>
          <div id="header-nav" style="display: flex; align-items: center" />
        </CBreadcrumb>
      </CHeaderNav>

      <CHeaderNav class="me-2">
        <SearchResults />
      </CHeaderNav>
      <CHeaderNav class="me-2">
        <router-link :to="''" @click="toSettings">
          <CIcon class="my-1 mx-2" icon="cil-settings" size="lg" />
        </router-link>
      </CHeaderNav>

      <CHeaderNav class="me-2">
        <router-link :to="{ name: 'Notifications' }">
          <div class="bell-icon">
            <CIcon class="my-1 mx-2" icon="cil-bell" size="lg" />
            <div
              v-if="notificationCounter"
              class="bell-icon__label"></div>
          </div>
        </router-link>
      </CHeaderNav>
      <CHeaderNav class="ms-3 me-4">
        <div v-if="user.is_superuser && user.company" class="logged">
          <div class="logged-title">Logged in as</div>
          <div class="logged-company">{{ user.company.name }}</div>
        </div>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import AppHeaderDropdownMssgs from './AppHeaderDropdownMssgs'
import AppHeaderDropdownNotif from './AppHeaderDropdownNotif'
import AppHeaderDropdownTasks from './AppHeaderDropdownTasks'
import SearchResults from '@/views/TopNav/SearchResults/index.vue'
import { computed } from 'vue'
import { mapGetters, useStore } from 'vuex'
import Gate from '@/services/Gate/Gate'
import { permissionError } from '@/services/Notify/Toasts'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
    AppHeaderDropdownMssgs,
    AppHeaderDropdownNotif,
    AppHeaderDropdownTasks,
    SearchResults,
  },
  setup() {
    const store = useStore()
    return {
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
    }
  },
  computed: {
    ...mapGetters(['user', 'notificationCounter']),
  },
  updated() {
    this.$http.auth.getNotifications()
      .then(res => this.$store.dispatch('setUnreadNotificationsCounter', res.data.unread))
  },
  created() {
    this.$http.auth.getNotifications()
      .then(res => this.$store.dispatch('setUnreadNotificationsCounter', res.data.unread))
  },
  methods: {
    async toSettings() {
      (await Gate.can('view', 'setting'))
        ? this.$router.push({ name: 'Settings' })
        : this.$notify(permissionError)
    },
  },
}
</script>

<style lang="scss">
.logged {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;

  &-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #303c54;
  }

  &-company {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #005d9d;
  }
}
.bell-icon {
  position: relative;

  &__label {
    height: 8px;
    width: 8px;
    background: #0068AD;
    border-radius: 200px;
    position: absolute;
    right: 10px;
    top: 4px;
    border: 0.5px solid white;
  }
}
</style>
