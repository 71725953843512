import i18n from '@/i18n'
import { nextTick } from 'vue'

const getMessage = (message, value, customMessage) => {
	const isSimpleMessage = typeof message === 'string'
	let messageKey = isSimpleMessage ? message : message[0]
	let messageVars = isSimpleMessage ? {} : (message[1] || {})
	messageVars.value = value

	if (customMessage) {
		const isSimpleCustomMessage = typeof customMessage === 'string'
		messageKey = isSimpleCustomMessage ? customMessage : customMessage[0]
		messageVars = {
			...messageVars,
			...(isSimpleCustomMessage ? {} : (customMessage[1] || {}))
		}
	}

	return i18n.global.t(messageKey, messageVars)
}

const validate = (rules, value) => {
	for (let rule of rules) {
		// Rule can be array [rule, customMessage] or rule function
		rule = Array.isArray(rule) ? rule[0] : rule
		const message = Array.isArray(rule) ? rule[1] : ''
		const result = rule(value)

		// Rule function can return -1 for stop validation as successfully validation
		if (result === -1) {
			return ''
		}

		// If result not TRUE and has result - result is validation error message
		if (result !== true && result) {
			return getMessage(result, value, message)
		}
	}

	return ''
}

const validateAll = (rules, values) => {
	const rulesArr = Object.entries(rules)
	const validationErrors = {}
	for (let [field, fieldRules] of rulesArr) {
		const validationResult = validate(fieldRules, values[field])
		if (validationResult) {
			validationErrors[field] = validationResult
		}
	}

	return {
		hasErrors: !!Object.entries(validationErrors).length,
		validationErrors,
	}
}

const has = (field, errors) => {
  return !!errors[field]
}

const set = (field, errorMessage, errors) => {
  if( Array.isArray(errors[field]) ) {
    errors[field].push(errorMessage)
  } else {
    errors[field] = [errorMessage]
  }
}

/**
 * Make validator.
 *
 * Provide rules object to validator: [field_name]: rule[].
 * The rule can be an array, where the first element will be the rule function,
 * and the second will be a custom message, or if a custom message is not needed,
 * you can simply pass the rule function.
 *
 * The result of the rule function or custom message must be either a key string for i18n,
 * or must be an array, where the first element is the key for i18n and
 * the second is an object with variables to translate.
 * For a custom message, the variables returned from the rule function and
 * the checked value will be automatically included as the "value" variable.
 *
 * @param {Object} rules
 * @return {Object}
 */
export default rules => ({
	rules,
  errors: {},
	validate(field, value) {
		const fieldRules = this.rules[field]
		let errors = fieldRules ? validate(fieldRules, value) : ''
    this.errors[field] = errors
    return errors
	},
	validateAll(values) {
		let result = validateAll(this.rules, values)
    this.errors = result.validationErrors
    return result
	},
  has(field){
    return has(field, this.errors)
  },
  set(field, errorMessage){
    set(field, errorMessage, this.errors)
  },
  get(field){
    if (this.errors[field]){
      if (Array.isArray(this.errors[field])){
        return this.errors[field][0]
      } else {
        return this.errors[field]
      }
    } else {
      return ''
    }
  },
  forget(field){
    this.errors[field] = null
  },
  forgetAll(){
    this.errors = {};
  },
  scrollToErrors(querySelector = '.form-text.text-error') {
    nextTick(() => {
      let els = document.querySelectorAll(querySelector)
      if (els.length) {
        const top = els[0].getBoundingClientRect().top
        const y = top + window.pageYOffset + -250
        window.scrollTo({top: y, behavior: 'smooth'})
      }
    })
   },
})
