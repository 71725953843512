<template>
  <CButton v-bind="$attrs" :class="btnClasses" :disabled="btnDisabled">
    <span :class="{ 'hide-content': loading }">
      <slot></slot>
    </span>
    <span :class="['btn-spinner c-loading-button-spinner spinner-border spinner-border-sm', { 'd-none': !loading }]"></span>
  </CButton>
</template>

<script>
  export default {
    name: 'SubmitBtn',
    props: {
      loading: Boolean,
    },
    computed: {
      btnClasses() {
        return ['btn-submit', this.$attrs.class]
      },
      btnDisabled() {
        return this.$attrs.disabled || this.loading
      }
    }
  }
</script>

<style scoped lang="scss">
  .btn-submit {
    position: relative;

    .hide-content {
      opacity: 0;
    }

    .btn-spinner {
      position: absolute;
      top: calc(50% - 7px);
      left: calc(50% - 7px);
      opacity: 1;
      width: 14px;
      height: 14px;
      margin: 0;
    }
  }
</style>
