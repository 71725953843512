export const cilArTopBlue = `<svg viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3903_148102)">
        <path d="M1.7179 4.82512L1.82355 4.94194C1.89358 5.01935 2.00713 5.01935 2.07717 4.94194L4.9987 1.71227L7.92023 4.94194C7.99026 5.01935 8.10381 5.01935 8.17385 4.94194L8.27951 4.82512C8.34954 4.74771 8.34954 4.62218 8.27951 4.54477L5.1255 1.05806C5.05547 0.980648 4.94193 0.980648 4.87188 1.05806L1.7179 4.54477C1.64785 4.62218 1.64785 4.74771 1.7179 4.82512Z" fill="#005D9D" stroke="#005D9D"/>
    </g>
    <defs>
        <clipPath id="clip0_3903_148102">
            <rect width="8" height="6" fill="white" transform="translate(8.99805 6) rotate(-180)"/>
        </clipPath>
    </defs>
</svg>
`
