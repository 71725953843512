<template>
  <CRow :class="['cn-list-item', customClass]">
    <CCol class="col-auto mt-0 pt-1  pe-2">
      <div v-if="number" class="parent">
        <div class="child">{{ number }}</div>
      </div>
    </CCol>
    <CCol class="description p-0 m-0">
      <slot v-if="$slots.text" name="text" />
      <p v-else class="m-0">
        {{ text }}
      </p>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'CNInput',
  props: {
    text: {
      type: String,
      default: '',
    },
    number: {
      type: [Number, null],
      default: null,
    },
    customClass: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss" scoped>
.cn-list-item {
  .parent {
    position: relative;
    min-width: 16px;
    min-height: 16px;
    background: #0068ad;
    border-radius: 50%;
  }

  .child {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 10px;
    font-weight: bold;
    color: white;
  }

  .description p {
    //font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.002em;
    color: #1c262f;
  }
}
</style>
