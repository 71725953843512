<template>
    <CModal
        :content-class-name="`company-details-modal`"
        :size="large ? 'lg' : ''"
        backdrop="static"
        :visible="visible"
        @close="onClose">
        <div class="base-modal__top-panel">
            <CIcon name="cil-x" style="cursor: pointer" @click="onClose"></CIcon>
        </div>

        <ModalStatement class="company-details-modal__statement" :title="title" :text="text" />

        <div class="company-details-modal__body">
            <slot></slot>
        </div>

        <slot name="upload"></slot>

        <div class="company-details-modal__footer">
            <slot name="footer"></slot>
        </div>
    </CModal>
</template>

<script>
import ModalStatement from '@/components/Modals/Partials/ModalStatement.vue';

export default {
    name: 'CompanyDetailsModal',
    components: { ModalStatement },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
        },
        text: {
            type: String,
            default: '',
        },
        large: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss">
.company-details-modal {
    padding: 40px;

    &_lg {
        width: 760px !important;
    }

    &__footer {
        display: flex;
        justify-content: center;
        gap: 16px;
        margin-top: 32px;

        & button {
            max-width: 180px;
        }
    }

    &__statement {
        margin-bottom: 24px;
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }
}
</style>
