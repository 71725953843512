<template>
  <CInputGroup>
    <CInputGroupText>
      <CSpinner v-if="loading" size="sm" />
      <CIcon v-else name="cil-search" />
    </CInputGroupText>
    <CFormInput
      :id="inputId"
      :model-value="modelValue"
      placeholder="Search"
      @input="$emit('update:modelValue', $event.target.value)"
      @keyup="$emit('search')"
    />
  </CInputGroup>
</template>

<script>
export default {
  name: 'SearchForm',
  props: {
    loading: { type: Boolean, default: false },
    modelValue: { type: String, default: '' },
    inputId: { type: String, required: true },
  },
  emits: ['update:modelValue', 'search'],
}
</script>

<style scoped></style>
