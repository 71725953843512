import Gate from '@/services/Gate/Gate'
import router from '../index'

export const canView = async ({ to }) => {
  await Gate.can('view', 'project', to.params.id).then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}

export const canCreate = async () => {
  await Gate.can('create', 'project').then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}

export const canEdit = async ({ to }) => {
  await Gate.can('edit', 'project', to.params.id).then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}

export const canViewTabPage = async ({ to }) => {
  await Gate.can('viewTabs', 'project', to.params.id).then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}
