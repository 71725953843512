<template>
  <div class="c-pagination">
    <div class="pc-hide">
      <v-pagination
        :model-value="modelValue"
        :pages="pages"
        active-color="#005D9D"
        style="margin: 10px 0"
        :dots="false"
        :hideFirstButton="true"
        :hideLastButton="true"
        @update:modelValue="$emit('update:modelValue', $event)"
    />
    </div>
    <v-pagination
      :model-value="modelValue"
      :pages="pages"
      active-color="#005D9D"
      style="margin-right: 10px"
      @update:modelValue="$emit('update:modelValue', $event)"
      class="mobile-hide"
    />
    <CInputGroup :style="{maxWidth: '120px', marginLeft: '10px'}">
      <CFormSelect
        :value="perPage"
        size="sm"
        style="height: 28px; padding-top: 2px"
        @change="updatePerPage($event.target.value)"
        :options="[{value: '10', label: '10/page'},{value: '20', label: '20/page'}, {value: '50', label: '50/page'},{value: '100', label: '100/page'}]"
      />
    </CInputGroup>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {},
    perPage: {},
    updatePerPage: { type: Function, default: () => {} },
    pages: { type: Number, default: 1 }
  }
}
</script>
<style lang="scss" scoped>
  :deep(.pagination) {
    .page-item{
      cursor: pointer;
    }
  }
</style>

<style lang="scss">
  .c-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;
    flex-wrap: wrap;

    @media (max-width: 767px) {

    justify-content: space-between;
    }
  }

  .pc-hide .Pagination li{
    display: none;

    &.PaginationControl {
      @media (max-width: 991px) {
        display: flex!important;
      }
    }

    &:has(> button.Page-active) {
      @media (max-width: 768px) {
        display: list-item!important;
      }
    }
  }

  .Pagination {

    & li {
      height: 28px;
      width: 28px;
      border: 1px solid rgba(48, 60, 84, .2);
      border-left: none;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        border-left: 1px solid rgba(48, 60, 84, .2);
        border-radius: 5px 0px 0px 5px;
      }

      &:last-child {
        border-radius: 0px 5px 5px 0px;
      }

      .Page {
        margin: 0;
        height: 28px;
        width: 28px;
        border-radius: 0!important;
        font-weight: 400;
        font-size: 12px!important;
        color: #005D9D;

        &-active {

        }
      }
    }
  }
  .Control-active {
    fill: #005D9D!important;
  }
</style>
