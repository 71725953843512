<template>
  <CAccordionItem :active="active" :item-key="itemKey" :class="['pc-hide-768 customAccordion', mobileClasses]">
    <CAccordionHeader v-if="!!$slots['header']">
      <slot name="header" />
    </CAccordionHeader>
    <CAccordionHeader v-else>
      {{ label ? label : mobileLabel }}
    </CAccordionHeader>
    <CAccordionBody>
      <slot />
    </CAccordionBody>
  </CAccordionItem>

  <CRow :class="['mobile-hide-768', pcClasses]">
    <CCol v-if="!!$slots['header']">
      <slot name="header" />
    </CCol>
    <CCol v-else-if="!!$slots['pc']">
      <slot name="pc" />
    </CCol>
    <CCol v-else>
      <h4>{{ label ? label : pcLabel }}</h4>
    </CCol>
  </CRow>
  <CRow class="mobile-hide-768">
    <slot />
  </CRow>
</template>

<script>
const CLASSES = ['.container', '.card', '.card-body']

export default {
  props: {
    label: { type: String, default: '' },
    pcLabel: { type: String, default: '' },
    mobileLabel: { type: String, default: '' },
    pcClasses: { type: [String, Array, Object], default: '' },
    mobileClasses: { type: [String, Array, Object], default: '' },
    itemKey: { type: Number, default: 1 },
    active: { type: Boolean, default: false }
  },
  mounted() {
    this.$nextTick(() => {
      this.setClass()
      window.addEventListener('resize', () => this.setClass())
      this.setBorderBottom()
    })
  },
  methods: {
    setBorderBottom() {
      const els = document.getElementsByClassName('customAccordion')
      if(els.length > 1)
        for(let i = 0; i < els.length - 1; i++)
          els[i].classList.add('borderBottom')
    },
    setClass() {
      const els = document.getElementsByClassName('customAccordion')
      const firstEl = els[0]
      if (firstEl && window.getComputedStyle(firstEl).display === 'block'){
        CLASSES.forEach((name) => {
          if (firstEl.closest(name)) firstEl.closest(name).classList.add('accordionCard')
        })
      } else if (firstEl) {
        CLASSES.forEach((name) => {
          if (firstEl.closest(name))
            firstEl.closest(name).classList.remove('accordionCard')
        })
      }
    },
  },
}
</script>

<style lang="scss">
.accordionCard {
  padding-right: 0 !important;
  padding-left: 0 !important;
  border-radius: 10px !important;
}

.customAccordion.borderBottom {
  border-bottom: 1px solid var(--cui-dropdown-divider-bg, rgba(0, 0, 21, 0.15)) !important;
}

.customAccordion {
  border: none !important;

  .accordion-header {
    .accordion-button:focus,
    .accordion-button {
      background: unset !important;
      border-color: unset !important;
      box-shadow: unset !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 21.28px !important;
      color: #303c54 !important;
    }

    .accordion-button::after {
      background-image: var(--cui-accordion-button-icon, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='rgba%2848, 60, 84, 100%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"));
    }

    .accordion-button:not(.collapsed) {
      color: #303c54 !important;
    }
  }
}
</style>
