export class User {
  constructor(userData) {
    Object.assign(this, { ...userData })
  }
  can(permission) {
    return Array.isArray(this.permissions) && this.permissions.includes(permission)
  }
  get fullName (){
    return `${this.first_name} ${this.last_name}`
  }
  cant(permission) {
    return !this.can(permission)
  }
  canAny(permissions){
    return this.permissions && Array.isArray(this.permissions) && this.permissions.length && Array.isArray(permissions)
      ? this.permissions.some((permission) => permissions.includes(permission))
      : false
  }
  canAll(permissions) {
    if (!Array.isArray(permissions) || !permissions.length || !this.permissions)
      return true
    let len = permissions.filter((el) => !this.permissions.includes(el))

    return !len.length
  }
  hasRole(role) {
    return this.role && this.role.name === role
  }
}
