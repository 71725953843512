import Echo from 'laravel-echo';
import ApiService from '@/services/ApiService';

window.Pusher = require('pusher-js');

const LaravelEcho = new Echo({
    broadcaster: 'pusher',
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    key: process.env.VUE_APP_PUSHER_KEY,
    forceTLS: true,
    authorizer: (channel) => {
        return {
            authorize: (socketId, callback) => {
                const data = {
                    socket_id: socketId,
                    channel_name: channel.name,
                };

                const config = {
                    withCredentials: true,
                    xsrfCookieName: 'XSRF-TOKEN',
                    headers: {'X-Socket-ID': socketId}
                };

                ApiService._axios.defaults.headers.common['X-Socket-Id'] = socketId;
                
                ApiService._axios
                    .post('/broadcasting/auth', data, config)
                    .then(response => callback(false, response.data))
                    .catch((error) => {
                        alert(error);
                        callback(true, error);
                    });
            },
        };
    },
});

export default LaravelEcho;
