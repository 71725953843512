import Gate from '@/services/Gate/Gate'
import router from '../index'

export const canCreate = async () => {
  await Gate.can('create', 'company').then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}

export const canView = async ({ to }) => {
  await Gate.can('view', 'company', to.params.id).then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}

export const canViewNotes = async ({ to }) => {
  await Gate.can('list', 'note', to.params.id).then(async (res) => {
    if (!res) await router.replace({ name: 'PermissionsError' })
    return res
  })
}
