import ApiService from '@/services/ApiService'
import Gate from '@/services/Gate/Gate'

export default {
  state: {
    companies: [],
    entity: {},
    permissions: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  },
  getters: {
    companies: (state) => state.companies,
    companyPermissions: (state) => state.permissions,
  },
  mutations: {
    setCompanies: (state, data) => (state.companies = data),
    setCompanyEntity: (state, data) => (state.entity = data),
    setCompanyPermissions: async (state) => {
      state.permissions = {
        view: await Gate.can('view', 'company', state.entity.id),
      }
    },
  },
  actions: {
    fetchCompanyPermissions({ commit }, id) {
      commit('setCompanyEntity', {})
      return new Promise((resolve, reject) => {
        ApiService.api.app
          .getModelPermissions('company', id)
          .then((res) => {
            commit('setCompanyEntity', res.data.data)
            commit('setCompanyPermissions')
            resolve(res.data.data)
          })
          .catch((err) => {
            commit('setCompanyEntity', {})
            commit('setCompanyPermissions')
            resolve({})
            reject(err)
          })
      })
    },
    fetchCompanies({ commit }, data) {
      commit('setCompanies', [])
      return new Promise((resolve, reject) => {
        ApiService.api.companies
          .fetchCompanies(data)
          .then((res) => {
            commit('setCompanies', res.data.data)
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    setWorkflow(vm, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        Object.keys(data).forEach((item) => {
          data[item] && formData.append(item, data[item])
        })
        ApiService.api.companies
          .setWorkflow(formData)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
  },
}
