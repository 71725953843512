import $P from '@/utils/constants/permissions';
/**  Middleware */
import { canCreate, canView, canViewTabPage } from './middleware/project';
import {
    canCreate as taskCreate,
    canFinishView,
    canSendForSignature,
    canSignView,
    canView as taskView,
} from './middleware/task';
import { canCreate as scopeCreate, canView as scopeView } from './middleware/scope';

const Projects = () => import('@/views/Projects');
const ProjectsList = () => import('@/views/Projects/List');

const ProjectNew = () => import('@/views/Projects/New');
const Project = () => import('@/views/Projects/Project');

const ProjectDocuments = () => import('@/views/Projects/Project/Documents');
const ProjectActivity = () => import('@/views/Projects/Project/Activity');

const ProjectScopes = () => import('@/views/Projects/Project/Scopes');
const ProjectScope = () => import('@/views/Projects/Project/Scopes/Scope');
const ProjectScopesList = () => import('@/views/Projects/Project/Scopes/List');
const ProjectScopeUpload = () => import('@/views/Projects/Project/Scopes/ScopeUpload');
const ProjectScopesTaskAssign = () => import('@/views/Projects/Project/Scopes/Scope/Tasks/TaskAssign.vue');
const ProjectScopesTasksIndex = () => import('@/views/Projects/Project/Scopes/Scope/Tasks/index.vue');
const ProjectScopesTaskReview = () => import('@/views/Projects/Project/Scopes/Scope/Tasks/ReviewContract.vue');
const ProjectScopesTaskMergeFields = () => import('@/views/Projects/Project/Scopes/Scope/Tasks/MergeFields.vue');
const ProjectScopesTaskConfirm = () => import('@/views/Projects/Project/Scopes/Scope/Tasks/ConfirmSend.vue');

const ProjectInfo = () => import('@/views/Projects/Project/Info');
const ProjectDetails = () => import('@/views/Projects/Project/Info/Details');
const ProjectTeams = () => import('@/views/Projects/Project/Info/Team');

const ProjectScopeWorkflow = () => import('@/views/Projects/Project/Info/Workflows/Scope');
const ProjectContractWorkflow = () => import('@/views/Projects/Project/Info/Workflows/Contract');

const ProjectTemplateCollection = () => import('@/views/Projects/Project/Info/TemplateCollection');

const ProjectTasks = () => import('@/views/Projects/Project/Tasks');
const ProjectTasksList = () => import('@/views/Projects/Project/Tasks/List');
const ProjectTasksKanban = () => import('@/views/Projects/Project/Tasks/Kanban');

const ProjectTasksCreate = () => import('@/views/Projects/Project/Tasks/Task/Create');
const TasksChooseScope = () => import('@/views/Projects/Project/Tasks/Task/Create/ChooseScope');
const TasksConfirm = () => import('@/views/Projects/Project/Tasks/Task/Create/ConfirmData');
const TasksNotify = () => import('@/views/Projects/Project/Tasks/Task/Create/Notify');

const ProjectTaskView = () => import('@/views/Projects/Project/Tasks/Task/View');

const Contracts = () => import('@/views/Projects/Project/Tasks/Task/View/Contracts');
const TaskReviewApprove = () => import('@/views/Projects/Project/Tasks/Task/View/ReviewApprove/ReviewApprove');
const TaskSendSignature = () => import('@/views/Projects/Project/Tasks/Task/View/TaskSendSignature');
const TaskSign = () => import('@/views/Projects/Project/Tasks/Task/View/TaskSign/TaskSign');
const TaskFinish = () => import('@/views/Projects/Project/Tasks/Task/View/TaskFinish');
const TaskRequest = () => import('@/views/Projects/Project/Tasks/Task/View/Request');
const TaskAcknowledge = () => import('@/views/Projects/Project/Tasks/Task/View/Acknowledge');

export default [
    {
        path: '/projects',
        name: 'Projects',
        redirect: {
            name: 'ProjectsList',
        },
        component: Projects,
        meta: { auth: true },
        children: [
            {
                path: '',
                name: 'ProjectsList',
                component: ProjectsList,
            },
            {
                path: 'new',
                name: 'ProjectNew',
                component: ProjectNew,
                meta: { middleware: [canCreate] },
            },
            {
                path: ':id',
                name: 'Project',
                redirect: {
                    name: 'ProjectDashboard',
                },
                beforeEnter: async (to) => await canViewTabPage({ to: to }),
                component: Project,
                children: [
                    {
                        path: 'dashboard',
                        name: 'ProjectDashboard',
                        redirect: {
                            name: 'ProjectTasks',
                        },
                    },
                    {
                        path: 'documents',
                        name: 'ProjectDocuments',
                        component: ProjectDocuments,
                        meta: { permissions: [$P.TASKS_AND_DOCUMENTS_VIEW] },
                    },
                    {
                        path: 'activity',
                        name: 'ProjectActivity',
                        component: ProjectActivity,
                    },
                    {
                        path: 'info',
                        name: 'ProjectInfo',
                        redirect: {
                            name: 'ProjectDetails',
                        },
                        meta: { middleware: [canView] },
                        beforeEnter: async (to) => await canView({ to: to }),
                        component: ProjectInfo,
                        children: [
                            {
                                path: 'details',
                                name: 'ProjectDetails',
                                component: ProjectDetails,
                            },
                            {
                                path: 'teams',
                                name: 'ProjectTeams',
                                component: ProjectTeams,
                            },
                            {
                                path: 'scope-workflow',
                                name: 'ProjectScopeWorkflow',
                                component: ProjectScopeWorkflow,
                            },
                            {
                                path: 'contract-workflow',
                                name: 'ProjectContractWorkflow',
                                component: ProjectContractWorkflow,
                            },
                            {
                                path: 'template-collection/:create?',
                                name: 'ProjectTemplateCollection',
                                component: ProjectTemplateCollection,
                            },
                        ],
                    },
                    {
                        path: 'scopes',
                        name: 'ProjectScopes',
                        redirect: {
                            name: 'ProjectScopesList',
                        },
                        beforeEnter: async (to) => await canView({ to: to }),
                        component: ProjectScopes,
                        children: [
                            {
                                path: '',
                                name: 'ProjectScopesList',
                                component: ProjectScopesList,
                            },
                            {
                                path: ':scopeId',
                                name: 'ProjectScopeEdit',
                                beforeEnter: async (to) => await scopeView({ to: to }),
                                component: ProjectScope,
                            },
                            {
                                path: ':scopeId/tasks',
                                name: 'ProjectScopesTasksIndex',
                                component: ProjectScopesTasksIndex,
                                beforeEnter: async (to) => {
                                    await canView({ to: to });
                                    await taskCreate({ to: to });
                                },
                                children: [
                                    {
                                        path: 'assign',
                                        name: 'ProjectScopesTasksAssign',
                                        component: ProjectScopesTaskAssign,
                                    },
                                    {
                                        path: 'review',
                                        name: 'ProjectScopesTaskReview',
                                        component: ProjectScopesTaskReview,
                                    },
                                    {
                                        path: 'merge-fields',
                                        name: 'ProjectScopesTaskMergeFields',
                                        component: ProjectScopesTaskMergeFields,
                                    },
                                    {
                                        path: 'confirm',
                                        name: 'ProjectScopesTaskConfirm',
                                        component: ProjectScopesTaskConfirm,
                                    },
                                ],
                            },
                            {
                                path: 'new',
                                name: 'ProjectScopeNew',
                                beforeEnter: async (to) => await scopeCreate({ to: to }),
                                component: ProjectScope,
                            },
                            {
                                path: ':scopeId/upload',
                                name: 'ProjectScopeUpload',
                                beforeEnter: async (to) => {
                                    await canView({ to: to });
                                    await taskCreate({ to: to });
                                },
                                // beforeEnter: async (to) => await scopeCreate({ to: to }),
                                component: ProjectScopeUpload,
                            },
                        ],
                    },
                    {
                        path: 'tasks',
                        name: 'ProjectTasks',
                        redirect: {
                            name: 'ProjectTasksList',
                        },
                        component: ProjectTasks,
                        children: [
                            {
                                path: '',
                                name: 'ProjectTasksList',
                                component: ProjectTasksList,
                            },
                            {
                                path: 'kanban',
                                name: 'ProjectTasksKanban',
                                component: ProjectTasksKanban,
                                redirect: {
                                    name: 'ProjectTasksList',
                                },
                            },
                            {
                                path: 'create',
                                name: 'ProjectTasksCreate',
                                beforeEnter: async (to) => {
                                    await canView({ to: to });
                                    await taskCreate({ to: to });
                                },
                                redirect: {
                                    name: 'TasksChooseScope',
                                },
                                component: ProjectTasksCreate,
                                children: [
                                    {
                                        path: ':scope_id?',
                                        name: 'TasksChooseScope',
                                        component: TasksChooseScope,
                                    },
                                    {
                                        path: ':scope_id/confirm-data',
                                        name: 'TasksConfirmData',
                                        component: TasksConfirm,
                                    },
                                    {
                                        path: ':scope_id/notify',
                                        name: 'TasksNotify',
                                        component: TasksNotify,
                                    },
                                ],
                            },
                            {
                                path: ':task_id',
                                name: 'ProjectTaskView',
                                redirect: {
                                    name: 'ProjectTasks',
                                },
                                component: ProjectTaskView,
                                beforeEnter: async (to) => await taskView({ to: to }),
                                children: [
                                    {
                                        path: 'view',
                                        name: 'TaskContracts',
                                        component: Contracts,
                                    },
                                    {
                                        path: 'review-document',
                                        name: 'TaskContractsReviewDocument',
                                        component: Contracts,
                                    },
                                    {
                                        path: 'review-approve',
                                        name: 'TaskReviewApprove',
                                        component: TaskReviewApprove,
                                    },
                                    {
                                        path: 'send-signature',
                                        name: 'TaskSignature',
                                        beforeEnter: async (to) => await canSendForSignature({ to: to }),
                                        component: TaskSendSignature,
                                    },
                                    {
                                        path: 'sign',
                                        name: 'TaskSign',
                                        beforeEnter: async (to) => await canSignView({ to: to }),
                                        component: TaskSign,
                                    },
                                    {
                                        path: 'finish',
                                        name: 'TaskFinish',
                                        beforeEnter: async (to) => await canFinishView({ to: to }),
                                        component: TaskFinish,
                                    },
                                    {
                                        path: 'request',
                                        name: 'TaskRequest',
                                        component: TaskRequest,
                                        beforeEnter: async (to) => await taskView({ to: to }),
                                    },
                                    {
                                        path: 'acknowledge',
                                        name: 'TaskAcknowledge',
                                        component: TaskAcknowledge,
                                        beforeEnter: async (to) => await taskView({ to: to }),
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
