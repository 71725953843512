import ApiService from '@/services/ApiService'
import { User } from '@/utils/user'

const state = {
  toasts: [],
}

const getters = {
  toasts: (state) => state.toasts,
}

const mutations = {
  setToast(state, data) {
    state.toasts.push(data)
  },
  deleteToast(state, index) {
    state.toasts.splice(index, 1)
  },
  clearToast(state) {
    state.toasts = []
  },
}

const actions = {}

export default {
  state,
  getters,
  mutations,
  actions,
}
