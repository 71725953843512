const DISCUSSION_APPROVEMENT_STATUS_PENDING = 'Pending'
const DISCUSSION_APPROVEMENT_STATUS_REJECTED = 'Rejected'
const DISCUSSION_APPROVEMENT_STATUS_APPROVED = 'Approved'
const DISCUSSION_STATUS_APPROVED = 'Approved'
const DISCUSSION_STATUS_REJECTED = 'Rejected'
const DISCUSSION_STATUS_OPEN = 'Open'

export const DISCUSSION_APPROVEMENT_STATUSES = {
  DISCUSSION_APPROVEMENT_STATUS_PENDING,
  DISCUSSION_APPROVEMENT_STATUS_REJECTED,
  DISCUSSION_APPROVEMENT_STATUS_APPROVED,
}

export const DISCUSSION_STATUSES = {
  DISCUSSION_STATUS_OPEN,
  DISCUSSION_STATUS_REJECTED,
  DISCUSSION_STATUS_APPROVED,
}

export default {
  namespaced: true,
  state: {
    discussions: [],
    decorations: [],
    task: null,
  },
  getters: {
    allDiscussions(state) {
      return state.discussions
    },
    allReplies(state) {
      const replies = []
      return replies.concat(...state.discussions.map((d) => d.replies))
    },
    getDiscussionById: (state) => (discussionId) => {
      return state.discussions.find((d) => d.discussionId === discussionId)
    },
    replyHasReplies:
      (state) =>
      ({ discussionId, replyId }) => {
        const discussion = state.discussions.find(
          (d) => d.discussionId === discussionId,
        )
        if (discussion.replies) {
          return !!discussion.replies.find(
            (r) => r.discussion_id === discussionId && r.id > replyId,
          )
        } else {
          return false
        }
      },
  },
  mutations: {
    ADD_INTERNAL_TEXT(state, payload) {
      state.discussions = state.discussions.map((d) =>
        d.discussionId === payload.discussionId
          ? { ...d, internalChangesText: payload.internalChangesText }
          : d,
      )
    },
    SET_INTERNAL_CHANGES(state, payload) {
      state.discussions = state.discussions.map((d) =>
        d.discussionId === payload.discussionId
          ? { ...d, suggestionText: payload.internalChanges }
          : d,
      )
    },
    SET_STATE(state, { key, value }) {
      if (typeof value === 'object' && !Array.isArray(value)) {
        state[key] = Object.assign(state[key], value)
      } else {
        state[key] = value
      }
    },
    UPDATE_DISCUSSION(state, { decorationUuid, attrs }) {
      let discussionIndex = state.discussions.findIndex(
        (d) => d.decorationUuid === decorationUuid,
      )
      if (discussionIndex >= 0) {
        state.discussions.splice(discussionIndex, 1, {
          ...state.discussions[discussionIndex],
          ...attrs,
        })
      }
    },
    PUSH_DISCUSSION_REPLY(state, { discussionId, reply }) {
      const discussion = state.discussions.find(
        (d) => parseInt(d.discussionId) === parseInt(discussionId),
      )
      if (discussion) {
        discussion.replies.push(reply)
      }
    },
    DELETE_DISCUSSION(state, { decorationUuid }) {
      const targetDiscussionIndex = state.discussions.findIndex(
        (d) => d.decorationUuid === decorationUuid,
      )
      if (targetDiscussionIndex >= 0) {
        state.discussions.splice(targetDiscussionIndex, 1)
      }
    },
    UPDATE_DISCUSSIONS_DECORATIONS(state, decorations) {
      if (Array.isArray(decorations)) {
        state.discussions.forEach((discussion) => {
          const relatedDecoration = decorations.find(
            (d) => d.id === discussion.decorationUuid,
          )
          if (relatedDecoration) {
            const { from, to } = relatedDecoration
            discussion.range = { from, to }
          }
        })
      }
    },
    UPDATE_REPLY(state, { discussionId, replyId, value }) {
      const targetDiscussion = state.discussions.find(
        (d) => d.discussionId === discussionId,
      )
      if (targetDiscussion) {
        let targetReplyIndex = targetDiscussion.replies?.findIndex(
          (r) => r.id === replyId,
        )
        if (targetReplyIndex >= 0) {
          targetDiscussion.replies[targetReplyIndex] = {
            ...targetDiscussion.replies[targetReplyIndex],
            ...value,
          }
        } else {
          targetDiscussion.replies?.push(value)
        }
      }
    },
    DELETE_REPLY(state, { discussionId, replyId }) {
      const targetDiscussion = state.discussions.find(
        (d) => d.discussionId === discussionId,
      )
      if (targetDiscussion && targetDiscussion.replies) {
        const targetReplyIndex = targetDiscussion.replies.findIndex(
          (r) => r.id === replyId,
        )
        if (targetReplyIndex >= 0) {
          targetDiscussion.replies.splice(targetReplyIndex, 1)
        }
      }
    },
    PUSH_DISCUSSION(state, discussion) {
      state.discussions.push(discussion)
    },
    MARK_DISCUSSION_APPROVED(state, { discussion, approverId }) {
      alert('discussion approved ' + discussion.id + ' by ' + approverId)
    },
  },
  actions: {
    approveDiscussion({ discussionId }) {

    },
    rejectDiscussion({ discussionId }) {

    },
  },
}
