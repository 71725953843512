<template>
  <CCol v-if="items.count > 0">
    <SmartTable
      :name="'search-table-companies'"
      :items="items.data"
      :columns="columns.map((i) => ({ ...i, sorter: false }))"
      infinity
    >
    </SmartTable>
    <CButton color="link" class="w-link-all" @click="toAll()">
      See All
    </CButton>
  </CCol>
  <AccordionEmptyTable v-else />
</template>

<script>
import AccordionEmptyTable from './AccordionEmptyTable'
export default {
  name: 'Companies',
  components: { AccordionEmptyTable },
  props: {
    items: {
      type: Object,
      default: () => {
        return { data: [], total: 0 }
      },
    },
    search: { type: String, default: '' },
  },
  data() {
    return {
      columns: [
        {
          key: 'name',
          label: 'Company Name',
          _style: { textAlign: 'center' },
          link: { name: 'Company', params: { id: 'id' } },
        },
        {
          key: 'tid',
          label: 'Tax ID',
          _style: { textAlign: 'center' },
        },
        {
          key: 'projects_count',
          label: 'Projects #',
          _style: { textAlign: 'center' },
          link: { name: 'CompanyProjects', params: { id: 'id' } },
          format: (v) => `${v || '0'}`,
        },
        {
          key: 'tasks_count',
          label: 'Task Open #',
          _style: { textAlign: 'center' },
          link: { name: 'CompanyTasks', params: { id: 'id' } },
          format: (v) => `${v || '0'}`,
        },
        {
          key: 'overdue_tasks_count',
          label: 'Task Overdue #',
          _style: { textAlign: 'center' },
          link: { name: 'CompanyTasks', params: { id: 'id' } },
          format: (v) => `${v || '0'}`,
        },
        {
          key: 'type',
          label: 'Company Type',
          _style: { textAlign: 'center' },
          format: (v) => (v ? v.capitalize() : null),
        },
        {
          key: 'primary_contact_first_name',
          label: 'Contact Name',
          _style: { textAlign: 'center' },
          link: {
            name: 'CompanyUser',
            params: { id: 'id', user_id: 'primary_contact_id' },
          },
          format: (v, i) =>
            v
              ? `${i.primary_contact_first_name || ''} ${
                  i.primary_contact_last_name || ''
                }`
              : null,
        },
      ],
    }
  },
  methods: {
    toAll() {
      if (this.$route.href === '/companies-list') {
        window.location.href = '/companies-list?search=' + this.search
      } else {
        this.$router.push({
          name: 'CompaniesList',
          query: { search: this.search },
        })
      }
    },
  },
}
</script>

<style scoped></style>
