<template>
  <CSpinner color="primary" :size="size" />
</template>

<script>
  export default {
    name: 'BasePreloader',
    props: {
      size: String,
    },
  }
</script>

<style scoped>

</style>
